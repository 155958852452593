/*  DARK UI  */

.dark #dataLoadingSection {
  background: #303030 !important;
}

.dark nav#header {
  padding-bottom: 4px !important;
  border-bottom: 1px solid #585858 !important;
  background: #464646 !important;
  color: #fff !important;
}

.dark .side-menu-container::before {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 49%);
  content: '';
}

.dark .primary-gradient-img {
  background: linear-gradient(135deg, #5a5a5a 0%, #4a4a4a 100%) !important;
  background: linear-gradient(135deg, #5a5a5a 0%, #4a4a4a 100%) !important;
  background: linear-gradient(135deg, #5a5a5a 0%, #4a4a4a 100%) !important;
}

.dark .mat-drawer-container {
  background: #0b0b0b !important;
  color: rgb(255 255 255 / 87%);
}

.dark .mat-card,
.dark footer {
  background: #373737;
  color: rgb(255 255 255 / 87%);
}

.dark .mat-select-disabled .mat-select-value,
.dark .mat-select-arrow,
.dark .mat-select-trigger {
  color: rgb(255 255 255 / 70%);
}

.dark .mat-form-field-underline {
  background-color: rgb(255 255 255 / 42%);
}

.dark .mat-select-underline {
  background-color: rgb(255 255 255 / 42%);
}

.dark #operatorFilter button.btn-pill.btn-small {
  background: #383838 !important;
}

.dark #operatorFilter button.btn-pill.btn-small.active {
  background: #02c5d3 !important;
  color: #fff;
}

.dark nav#header {
  background-color: #4c4c4c;
  color: #cecece;
}

.dark button.sideMenuButton.mat-button {
  margin-top: 10px;
}

.dark .mat-table {
  background: #464646;
}

.dark .mat-header-cell {
  color: rgb(255 255 255 / 54%);
}

.dark .mat-cell {
  color: rgb(251 251 251 / 87%);
}

.dark mat-row:nth-child(odd) {
  background-color: #4c4c4c;
}

.dark .mat-row:hover,
.dark .example-selected-row {
  background: #333 !important;
}

.dark .mat-paginator {
  border-top: 1px solid #383838;
  background: #464646;
}

.dark .mat-paginator,
.dark .mat-paginator-page-size .mat-select-trigger,
.dark .mat-select-value {
  color: rgb(253 253 253 / 70%) !important;
}

.dark .well {
  border: 1px solid #333;
  background: #353535;
}

.dark .mat-menu-panel {
  background: #585858 !important;
}

.dark .mat-menu-item {
  background: transparent;
  color: rgb(255 255 255 / 87%);
}

.dark .mat-menu-item .mat-icon,
.dark .mat-menu-item-submenu-trigger::after {
  color: rgb(255 255 255 / 54%);
}

.dark .mat-form-field-label {
  color: rgb(255 255 255 / 54%);
}

.dark .pleaseChoose {
  border: 1px solid #313131;
  background: rgb(33 33 33 / 47%);
}

.dark .mat-tab-label,
.dark .mat-tab-link {
  color: rgb(255 255 255);
}

.dark .well.my-drop-zone {
  border-color: #313131 !important;
  border-top: none;
  border-bottom: none;
  background: #3c3c3c;
  color: rgb(255 255 255 / 87%);
}

.dark .mat-input-element:disabled {
  color: rgb(255 255 255 / 42%);
}

.dark mat-card-actions {
  border-color: #3d3d3d !important;
}

.dark .total.anim span {
  color: #fff !important;
}

.dark label.num span {
  color: #e3e3e3 !important;
}

.dark .mat-button-toggle-appearance-standard {
  background: #545454;
  color: rgb(228 228 228 / 95%) !important;
}

.dark #operatorFilter .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background: #008089;
}

.dark .mat-button-toggle-checked {
  background: #383838;
}

.dark .pull-right mat-button-toggle {
  background: #434343 !important;
}

.dark .body-container-content app-invitation-create::after,
.dark app-settlement-create::after,
.dark app-appointment-create::after,
.dark app-dentist-invite-create::after,
.dark app-membership-create::after {
  background: transparent !important;
}

.dark wizard-navigation-bar {
  padding: 15px 0;
  border-bottom: 1px solid #404040;
  background: #232323;
}

.dark .horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child)::before {
  background-color: #404040;
}

.dark .mat-expansion-panel {
  background: #464646;
  color: #efefef;
}

.dark .mat-expansion-panel-body {
  border-color: #383838;
  background: #424242;
}

.dark .sideNavAccordion .mat-expansion-panel-body {
  background: rgb(0 0 0 / 20%);
}

.dark .filterCardless mat-expansion-panel-header.mat-expansion-panel-header {
  border-bottom: 2px solid #3e3e3e;
}

.dark .sr-title,
.dark .primary-color {
  color: #eee !important;
}

.dark hr {
  opacity: 0.3;
}

.dark span.bigNum {
  opacity: 0.9;
}

.dark .subStatcontainer {
  background: rgb(35 35 35) !important;
}

.dark .mat-list .mat-list-item,
.dark .mat-nav-list .mat-list-item,
.dark .mat-selection-list .mat-list-item {
  color: rgb(255 255 255 / 87%) !important;
}

.dark .list-card .mat-table-sticky:first-child {
  border-right: 1px solid #3d3d3d !important;
}

.dark .list-card .mat-table-sticky:last-child {
  border-left: 1px solid #3d3d3d !important;
}

.dark .list-card tr:nth-child(even) {
  background: #414141 !important;
}

.dark .list-card table tr:hover,
.dark .list-card tr:hover .cdk-column-Customer {
  background: #2c2c2c !important;
}

.dark .btn-group {
  background: #383838;
}

.dark .grey-row {
  background: #4c4c4c !important;
}

.dark .cut {
  background: #464646 !important;
}

.dark .supplier-card .grey-row {
  background: #323232 !important;
}

.dark .mat-raised-button,
.dark .mat-fab,
.dark .mat-mini-fab {
  background-color: #565656;
  color: rgb(255 255 255 / 87%) !important;
}

.dark .subsidaries-list mat-list-item:hover {
  background: #353536 !important;
}

.dark .subsidaries-list mat-list-item .mat-list-item-content {
  border-bottom: 1px solid #797979;
}

.dark .pleaseChoose {
  border-color: #3e3e3e !important;
  background: rgb(27 27 27 / 20%) !important;
}

.dark .toggles .mat-button-toggle:hover mat-icon {
  color: #272727 !important;
}

.dark .changeLogCard .changes {
  background: #545454 !important;
}

.dark .changeLogCard ul li {
  color: #dedede !important;
}

.dark .mat-card-subtitle {
  color: rgb(255 255 255 / 54%) !important;
}

.dark .white-background,
.dark .mat-tab-body-content.ng-trigger.ng-trigger-translateTab {
  background-color: #424242 !important;
}

.dark .rel .btn-file-input,
.dark app-file-uploader .btn-file-input {
  color: #d1d1d1 !important;
}

.dark app-settlement-view .chart-card .total {
  text-shadow: 0 1px 1px rgb(0 0 0 / 35%), 0 3px 4px rgb(0 0 0 / 31%), 0 1px 6px rgb(0 0 0 / 43%) !important;
}

/*  my profile */

.dark .pictureBlock.mat-card::after,
.dark .prac-overview::after {
  background: #383838;
  background: linear-gradient(to top, #585858, #383838) !important;
}

.dark app-ep-document-view-image.display-image {
  border-color: #58585a !important;
}

/* overview pages  */

.dark .subStatcontainer .greyRow,
.dark .greyRow {
  background: #333 !important;
}

.dark text.pie-label,
.dark text {
  fill: #e8e8e8 !important;
}

.dark .mat-dialog-container .mat-card {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 40px 0 rgb(0 0 0 / 50%), 0 1px 15px 0 rgb(0 0 0 / 30%) !important;
}

.dark app-contracts-view .contractViewMain.mat-card {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.dark .qa label.rm-mt {
  border-bottom: 1px dotted #5f5f5f !important;
}

.dark .num.anim.statusList:first-child {
  background: rgb(32 37 32 / 63%) !important;
  box-shadow: inset 0 1px 1px rgb(31 31 31 / 10%), inset 0 1px 8px rgb(31 31 31 / 10%) !important;
}

.dark h4.primary-color .list-label {
  filter: brightness(90%) grayscale(1.5) !important;
}

.dark app-settlement-view .num.anim.statusList:first-child {
  background: none !important;
}

.dark vertical-timeline {
  background: transparent !important;
}

.dark vertical-timeline .timeline-content {
  background: #353535;
}

.dark vertical-timeline .timeline-content::before {
  border-bottom: 1px solid #232323;
  background: #353535;
}

.dark vertical-timeline .timeline-content::after {
  border-color: transparent transparent transparent #353535;
}

.dark vertical-timeline .timeline-item:nth-child(even) .timeline-content::after {
  border-color: transparent #353535 transparent transparent;
}

/* calculator */
.dark mat-card.calculator,
.dark mat-card.calculator .front,
.dark .prodCard,
.dark .mat-card,
.dark mat-card.calculator .back,
.dark .calculator {
  background: #434343 !important;
}

.dark .inlined-label .mat-form-field-flex.mat-form-field-flex {
  background: #434343 !important;
}

.dark .breakdown.highlight {
  background: #3a3a3a !important;
}

.dark .btn-large.mat-raised-button[disabled] {
  background-color: rgb(90 90 90) !important;
}

/* utilities */
.dark .toggles {
  border-color: #656565 !important;
  background: transparent !important;
}

.dark .introjs-tooltip {
  background: #434343 !important;
  color: #fff !important;
}

.dark .introjs-helperLayer {
  background: transparent !important;
}

.dark .introjs-button:hover {
  box-shadow: 0 1px 1px #2b2b2b !important;
}

.dark .mat-select-content,
.mat-select-panel-done-animating {
  background: #464646 !important;
}

.dark .mat-option {
  color: rgb(255 255 255 / 87%) !important;
}

.dark .mat-dialog-container {
  background: #424242 !important;
  color: rgb(255 255 255 / 87%) !important;
}

.dark .noCard mat-dialog-container,
.dark .noCard .mat-dialog-container {
  background: none !important;
  box-shadow: none;
}

.dark .infoWell {
  border: 1px solid #2d2d2d !important;
  background: #313131 !important;
}

.dark .infoWell p.name {
  border-bottom: 1px solid #444 !important;
}

.dark mat-card-header.mat-card-header {
  background: #2f2f2f !important;
}

.dark .audit .mat-tab-header {
  background: #424242 !important;
}

.dark .auditInfo .mat-tab-label {
  border-right: 1px solid #2d2d2d !important;
  background: #424242 !important;
  background: linear-gradient(to bottom, #424242 0%, #4c4c4c 50%, #3c3c3c 51%, #424242 100%) !important;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#424242', endColorstr='#424242', GradientType=0) !important;
}

.dark mat-icon.xlarge-modal-icon::after {
  background: #424242 !important;
}

.dark .toggle-card mat-button-toggle-group {
  background: #434343 !important;
}

.dark .toggle-card mat-button-toggle {
  border-bottom: 3px solid #434343 !important;
}

.dark .toggle-card .btn-group .mat-button-toggle-checked label {
  color: #dcdcdc !important;
}

.dark .isAvailable {
  color: #00e681 !important;
}

.dark .clearfix.settleWell .row.clearfix.grey {
  background: #525252 !important;
}

.dark .clearfix.settleWell:hover {
  background: #2b2b2b !important;
}

.dark .clearfix.settleWell:hover .row.clearfix.grey {
  background: #313131 !important;
}

.dark span.badge {
  border: 1px solid #000;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #fff !important;
  text-shadow: none !important;
}

.dark label strong.greyble {
  filter: brightness(5000%) grayscale(1.5);
}

.dark .activeExistingcustomer,
.dark .customerRecord.activeExistingcustomer {
  background: #2f2f2f !important;
}

.dark .span-total-color {
  background: #0f0f0f !important;
  color: rgb(118 118 118 / 60%) !important;
}

.dark .customerRecord.noCard:hover {
  border-bottom: black !important;
  background: #383838 !important;
}

.dark .customerRecord.noCard {
  border-top: 1px solid #353535 !important;
}

.dark .hicaps-logo {
  filter: invert(50%);
}

.dark .fadeInDown.file-container {
  border-color: #181818 !important;
  background: #2d2d2d;
  box-shadow: none !important;
}

.dark mat-list.mat-list.small .odd .mat-list-item-content {
  background: #3e3e3e !important;
}

.dark .display .mat-list mat-list-item.less.mat-list-item {
  border-top: 1px solid #333 !important;
  color: #b7b7b7 !important;
}

.dark .row.clearfix.grey {
  background: #2b2b2b !important;
}

.dark .tipInfo::before {
  background: #4e4e4e !important;
}

.dark .mat-slide-toggle-thumb {
  background-color: #828282 !important;
}

/* map  */

.dark .si-content-wrapper {
  background-color: #383838 !important;
}

.dark .si-pointer-bg-top {
  border-top-color: #383838 !important;
}

/*  templates */

.dark .templateContainer .greyArea {
  background: #292929 !important;
}

.dark mat-card.template-preview .status-row {
  background: #353535 !important;
}

.dark .selectedTemplate {
  background: #353535 !important;
}

/*  profile pages */

.dark app-customer-prospect-list-overview .profile-sidebar {
  background: #434343 !important;
}

.dark app-prospect-profile-tabs .mat-tab-body-content.ng-trigger.ng-trigger-translateTab,
.dark app-customer-profile-tabs .mat-tab-body-content.ng-trigger.ng-trigger-translateTab {
  background-color: transparent !important;
}

.dark mat-sidenav.profile-sidebar {
  background: #434343;
}

.dark mat-sidenav.profile-sidebar p {
  color: #fff;
}

.dark app-customer-prospect-list-overview .mat-drawer {
  color: rgb(231 231 231) !important;
}

.dark .profile-container .mat-tab-label-container {
  background: #383838 !important;
}

.dark app-customer-prospect-list-overview .mat-drawer-container {
  background-color: transparent !important;
}

.dark .block.yes,
.dark .block.wide {
  border: 1px solid rgb(255 255 255 / 10%) !important;
  background: #3b3b3b !important;
}

.dark .colour-stripe {
  filter: grayscale(0.8);
}

.dark .collapsable .timeline-item .mat-expansion-panel-body {
  border-top: 1px solid #272728;
  border-bottom: 1px solid #272728;
  background: #383838;
}

/*  treatment and supplier */

.dark .supplier-card mat-accordion.grey-row .mat-expansion-panel-body {
  background: #3b3b3b !important;
}

.dark mat-icon.iocon {
  background: #4d4d4d !important;
  color: #a8a8a8 !important;
}

.dark app-ep-document-view-image::after {
  background: #323232 !important;
}

/*
.dark .indOvf {
  background:
    !* Shadow covers *!
    linear-gradient(black 30%, rgba(0, 0, 0, 0)),
    linear-gradient(rgba(0, 0, 0, 0), black 70%) 0 100%,

    !* Shadows *!
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background:
    !* Shadow covers *!
    linear-gradient(#11111138 30%, rgba(44, 44, 44, 0)), linear-gradient(rgba(0, 0, 0, 0), #414141 70%) 0 100%,
    !* Shadows *!
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: #434343;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
*/
