@font-face {
  font-family: icomoon;
  font-style: normal;
  font-weight: normal;
  src: url('/assets/styles/fonts/icomoon.eot?kmknv1');
  src: url('/assets/styles/fonts/icomoon.eot?kmknv1#iefix') format('embedded-opentype'),
    url('/assets/styles/fonts/icomoon.ttf?kmknv1') format('truetype'),
    url('/assets/styles/fonts/icomoon.woff?kmknv1') format('woff'),
    url('/assets/styles/fonts/icomoon.svg?kmknv1#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon-tooth-solid-(1):before {
                       content: '\e900';
}

.icon-tooth-q4 .path1::before {
  color: rgb(208 210 211);
  content: '\e901';
}

.icon-tooth-q4 .path2::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e902';
}

.icon-tooth-q4 .path3::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e903';
}

.icon-tooth-q4 .path4::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e904';
}

.icon-tooth-q4 .path5::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e905';
}

.icon-tooth-q4 .path6::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e906';
}

.icon-tooth-q4 .path7::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e907';
}

.icon-tooth-q4 .path8::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e908';
}

.icon-tooth-q4 .path9::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e909';
}

.icon-tooth-q4 .path10::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e90a';
}

.icon-tooth-q4 .path11::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e90b';
}

.icon-tooth-q4 .path12::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e90c';
}

.icon-tooth-q4 .path13::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e90d';
}

.icon-tooth-q4 .path14::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e90e';
}

.icon-tooth-q4 .path15::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e90f';
}

.icon-tooth-q4 .path16::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e910';
}

.icon-tooth-q4 .path17::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e911';
}

.icon-tooth-q4 .path18::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e912';
}

.icon-tooth-q4 .path19::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e913';
}

.icon-tooth-q4 .path20::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e914';
}

.icon-tooth-q4 .path21::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e915';
}

.icon-tooth-q4 .path22::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e916';
}

.icon-tooth-q4 .path23::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e917';
}

.icon-tooth-q4 .path24::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e918';
}

.icon-tooth-q4 .path25::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e919';
}

.icon-tooth-q4 .path26::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e91a';
}

.icon-tooth-q4 .path27::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e91b';
}

.icon-tooth-q4 .path28::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e91c';
}

.icon-tooth-q4 .path29::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e91d';
}

.icon-tooth-q4 .path30::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e91e';
}

.icon-tooth-q4 .path31::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e91f';
}

.icon-tooth-q4 .path32::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e920';
}

.icon-tooth-q4 .path33::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e921';
}

.icon-tooth-q4 .path34::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e922';
}

.icon-tooth-q4 .path35::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e923';
}

.icon-tooth-q4 .path36::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e924';
}

.icon-tooth-q3 .path1::before {
  color: rgb(208 210 211);
  content: '\e925';
}

.icon-tooth-q3 .path2::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e926';
}

.icon-tooth-q3 .path3::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e927';
}

.icon-tooth-q3 .path4::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e928';
}

.icon-tooth-q3 .path5::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e929';
}

.icon-tooth-q3 .path6::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e92a';
}

.icon-tooth-q3 .path7::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e92b';
}

.icon-tooth-q3 .path8::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e92c';
}

.icon-tooth-q3 .path9::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e92d';
}

.icon-tooth-q3 .path10::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e92e';
}

.icon-tooth-q3 .path11::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e92f';
}

.icon-tooth-q3 .path12::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e930';
}

.icon-tooth-q3 .path13::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e931';
}

.icon-tooth-q3 .path14::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e932';
}

.icon-tooth-q3 .path15::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e933';
}

.icon-tooth-q3 .path16::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e934';
}

.icon-tooth-q3 .path17::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e935';
}

.icon-tooth-q3 .path18::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e936';
}

.icon-tooth-q3 .path19::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e937';
}

.icon-tooth-q3 .path20::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e938';
}

.icon-tooth-q3 .path21::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e939';
}

.icon-tooth-q3 .path22::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e93a';
}

.icon-tooth-q3 .path23::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e93b';
}

.icon-tooth-q3 .path24::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e93c';
}

.icon-tooth-q3 .path25::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e93d';
}

.icon-tooth-q3 .path26::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e93e';
}

.icon-tooth-q3 .path27::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e93f';
}

.icon-tooth-q3 .path28::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e940';
}

.icon-tooth-q3 .path29::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e941';
}

.icon-tooth-q3 .path30::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e942';
}

.icon-tooth-q3 .path31::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e943';
}

.icon-tooth-q3 .path32::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e944';
}

.icon-tooth-q3 .path33::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e945';
}

.icon-tooth-q3 .path34::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e946';
}

.icon-tooth-q3 .path35::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e947';
}

.icon-tooth-q3 .path36::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e948';
}

.icon-tooth-q2 .path1::before {
  color: rgb(0 146 235);
  content: '\e949';
}

.icon-tooth-q2 .path2::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e94a';
}

.icon-tooth-q2 .path3::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e94b';
}

.icon-tooth-q2 .path4::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e94c';
}

.icon-tooth-q2 .path5::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e94d';
}

.icon-tooth-q2 .path6::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e94e';
}

.icon-tooth-q2 .path7::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e94f';
}

.icon-tooth-q2 .path8::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e950';
}

.icon-tooth-q2 .path9::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e951';
}

.icon-tooth-q2 .path10::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e952';
}

.icon-tooth-q2 .path11::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e953';
}

.icon-tooth-q2 .path12::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e954';
}

.icon-tooth-q2 .path13::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e955';
}

.icon-tooth-q2 .path14::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e956';
}

.icon-tooth-q2 .path15::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e957';
}

.icon-tooth-q2 .path16::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e958';
}

.icon-tooth-q2 .path17::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e959';
}

.icon-tooth-q2 .path18::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e95a';
}

.icon-tooth-q2 .path19::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e95b';
}

.icon-tooth-q2 .path20::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e95c';
}

.icon-tooth-q2 .path21::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e95d';
}

.icon-tooth-q2 .path22::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e95e';
}

.icon-tooth-q2 .path23::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e95f';
}

.icon-tooth-q2 .path24::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e960';
}

.icon-tooth-q2 .path25::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e961';
}

.icon-tooth-q2 .path26::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e962';
}

.icon-tooth-q2 .path27::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e963';
}

.icon-tooth-q2 .path28::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e964';
}

.icon-tooth-q2 .path29::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e965';
}

.icon-tooth-q2 .path30::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e966';
}

.icon-tooth-q2 .path31::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e967';
}

.icon-tooth-q2 .path32::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e968';
}

.icon-tooth-q2 .path33::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e969';
}

.icon-tooth-q2 .path34::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e96a';
}

.icon-tooth-q2 .path35::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e96b';
}

.icon-tooth-q2 .path36::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e96c';
}

.icon-tooth-q1 .path1::before {
  color: rgb(208 210 211);
  content: '\e96d';
}

.icon-tooth-q1 .path2::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e96e';
}

.icon-tooth-q1 .path3::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e96f';
}

.icon-tooth-q1 .path4::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e970';
}

.icon-tooth-q1 .path5::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e971';
}

.icon-tooth-q1 .path6::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e972';
}

.icon-tooth-q1 .path7::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e973';
}

.icon-tooth-q1 .path8::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e974';
}

.icon-tooth-q1 .path9::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e975';
}

.icon-tooth-q1 .path10::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e976';
}

.icon-tooth-q1 .path11::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e977';
}

.icon-tooth-q1 .path12::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e978';
}

.icon-tooth-q1 .path13::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e979';
}

.icon-tooth-q1 .path14::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e97a';
}

.icon-tooth-q1 .path15::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e97b';
}

.icon-tooth-q1 .path16::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e97c';
}

.icon-tooth-q1 .path17::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e97d';
}

.icon-tooth-q1 .path18::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e97e';
}

.icon-tooth-q1 .path19::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e97f';
}

.icon-tooth-q1 .path20::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e980';
}

.icon-tooth-q1 .path21::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e981';
}

.icon-tooth-q1 .path22::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e982';
}

.icon-tooth-q1 .path23::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e983';
}

.icon-tooth-q1 .path24::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e984';
}

.icon-tooth-q1 .path25::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e985';
}

.icon-tooth-q1 .path26::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e986';
}

.icon-tooth-q1 .path27::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e987';
}

.icon-tooth-q1 .path28::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e988';
}

.icon-tooth-q1 .path29::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e989';
}

.icon-tooth-q1 .path30::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e98a';
}

.icon-tooth-q1 .path31::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e98b';
}

.icon-tooth-q1 .path32::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e98c';
}

.icon-tooth-q1 .path33::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e98d';
}

.icon-tooth-q1 .path34::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e98e';
}

.icon-tooth-q1 .path35::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e98f';
}

.icon-tooth-q1 .path36::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e990';
}

.icon-tooth-bottom .path1::before {
  color: rgb(208 210 211);
  content: '\e991';
}

.icon-tooth-bottom .path2::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e992';
}

.icon-tooth-bottom .path3::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e993';
}

.icon-tooth-bottom .path4::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e994';
}

.icon-tooth-bottom .path5::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e995';
}

.icon-tooth-bottom .path6::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e996';
}

.icon-tooth-bottom .path7::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e997';
}

.icon-tooth-bottom .path8::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e998';
}

.icon-tooth-bottom .path9::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e999';
}

.icon-tooth-bottom .path10::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e99a';
}

.icon-tooth-bottom .path11::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e99b';
}

.icon-tooth-bottom .path12::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e99c';
}

.icon-tooth-bottom .path13::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e99d';
}

.icon-tooth-bottom .path14::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e99e';
}

.icon-tooth-bottom .path15::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e99f';
}

.icon-tooth-bottom .path16::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9a0';
}

.icon-tooth-bottom .path17::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a1';
}

.icon-tooth-bottom .path18::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a2';
}

.icon-tooth-bottom .path19::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a3';
}

.icon-tooth-bottom .path20::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a4';
}

.icon-tooth-bottom .path21::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a5';
}

.icon-tooth-bottom .path22::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a6';
}

.icon-tooth-bottom .path23::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a7';
}

.icon-tooth-bottom .path24::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a8';
}

.icon-tooth-bottom .path25::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9a9';
}

.icon-tooth-bottom .path26::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9aa';
}

.icon-tooth-bottom .path27::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9ab';
}

.icon-tooth-bottom .path28::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9ac';
}

.icon-tooth-bottom .path29::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9ad';
}

.icon-tooth-bottom .path30::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9ae';
}

.icon-tooth-bottom .path31::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9af';
}

.icon-tooth-bottom .path32::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9b0';
}

.icon-tooth-bottom .path33::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9b1';
}

.icon-tooth-bottom .path34::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9b2';
}

.icon-tooth-bottom .path35::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9b3';
}

.icon-tooth-bottom .path36::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9b4';
}

.icon-tooth-top .path1::before {
  color: rgb(208 210 211);
  content: '\e9b5';
}

.icon-tooth-top .path2::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9b6';
}

.icon-tooth-top .path3::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9b7';
}

.icon-tooth-top .path4::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9b8';
}

.icon-tooth-top .path5::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9b9';
}

.icon-tooth-top .path6::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9ba';
}

.icon-tooth-top .path7::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9bb';
}

.icon-tooth-top .path8::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9bc';
}

.icon-tooth-top .path9::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9bd';
}

.icon-tooth-top .path10::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9be';
}

.icon-tooth-top .path11::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9bf';
}

.icon-tooth-top .path12::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c0';
}

.icon-tooth-top .path13::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c1';
}

.icon-tooth-top .path14::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c2';
}

.icon-tooth-top .path15::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c3';
}

.icon-tooth-top .path16::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c4';
}

.icon-tooth-top .path17::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c5';
}

.icon-tooth-top .path18::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c6';
}

.icon-tooth-top .path19::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c7';
}

.icon-tooth-top .path20::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c8';
}

.icon-tooth-top .path21::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9c9';
}

.icon-tooth-top .path22::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9ca';
}

.icon-tooth-top .path23::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9cb';
}

.icon-tooth-top .path24::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9cc';
}

.icon-tooth-top .path25::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9cd';
}

.icon-tooth-top .path26::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9ce';
}

.icon-tooth-top .path27::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9cf';
}

.icon-tooth-top .path28::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9d0';
}

.icon-tooth-top .path29::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9d1';
}

.icon-tooth-top .path30::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9d2';
}

.icon-tooth-top .path31::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9d3';
}

.icon-tooth-top .path32::before {
  margin-left: -0.701171875em;
  color: rgb(208 210 211);
  content: '\e9d4';
}

.icon-tooth-top .path33::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9d5';
}

.icon-tooth-top .path34::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9d6';
}

.icon-tooth-top .path35::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9d7';
}

.icon-tooth-top .path36::before {
  margin-left: -0.701171875em;
  color: rgb(0 146 235);
  content: '\e9d8';
}

.icon-tooth-full::before {
  color: #0092eb;
  content: '\e9d9';
}

.icon-tooth-blank::before {
  color: #d0d2d3;
  content: '\e9da';
}

.icon-tooth-solid::before {
  content: '\e9db';
}
