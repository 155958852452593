@import '~material-design-icons-iconfont/dist/material-design-icons.css';

/* Utility Classes */

.container {
  max-width: 100%;
  padding: 0 0 50px;
  margin: 0 8px 20px !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex.force {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.flexwrap {
  flex-wrap: wrap;
}

.flexend {
  justify-content: flex-end;
}

.flexcenter {
  align-items: center;
}

.flex-just-between {
  align-items: center;
  justify-content: space-between;
}

.noOverflow {
  overflow: hidden;
}

.d-none {
  display: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.column {
  padding-right: 5px;
  padding-left: 5px;
}

.col-left {
  padding-right: 0;
}

.col-right {
  padding-left: 0;
}

.full-width,
.half-width,
.qtr-width,
.fifth-width,
.thrd-width,
.two-thrd-width,
.thr-qtr-width {
  width: 100%;
}

.rot-180 {
  transform: rotate(180deg);
}

.inline {
  display: inline-block;
  vertical-align: top;
}

.padded {
  padding: 0 15px;
}

.rm-mt {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.rm-mb {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.rm-pt {
  padding-top: 0 !important;
}

.rm-m {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.rm-p {
  padding: 0 !important;
}

.rm-bp {
  padding-bottom: 0 !important;
}

.rm-ml {
  margin-left: 0 !important;
}

.rm-mr {
  margin-right: 0 !important;
}

.smb {
  margin-bottom: 5px !important;
}

.mb {
  margin-bottom: 10px !important;
}

.reduce-m {
  margin: 5px 0 !important;
}

.reduce-marg {
  padding: 0;
  margin: 5px 0 10px;
}

.add-mb {
  margin-bottom: 20px !important;
}

.mt {
  margin-top: 10px !important;
}

.smt {
  margin-top: 10px !important;
}

.add-mt {
  margin-top: 20px !important;
}

.extra-mt {
  margin-top: 40px !important;
}

.sm-mb {
  margin-bottom: 0.5rem !important;
}

.mr {
  margin-right: 1rem !important;
}

.smr {
  margin-right: 0.5rem !important;
}

.add-mr {
  margin-right: 2rem !important;
}

.sm-mr {
  margin-right: 0.5rem !important;
}

.ml {
  margin-left: 1rem !important;
}

.sml {
  margin-left: 0.5rem !important;
}

.no-pad {
  padding: 0 !important;
}

.rel {
  position: relative;
}

.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.op09 {
  opacity: 0.9;
}

.op08 {
  opacity: 0.8;
}

.op07 {
  opacity: 0.7;
}

.op06 {
  opacity: 0.6;
}

.op05 {
  opacity: 0.5;
}

.op04 {
  opacity: 0.4;
}

.full-height {
  height: 100% !important;
}

/* Native Web Elements  */

body,
html {
  padding: 0;
  margin: 0;
}

textarea {
  resize: none !important;
}

.sm-h2 {
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.3rem;
}

.sm-h3 {
  padding: 1rem 0;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.5rem;
}

.sm-h3-sml {
  padding: 1rem 0 0;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.5rem;
}

h3.sr-title {
  padding-bottom: 0;
}

h4.subtitle-h4 {
  margin-top: 10px;
  color: #777;
  font-size: 0.9rem;
  font-weight: 400;
}

.sm-h1 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2rem;
}

.lg-h1 {
  margin: 0 1%;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 2rem;
}

p {
  font-weight: 300;
  -webkit-margin-after: 0.5em;
  -webkit-margin-before: 0.5em;
}

p.lead {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 2rem;
}

p.small {
  font-size: 0.75rem;
  font-weight: 300;
}

p.larger {
  font-size: 1.08rem;
  font-weight: 300;
  line-height: 1.55rem;
}

label {
  display: block;
  margin-bottom: 0;
  color: #9a9a9a;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
}

label.small {
  font-size: 0.75rem;
}

p em {
  font-size: 0.9rem;
}

.truncate {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.isAvailable {
  color: #02b168;
}

.noAvailable {
  color: red;
}

h1.transparent,
h2.transparent h3.transparent h4.transparent h5.transparent h6.transparent p.transparent {
  opacity: 0.5;
}

/* Material & CDK */

.material-icons,
mat-icon,
.mat-icon {
  /* Preferred icon size */
  display: inline-block;
  direction: ltr;
  font-family: 'Material Icons';

  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 24px;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;

  /* Support for Safari and Chrome. */
  text-rendering: optimizelegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}

mat-sidenav-container.merchant-dash.mat-drawer-container {
  background: url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/geometry2.png);
  background-repeat: repeat;
}

mat-button-toggle-group.mobileFlow {
  flex-flow: revert;
}

mat-list-item,
.mat-list .mat-list-item,
.mat-list .mat-list-option,
.mat-nav-list .mat-list-item,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-item,
.mat-selection-list .mat-list-option {
  height: auto !important;
}

.button-group-tabs .mat-tab-labels {
  border-top: 1px solid #e0e0e0;
  background-color: white;
}

.mat-tab-header {
  margin-bottom: 20px !important;
}

.mat-form-field-infix {
  width: unset !important;
  min-width: 150px !important;
}

.mat-dialog-content {
  width: 100%;
  padding: 0 16px !important;
  margin: 0 -16px !important;
}

.mat-dialog-container {
  max-height: 90vh !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
}

mat-icon {
  /* Support for IE. */
  font-feature-settings: 'liga';

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for Safari and Chrome. */
  text-rendering: optimizelegibility;
}

mat-icon.fa {
  font-size: 1.2rem;
  text-align: center;
}

/* HIDE CONSUMER FOOTER TEMPORARILY */

#consumer-footer {
  display: none;
}

/* Layout */

.pac-container {
  z-index: 100000000000000 !important;
}

.body-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.body-container-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

/* BG style needs to set for login page only */

.background_pattern {
  background-repeat: repeat !important;
}

.input-cont-height {
  position: relative;
  display: block;
  min-height: 49.5px;
  padding-bottom: 1rem;
}

.thrd-width.grey-col {
  width: auto;
  padding: 16px 0 16px 16px;
  margin: -24px -16px 0;
  background: #f7f7f9;
}

app-consumer-header#application-header {
  z-index: 999;
}

/* Mobile First */

.pull-left,
p.mobPullLeft {
  float: left;
}

.pull-right,
p.mobPullRight {
  float: right;
}

p.mobile {
  padding-right: revert;
  padding-left: revert;
}

p.mobile2 {
  padding-right: revert;
  padding-left: revert;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

/*  list override */

@media (min-width: 768px) {
  .flex {
    display: flex;
  }

  .mobWrap {
    display: flex;
  }

  .full-width {
    width: 100% !important;
    float: left;
  }

  .half-width {
    width: 50% !important;
    float: left;
  }

  .qtr-width {
    width: 25% !important;
    float: left;
  }

  .thr-qtr-width {
    width: 75% !important;
    float: left;
  }

  .thrd-width {
    width: 33.33% !important;
    float: left;
  }

  .fifth-width {
    width: 20% !important;
    float: left;
  }

  .two-thrd-width {
    width: 66.66% !important;
    float: left;
  }

  .column {
    padding-right: 20px;
    padding-left: 20px;
  }

  .col-left {
    padding-right: 20px;
  }

  .col-right {
    padding-left: 20px;
  }

  .flex {
    display: flex;
  }

  .container {
    max-width: 100%;
    padding: 10px 15px 50px;
    margin: 0 auto 50px !important;
  }

  app-dashbord-over-view .container.main {
    padding: 10px 0 0;
  }

  .push-out {
    margin: 0 -24px -24px;
  }

  /* Flex fitting */
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .flex-row .flex-block {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 1%;
    margin-left: 0;
  }

  .flex-row .flex-block:last-child {
    margin-right: 0;
  }

  .thrd-width.grey-col {
    padding: 24px 0 24px 24px;
    margin: -24px 0 -24px -24px;
    background: #f7f7f9;
  }

  .ml-auto {
    margin-left: auto !important;
  }

  .mr-auto {
    margin-right: auto !important;
  }
}

@media (min-width: 1200px) {

  app-main .mat-drawer-content,
  app-merchant-main .mat-drawer-content,
  app-promoter-main .mat-drawer-content,
  app-supplier-main .mat-drawer-content,
  app-funder-main .mat-drawer-content,
  app-consumer-main .display-cutomer-portal .mat-drawer-content {
    margin-left: 230px !important;
  }
}

@media (min-width: 1100px) {
  .container {
    max-width: 100%;
    padding: 10px 0 50px;
    margin: 0 auto !important;
  }
}

@media (min-width: 991px) {

  app-merchant-main .mat-drawer-content,
  app-promoter-main .mat-drawer-content,
  app-supplier-main .mat-drawer-content,
  app-funder-main .mat-drawer-content {
    height: 100vh !important;
  }
}

.full-width input,
.full-width.mat-form-field,
.pioneer-theme .full-width input,
.pioneer-theme .full-width.mat-form-field {
  width: 100%;
}

.treatment-card .mat-tab-header {
  border-bottom: none !important;
  margin-bottom: 0 !important;
}

span.block {
  display: block !important;
}

span.block.small {
  font-size: 0.7rem;
}

.wideMenu.mat-menu-panel {
  max-width: 400px;
}

.rm-bp mat-dialog-container {
  padding-bottom: 0 !important;
}

app-ep-document-view-image {
  display: flex;
  min-width: 40px;
  min-height: 40px;
}

.search .mat-form-field-appearance-outline .mat-form-field-prefix,
.search .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: -1em !important;
  margin-right: 5px;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: -0.25em !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  top: -0.25em;
  padding: 0.7em 0 !important;
}

.searchArea .mat-form-field-type-mat-input .mat-form-field-flex,
.searchArea-wht .mat-form-field-type-mat-input .mat-form-field-flex {
  padding: 0.15em 0.7em 0.1em !important;
}

.searchArea .mat-form-field-type-mat-input .mat-form-field-wrapper,
.searchArea-wht .mat-form-field-type-mat-input .mat-form-field-wrapper {
  margin-bottom: 0 !important;

  /* margin-top: 0.6em; */
}

.searchArea app-date-input .mat-form-field-appearance-outline .mat-form-field-infix,
.searchArea-wht app-date-input .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.55em 0 0.5em !important;
}

.searchArea .mat-form-field-type-mat-input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;

  /* Firefox */
}

.searchArea .matfieldmat-input :input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.searchArea .matfieldmat-input ::input-placeholder {
  /* Microsoft Edge */
  color: white;
}

app-phone-input.full-width mat-form-field {
  margin: 0 !important;
}

.mat-form-field-appearance-outline.mat-select-adjust .mat-form-field-infix {
  padding: 0.55em 0 !important;
}

.mat-form-field-type-mat-select.mat-form-field-appearance-outline .mat-form-field-flex {
  padding-bottom: 0.15em;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0.75em;
}

.mat-form-field-appearance-outline .mat-form-field-suffix button {
  min-width: 20px;
  padding: 0 7px;
}

/*  indicate overflow */

@media (max-width: 768px) {
  .wideMenu.mat-menu-panel {
    max-width: 100%;
  }
}

/*  dialog content padding override */
.mat-dialog-content::-webkit-scrollbar,
.indOvf::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.mat-dialog-content,
.indOvf {
  /* Firefox */
  -ms-overflow-style: auto;
  scrollbar-width: auto;

  /* IE 10+ */
}

/* initial textarea height */

textarea.mat-input-element {
  height: 18px;
}

.mat-input-element {
  line-height: 1rem !important;
}

.row.clearfix textarea.mat-input-element {
  min-height: 100px;
  line-height: 1.35rem !important;
}

.ql-editor {
  min-height: 300px;
  overflow-y: scroll !important;
  resize: vertical;
}

/* icon column in forms */

.icons-col {
  display: flex;
  max-width: 50px;
  float: left;
}

.icons-col mat-icon {
  position: relative;
  top: 18px;
  color: #a5a5a5;
}

.row-content-col {
  width: calc(100% - 45px);
  padding: 0 10px;
  float: right;
}

.icons-col.adjusted mat-icon {
  position: relative;
  top: 0;
  color: #a5a5a5;
}

@media (min-width: 768px) {
  .mb {
    margin-bottom: 20px !important;
  }

  .smb {
    margin-bottom: 10px !important;
  }

  .add-mb {
    margin-bottom: 40px !important;
  }

  .mt {
    margin-top: 20px !important;
  }

  .smt {
    margin-top: 10px !important;
  }

  .add-mt {
    margin-top: 40px !important;
  }

  .row-content-col {
    width: calc(100% - 50px);
    padding: 0 0 0 20px;
    float: right;
  }
}

/* Typography */

h1,
h2,
h3,
h4,
h5,
h6,
h1.sr-title,
h2.sr-title,
h3.sr-title,
h4.sr-title,
h5.sr-title,
h6.sr-title,
.mat-h1,
.mat-h2,
.mat-h3,
.mat-h4,
.mat-h5,
.mat-h6,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-card,
.mat-list-item,
mat-card,
div[class*=' mat-'],
.mat-table,
text,
table,
.table-striped,
.dataTable,
.mat-select,
.datepicker-actions__input,
.mat-expansion-panel-header {
  font-family: Nunito, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.condiment,
.sign-text p {
  font-family: Condiment, cursive !important;
}

.sign-text p {
  display: none;
}

p.lead.condiment {
  position: relative;
  z-index: 1;
  font-size: 1.65rem;
  font-weight: normal;
  text-align: center;
}

.white {
  color: #fff;
}

.white.ap-title,
.white.ap-subtitle {
  color: #fff;
  text-align: center;
}

.white.ap-title {
  margin-bottom: 0;
  font-size: 1.7rem;
}

.white.ap-subtitle {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 300;
}

.truncateTitle {
  overflow: hidden;
  width: calc(100% - 40px);
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .row.flex .mat-raised-button {
  min-width: initial !important;
} */

@media (min-width: 768px) {
  p {
    -webkit-margin-after: 1em;
    -webkit-margin-before: 1em;
  }

  .sign-text p {
    display: block;
  }

  p.lead {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  p.small,
  p.ql-size-small {
    font-size: 0.8rem;
  }

  p.larger {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.8rem;
  }

  .white.ap-title {
    font-size: 50px;
  }

  .white.ap-subtitle {
    margin-bottom: 45px;
    font-size: 1.1rem;
  }

  .sm-h2 {
    padding: 1rem 0;
    margin: 5px 0 0;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.5rem;
  }

  p em {
    font-size: 1rem;
  }

  p.small em {
    font-size: 0.9rem;
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center;
}

.flexCenter,
.flex-center {
  display: flex;
  align-items: center;
}

.flex-start {
  justify-content: flex-start !important;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
  justify-content: space-between;
}

.mat-form-field-label {
  font-weight: 300;
}

h2.sr-title {
  font-weight: 200;
  letter-spacing: 1px;
}

h4.sr-subtitle {
  padding-top: 0;
  margin-top: 0;
  color: #808080;
  font-weight: 300;
}

h4.rm-mb {
  padding-bottom: 10px !important;
}

hr {
  width: 100%;
  height: 1px;
  border: none;
  background: #e4e4e4;
}

p.ticked {
  position: relative;
  padding-left: 25px;
}

mat-icon.tiny.green {
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  color: #1cb91c;
  font-size: 16px;
  font-weight: 600;
}

.noTop {
  top: 0 !important;
}

.sr-title.patientcard-title {
  font-family: Nunito, 'Helvetica Neue', sans-serif !important;
  font-weight: 300;
}

.mat-tooltip {
  font-size: 14px !important;
}

.bold {
  font-weight: bold !important;
}

.text-right {
  text-align: right;
}

mat-card p.mobile {
  padding-top: revert;
}

.mat-select-placeholder {
  letter-spacing: 1px;
}

.mat-form-field-appearance-outline .mat-select {
  padding-top: 0;
}

.mat-option-text {
  padding: 0.5rem 0;
  line-height: 1.6rem;
  white-space: normal;
}

.mat-select-panel .mat-option {
  height: unset !important;
  min-height: 3em;
}

.phoneNum {
  white-space: nowrap;
}

label.slider-label.inline {
  font-size: 1.3rem;
}

.merchant-calculator label.slider-label.inline {
  font-size: 1rem;
}

@media (max-width: 768px) {
  h3.mobile {
    padding-right: 0.85vw;
    padding-left: 0.85vw;
  }

  .input-cont-height .half-width {
    min-height: 50px;
    margin-bottom: 1rem;
  }

  .input-cont-height .half-width:last-child {
    margin-bottom: 0;
  }

  mat-card p.mobile {
    padding-top: 40px;
  }

  .mobWrap {
    flex-wrap: wrap;
  }

  p.mobile {
    padding-right: 0.5vw;
    padding-left: 0.5vw;
  }

  p.mobile2 {
    padding-right: 4vw;
    padding-left: 4vw;
  }

  p.mobSm {
    font-size: 0.85rem;
    line-height: 1.1rem;
  }

  p.mobPullLeft {
    padding-left: 1.5vw;
    float: left;
  }

  p.mobPullRight {
    padding-right: 1.5vw;
    float: right;
  }

  mat-button-toggle-group.mobileFlow {
    flex-flow: row wrap;
  }
}

@media (max-width: 500px) {

  .mat-select-placeholder,
  .mat-form-field-labeler {
    font-size: 13px;
    letter-spacing: 0.2px;
  }
}

.info-block {
  position: relative;
  padding: 20px 20px 20px 65px;
  border: 1px solid #f1f1f1;
  margin: 35px 0;
  text-align: left;
}

.info-block .icon {
  position: absolute;
  top: 20px;
  left: 20px;
}

.info-block p {
  margin: 0;
}

/* Warnings and alerts */

.warning {
  padding: 5px 8px;
  border: 1px solid #f54437;
  border-radius: 3px;
  margin: 0 0 15px;
  background: #fff5f5;
  clear: both;
  color: #f54437;
}

.warning p {
  padding: 0;
  margin: 0;
  color: #f54437;
  font-size: 0.85rem;
}

p.small.warn {
  padding: 5px 8px !important;
  border: 1px solid #f54437;
  border-radius: 3px;
  margin: 0 0 15px;
  background: #fff4eb;
  clear: both;
  color: #f54437;
}

p.small.warn mat-icon {
  position: relative;
  top: 2px;
  width: auto;
  height: auto;
  margin-right: 5px;
  font-size: 0.9rem;
}

p.small.notice {
  padding: 5px 8px !important;
  border: 1px solid #f57337;
  border-radius: 3px;
  margin: 0 0 15px;
  background: #fff4eb;
  clear: both;
  color: orangered;
}

p.small.notice mat-icon {
  position: relative;
  top: 2px;
  width: auto;
  height: auto;
  margin-right: 5px;
  font-size: 0.9rem;
}

p.small.info,
.information-container {
  padding: 5px 8px !important;
  border: 1px solid #3783f5;
  border-radius: 3px;
  margin: 0 0 15px;
  background: #f7fafe;
  clear: both;
  color: rgb(0 110 255);
}

p.small.info a,
.information-container a {
  color: rgb(0 82 189);
}

p.small.info mat-icon,
.information-container mat-icon {
  position: relative;
  top: 2px;
  width: auto;
  height: auto;
  margin-right: 5px;
  font-size: 0.9rem;
}



.warning-container {
  padding: 5px 8px !important;
  border: 1px solid #db641a;
  border-radius: 3px;
  margin: 0 0 15px;
  background: #fffdfc;
  clear: both;
  font-weight: 400 !important;
  color: rgb(237, 91, 0);
}


.warning-container a {
  color: rgb(237, 91, 0);
}


.warning-container mat-icon {
  position: relative;
  top: 2px;
  width: auto;
  height: auto;
  margin-right: 5px;
  font-size: 0.9rem;
}


.positive {
  padding: 5px 8px;
  border: 1px solid #57af0c;
  border-radius: 3px;
  margin: 0 0 15px;
  background: #f4fff5;
  clear: both;
}

.positive strong {
  color: #57af0c !important;
}

.positive p {
  padding: 0;
  margin: 0;
  color: #57af0c;
  font-size: 0.85rem;
}

/* Special Typography */

.well h2.sr-title {
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #e9e9e9;
  margin-top: 0;
}

h4.title {
  padding-bottom: 5px;
  border-bottom: 1px solid #e9e9e9;
  margin-top: 20px;
  font-weight: 600;
}

.welcome-block h1,
.welcome-block h1 span,
h1.welcome {
  color: #fff !important;
  font-size: 2.6rem;
  font-weight: 100 !important;
  font-weight: normal;
  letter-spacing: -1px;
  text-shadow: 0 2px 8px rgb(0 0 0 / 40%);
}

.welcome-block h1 span {
  font-weight: 400 !important;
}

.well.reducedPadding {
  padding: 0 10px;
}

app-merchant-welcome-card app-merchant-view-picture {
  margin-right: 15px;
}

app-merchant-welcome-card app-merchant-view-picture app-ep-document-view-image {
  display: flex !important;
  width: 60px !important;
  min-width: 60px;
  height: 60px !important;
  min-height: 60px;
}

@media (max-width: 768px) {
  .welcome-block {
    width: auto !important;
    min-height: 220px !important;
    padding: 10px !important;
  }

  .welcome-block h1,
  .welcome-block h1 span,
  h1.welcome {
    font-size: 1.6rem !important;
  }

  .welcome-block .versionBlock {
    font-size: 75%;
  }
}

@media (max-width: 1570px) {

  .welcome-block h1,
  .welcome-block h1 span,
  h1.welcome {
    font-size: 2.6rem;
  }
}

@media (max-width: 1400px) {
  .number-counter span {
    font-size: 4rem !important;
  }

  .sm-h3 {
    padding: 0.5rem 0;
    font-size: 1.05rem;
    font-weight: 200;
    line-height: 1.95rem;
  }
}

@media (max-width: 768px) {
  .number-counter span {
    font-size: 3rem !important;
  }
}

.lastVisited {
  padding: 10px;
  border-radius: 3px;
  border-top: 1px solid rgb(255 255 255 / 45%);
  border-bottom: 1px solid rgb(0 0 0 / 41%);
  background: linear-gradient(to bottom, rgb(206 220 231 / 18%) 0, rgb(89 106 114 / 24%) 100%);
  box-shadow: 0 2px 7px 0 rgb(0 0 0 / 20%);
  color: #fff;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#61cedce7', endColorstr='#78596a72', GradientType=0);
  text-shadow: 0 1px 3px rgb(0 0 0 / 40%);
}

.lastVisited p {
  margin: 2px 10px;
  font-size: 0.85rem;
}

.welcome-block .versionBlock {
  margin: 15px 0;
  color: #fff;
  text-shadow: 0 1px 3px rgb(0 0 0 / 40%);
}

.radio-label {
  margin-bottom: 10px;
}

mat-icon.large-modal-icon {
  position: absolute;
  z-index: 2;
  top: -70px;
  right: 0;
  left: 0;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0 auto;
  font-size: 60px;
}

mat-icon.large-modal-icon::after {
  position: absolute;
  z-index: -1;
  top: -3px;
  left: -3px;
  display: block;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: white;
  content: '';
}

mat-icon.xlarge-modal-icon {
  position: absolute;
  z-index: 2;
  top: -70px;
  right: 0;
  left: 0;
  width: 60px;
  height: 60px;
  padding: 5px;
  margin: 0 auto;
  font-size: 60px;
}

mat-icon.xlarge-modal-icon::after {
  position: absolute;
  z-index: -1;
  top: -3px;
  left: -3px;
  display: block;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background: white;
  content: '';
}

.stats p.small {
  color: #a5a5a5;
}

.stats p mat-icon {
  position: relative;
  top: 4px;
  color: #c7c7c7;
  font-size: 18px;
}

.wrapped-input .mat-radio-label {
  color: #3c3c3c;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  white-space: normal;
}

.wrapped-input .mat-radio-label .input-text {
  display: block;
  margin-left: 15px;
}

.border-bottom {
  border-bottom: 1px solid #e9e9e9;
}

.sign-text p {
  position: absolute;
  right: 19px;
  bottom: 0;
  bottom: -4px;
  color: #e0e0e0;
  font-weight: normal;
}

p.hint {
  display: inline-block;
  width: auto;
  padding: 6px 16px 6px 6px;
  border-radius: 20px;
  margin: 0 auto 20px;
  background: rgb(0 0 0 / 10%);
  box-shadow: inset 0 2px 5px rgb(0 0 0 / 5%);
  color: #fff;
}

p.hint label {
  display: inline-block;
  padding: 0.35em 0.6em;
  border-radius: 1.25rem;
  margin-right: 6px;
  background-color: #95dc7a;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
  color: #fff;
  font-size: 85%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  vertical-align: baseline;
  white-space: nowrap;
}

.sm-h2.white {
  color: #fff;
  line-height: 1.95rem;
}

.well.selectMerchant {
  min-height: 20px;
  padding: 10px 15px;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin: 15px 0;
  margin-top: 15px;
  background: rgb(253 253 253 / 70%);
  box-shadow: inset 0 1px 6px rgb(0 0 0 / 2%);
}

.invButtonRow .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 38px;
}

/* Large Slide Toggle */

.mat-slide-toggle {
  position: relative;
  display: flex !important;
  height: auto !important;
}

.mat-checkbox {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (min-width: 768px) {
  .slide-toggle-large {
    display: inline-block !important;
    padding: 0 !important;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .slide-toggle-large span.mat-slide-toggle-content {
    color: #656565;
    font-size: 1.1rem;
    font-weight: 300;
  }

  .slide-toggle-large .mat-slide-toggle-bar {
    width: 80px;
    height: 18px;
    margin-right: 20px;
    box-shadow: inset 0 2px 5px 0 rgb(0 0 0 / 15%);
  }

  .slide-toggle-large .mat-slide-toggle-thumb-container {
    top: -13px;
    width: 40px;
    height: 40px;
  }

  .slide-toggle-large .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 2px 6px 0 rgb(0 0 0 / 14%), 0 3px 5px 0 rgb(0 0 0 / 12%);
  }

  .slide-toggle-large .mat-slide-toggle-thumb-container .mat-slide-toggle-ripple {
    position: absolute;
    z-index: 1;
    top: -13px;
    left: -13px;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    pointer-events: none;
  }

  .slide-toggle-large.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(46px, 0, 0);
  }

  .slide-toggle-large.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    border-bottom: 1px solid #76a265;
    background-color: #95da7b !important;
  }

  .slide-toggle-large.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb::after {
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    content: '\e5ca';
    font-family: 'Material Icons';
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    line-height: 42px;
    text-align: center;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  }

  .slide-toggle-large.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: rgb(149 217 123 / 46%);
  }
}

@media screen and (max-width: 991px) {
  .slide-toggle-large span.mat-slide-toggle-content {
    white-space: normal;
  }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 38px !important;
}

.promoter-switch .mat-button-toggle-label-content {
  color: #fff;
  font-size: 0.9rem !important;
  font-weight: 400;
  line-height: 28px !important;
}

.promoter-switch .mat-button-toggle,
mat-button-toggle-group.promoter-switch {
  border-color: #fff;
  background: transparent;
}

.confirm-slide span.content-label {
  display: block;
  max-width: 700px;
  margin-left: 50px;
  white-space: normal;
}

.confirm-slide.mat-checkbox span.content-label {
  min-height: 45px;
  margin-left: 15px;
}

/* Disabled Typography */

.disabled a,
.disabled button {
  opacity: 0.65;
  pointer-events: none;
}

.block-disabled {
  position: relative;
  border-radius: 5px;
  background: #fbfbfb;
}

.block-disabled .padded {
  opacity: 0.35;
}

.block-disabled button {
  pointer-events: none;
}

.block-disabled:hover::after {
  opacity: 1;
}

/*  radial gradients */

radialGradient {
  cy: 50% !important;
}

/* Buttons */

button {
  border-radius: 4px !important;
  text-transform: none;
}

button.mat-fab,
button.mat-mini-fab {
  border-radius: 50% !important;
}

.btn-clear {
  font-weight: 400;
}

button.gradient {
  color: #fff !important;
}

button.btn-small,
a.btn-small {
  font-size: 0.7rem;
  text-transform: uppercase;
}

mat-button-toggle.btn-large,
button.btn-large,
a.btn-large {
  padding: 8px 20px;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

button.white.mat-stroked-button {
  border-color: #fff;
}

button.white {
  color: #fff;
}

button.btn-xlarge,
a.btn-xlarge {
  padding: 10px 30px;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.btn-outline {
  border: 2px solid #fff !important;
  background: none !important;
  text-transform: uppercase;
}

.step-button-row {
  position: relative;
  margin-top: 40px;
}

mat-card .step-button-row {
  position: relative;
  margin-top: 20px;
}

mat-card.card.full-width {
  box-sizing: border-box;
}

.step-button-row .step-back {
  position: absolute;
  top: 8px;
  left: 0;
}

.btn-clear.btn-view {
  width: auto;
  min-width: 25px;
  color: #777;
  float: right;
  font-size: 1.95rem;
}

.menu-trigger {
  top: 5px !important;
  left: 20px;
  width: 50px !important;
  min-width: 40px !important;
  height: 50px !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #777 !important;
}

.suppBtn mat-icon {
  width: auto;
  height: auto;
  margin-right: 5px;
  font-size: 1.05rem;
  opacity: 0.8;
}

.mat-dialog-container button.abs-right.mat-mini-fab.mat-accent {
  right: -44px;
}

button.small.mat-mini-fab {
  width: 30px;
  height: 30px;
  margin: 0;
  line-height: 30px;
}

button.small.mat-mini-fab mat-icon {
  font-size: 1.2rem;
  line-height: 1rem;
}

.clicktext {
  cursor: pointer;
}

.helptext {
  cursor: help;
}

.hidden-information {
  display: none;
}

button.helptext.position-abs {
  position: absolute;
  top: 6px;
  right: 6px;
}

.clicktext:hover h4,
.clicktext:hover h5,
.clicktext:hover h6 {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .step-button-row {
    margin-top: 20px;
  }

  button.btn-large,
  a.btn-large {
    padding: 5px 15px;
    font-size: 0.9rem;
  }

  button.btn-xlarge.payProceed {
    padding: 8px 10px !important;
    letter-spacing: 0.7px !important;
    white-space: normal;
  }
}

@media (max-width: 420px) {
  .mat-dialog-content {
    max-height: 40vh !important;
  }

  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

@media (max-width: 321px) {
  .infConsent mat-dialog-container.mat-dialog-container {
    padding: 12px;
  }

  .infConsent h3.sm-h3.sr-title.rm-mt.rm-mb {
    font-size: 0.95rem;
  }

  .infConsent button.proceed {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  button.btn-xlarge.payProceed {
    padding: 8px 10px !important;
    font-size: 0.9rem !important;
    letter-spacing: 0.5px !important;
    white-space: normal;
  }
}

@media (min-width: 1200px) {
  .menu-trigger {
    display: none !important;
  }
}

.notification-menu.mat-menu-panel {
  max-width: 100%;
}

.notification-menu.mat-menu-panel p {
  padding: 0;
  margin: 0;
}

.drop-buttons {
  position: absolute;
  right: 0;
  bottom: -50px;
  left: 0;
  width: 100%;
  text-align: center;
}

button.btn-compact {
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  padding: 0 10px 4px !important;
  margin: 0 5px;
  margin-top: 0 !important;
  text-align: center;
}

button.sml-btn {
  width: auto;
  min-width: 16px;
  padding: 0 10px;
  margin: 0;
}

button.btn-close {
  z-index: 99999;
  width: auto !important;
  min-width: 16px !important;
  padding: 0;
  margin: 0;
  color: #999;
}

button.cool {
  border: 2px solid #1e88e5;
  color: #1e88e5;
}

button.warm {
  border: 2px solid #e88400;
  color: #e88400;
}

button.hot {
  border: 2px solid #f44336;
  color: #f44336;
}

button mat-icon {
  position: relative;
  top: -2px;
}

#header button mat-icon {
  top: 0;
}

button.mat-mini-fab mat-icon {
  position: relative;
  top: 0;
}

.list-control-button {
  margin-left: 8px !important;
}

/* Button Groups */

.btn-group {
  border-radius: 5px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background: #f1f1f1;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group>.mat-button-toggle {
  width: 100%;
}

.btn-group>.mat-button-toggle,
.btn-group {
  border-radius: 4px !important;
}

.btn-file-input.btn-inverse {
  border: none !important;
}

.btn-group>.mat-button-toggle:first-child:not(:last-child, .dropdown-toggle),
.btn-group>.custom-file-control:first-child:not(:last-child, .dropdown-toggle)::before {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.btn-group>.mat-button-toggle:last-child:not(:first-child, .dropdown-toggle) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.btn-group>.mat-button-toggle:first-child,
.btn-group>.custom-file-control:first-child::before {
  margin-left: 0;
}

.btn-group>.mat-button-toggle:not(:first-child, :last-child, .dropdown-toggle),
.btn-group>.custom-file-control:not(:first-child, :last-child, .dropdown-toggle)::before {
  border-radius: 0 !important;
}

.btn-group-vertical>button,
.btn-group-vertical>.custom-file-control::before,
.btn-group>button,
.btn-group>.custom-file-control::before {
  position: relative;
  float: left;
}

.btn-group.full-width {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.btn-group.full-width button.mat-button {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-align: center;
}

.btn-group.full-width span.mat-button-wrapper {
  width: 100%;
  text-align: center;
}

.btn-group button.active,
.btn-group .mat-button-toggle-checked {
  color: #fff !important;
}

.btn-group .mat-button-toggle label {
  position: relative;
  top: 1px;
}

.btn-group .mat-button-toggle-checked label {
  top: -1px;
}

.btn-group .mat-button-toggle-checked label {
  color: #fff !important;
}

.btn-group button.active .mat-button-focus-overlay {
  background-color: rgb(255 255 255 / 12%);
}

.btn-group button.active .mat-ripple-element {
  background-color: rgb(255 255 255 / 10%);
}

.btn-group.sml-group * {
  font-size: 13px;
}

.btn-group.sml-group .mat-button-toggle label {
  padding: 0 5px;
}

.btn-group.sml-group .mat-button-toggle label {
  position: relative;
  top: 2px !important;
}

.btn-group.sml-group .mat-button-toggle-checked label {
  top: -1px !important;
}

button.gradient.red {
  background: linear-gradient(-45deg, rgb(208 31 118 / 100%) 0%, rgb(210 0 0 / 100%) 100%);
  background: linear-gradient(135deg, rgb(208 31 118 / 100%) 0%, rgb(210 0 0 / 100%) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d01f76e6', endColorstr='#d05318e6', GradientType=1);
}

button.gradient.gray {
  background: linear-gradient(-45deg, rgb(130, 130, 130) 0%, rgb(190, 190, 190) 100%);
  background: linear-gradient(135deg, rgb(130, 130, 130) 0%, rgb(190, 190, 190) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#828282e6', endColorstr='#bebebee6', GradientType=1);
}

button.gradient.purpred {
  background: linear-gradient(-45deg, rgb(214 26 31) 0%, rgb(151 62 212) 100%);
  background: linear-gradient(135deg, rgb(214 26 31) 0%, rgb(151 62 212) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#971fd0e6', endColorstr='#189dd0e6', GradientType=1);
}

button.gradient.purple {
  background: linear-gradient(-45deg, rgb(151 31 208 / 100%) 0%, rgb(24 157 208 / 100%) 100%);
  background: linear-gradient(135deg, rgb(151 31 208 / 100%) 0%, rgb(24 157 208 / 100%) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#971fd0e6', endColorstr='#189dd0e6', GradientType=1);
}

button.gradient.blue {
  background: linear-gradient(-45deg, rgb(38 171 226 / 100%) 0%, rgb(24 191 208 / 100%) 100%);
  background: linear-gradient(135deg, rgb(38 171 226 / 100%) 0%, rgb(24 191 208 / 100%) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#18bfd0e6', endColorstr='#18bfd0e6', GradientType=1);
}

button.gradient.green {
  background: linear-gradient(-45deg, rgb(31 208 129 / 100%) 0%, rgb(7 159 167 / 100%) 100%);
  background: linear-gradient(135deg, rgb(31 208 129 / 100%) 0%, rgb(7 159 167 / 100%) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#1fd081e6', endColorstr='#079fa7e6', GradientType=1);
}

button.gradient.yellow {
  background: linear-gradient(-45deg, rgb(230 201 34 / 100%) 0%, rgb(208 66 24 / 100%) 100%);
  background: linear-gradient(135deg, rgb(230 201 34 / 100%) 0%, rgb(208 66 24 / 100%) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#e6c922e6', endColorstr='#d04218e6', GradientType=1);
}

button.gradient.green.solid {
  background: linear-gradient(-45deg, rgb(31 208 129 / 100%) 0%, rgb(7 159 167 / 100%) 100%);
  background: linear-gradient(135deg, rgb(31 208 129 / 100%) 0%, rgb(7 159 167 / 100%) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#1fd081e6', endColorstr='#079fa7e6', GradientType=1);
}

.toggles .pos:hover {
  background: linear-gradient(135deg, rgb(31 208 129 / 90%) 0%, rgb(7 159 167 / 90%) 100%);
  color: #fdfdfd;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#1fd081e6', endColorstr='#079fa7e6', GradientType=1);
}

.toggles .neg:hover {
  background: linear-gradient(135deg, rgb(208 31 118 / 90%) 0%, rgb(210 0 0 / 90%) 100%);
  color: #fdfdfd;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d01f76e6', endColorstr='#d05318e6', GradientType=1);
}

.toggles .maybe:hover {
  background: linear-gradient(135deg, rgb(255 174 0 / 90%) 0%, rgb(255 166 0 / 97.2%) 100%);
  color: #fdfdfd;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d01f76e6', endColorstr='#d05318e6', GradientType=1);
}

.toggles .pos:hover mat-icon {
  box-shadow: none !important;
  color: #fdfdfd !important;
}

.toggles .maybe:hover mat-icon {
  box-shadow: none !important;
  color: #fdfdfd !important;
}

.toggles .neg:hover mat-icon {
  box-shadow: none !important;
  color: #fdfdfd !important;
}

.toggles.widen mat-button-toggle {
  padding: 0 6px;
}

.with-icons .mat-button-toggle mat-icon {
  width: auto;
  height: auto;
  margin-right: 8px;
  font-size: 1rem;
}

.toggle-card {
  padding: 0;
  border-top: solid 1px rgb(70 70 70 / 10%) !important;
  border-bottom: solid 1px rgb(70 70 70 / 10%) !important;
}

.toggle-card .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle,
.mat-button-toggle.mat-button-toggle-appearance-standard {
  border: 0 !important;
}

.mat-button-toggle.mat-button-toggle-appearance-standard,
.toggle-card .mat-button-toggle-group-appearance-standard,
.toggle-card .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  border: 0 !important;
}

.toggle-card mat-button-toggle-group {
  border: none !important;
  border-radius: 0 !important;
  margin: 0;
  background: transparent;
}

.toggle-card mat-button-toggle {
  position: relative;
  border-radius: 0 !important;
  line-height: 40px;

  /* border-bottom: 3px solid #fff; */
  transition: all 0.2s ease-in-out;
}

.toggle-card .btn-group .mat-button-toggle-checked {
  /* border-bottom: 3px solid #1b8bdd !important; */

  /* background: #e9e9e9 !important; */

  /* color: #656565 !important; */
  min-height: 40px;
  border-radius: 0 !important;
  border-top: none;
  border-right: none;
  border-left: none;
}

.toggle-card .btn-group .mat-button-toggle-checked label {
  color: #656565 !important;
}

/* .toggle-card .btn-group .mat-button-toggle-checked .mat-button-toggle-label-content {
  top: 2px;
  position: relative;
} */

.toggle-card mat-button-toggle:hover {
  /* border-bottom: 3px solid rgba(27, 139, 221, 0.1) !important; */

  /* background: rgba(27, 139, 221, 0.1) !important; */
}

.toggle-card .btn-group mat-button-toggle.mat-button-toggle-checked:hover {
  /* border-bottom: 3px solid #1b8bdd !important; */
}

.mat-button-toggle.mat-button-toggle-appearance-standard mat-icon,
.toggle-card .mat-button-toggle-group-appearance-standard mat-icon,
.toggle-card .mat-button-toggle-standalone.mat-button-toggle-appearance-standard mat-icon {
  margin-right: 6px !important;
  margin-left: 6px !important;
  color: #818181 !important;
  font-size: 1.3rem !important;
  line-height: 27px !important;
}

.mat-button-toggle.mat-button-toggle-appearance-standard:hover,
.toggle-card .mat-button-toggle-group-appearance-standard:hover,
.toggle-card .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:hover {
  background-color: rgb(245 245 245) !important;
}

.toggle-card .btn-group .mat-button-toggle-checked mat-icon,
.mat-button-toggle-appearance-standard.mat-button-toggle-checked mat-icon,
.mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked mat-icon,
.toggle-card .mat-button-toggle-group-appearance-standard.mat-button-toggle-checked mat-icon,
.toggle-card .mat-button-toggle-standalone.mat-button-toggle-appearance-standard.mat-button-toggle-checked mat-icon {
  margin-right: 6px !important;
  margin-left: 6px !important;
  color: #fff !important;
  font-size: 1.3rem !important;
  line-height: 27px !important;
  text-shadow: 0 1px 4px rgb(0 0 0 / 50%) !important;
}

.toggle-card .btn-group .mat-button-toggle-checked,
.mat-button-toggle-appearance-standard.mat-button-toggle-checked,
.mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked,
.toggle-card .mat-button-toggle-group-appearance-standard.mat-button-toggle-checked,
.toggle-card .mat-button-toggle-standalone.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  font-weight: bold !important;
}

.toggle-card mat-icon {
  width: auto;
  height: auto;
  margin-right: 5px;
  font-size: 0.9rem;
}

app-invitation-settings .row.toggle-card {
  border-bottom: 1px solid #e3e3e3;
  margin: -16px -16px 20px;
}

/* Inputs */

/*  give inputs a very faint colour */
.mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 4px;
  background: rgb(165 222 255 / 4%);
}

mat-select.qtr-width.align {
  margin-right: 10px;
  margin-bottom: 1rem;
}

.align-chk {
  margin-top: 15px;
}

.mat-form-field-prefix.mat-form-field-prefix {
  max-width: 100%;
}

.completer-row {
  max-width: 98% !important;
}

date-picker {
  display: block;
  margin-top: 10px;
}

ngx-daterangepicker-material {
  position: relative;
  z-index: 1000000;
  display: block;
}

.md-drppicker.show-ranges.double {
  left: 0 !important;
}

.md-drppicker .btn.btn-default {
  margin: 0 4px;
  background-color: #fff !important;
  color: #000;
}

.md-drppicker .clear svg {
  top: 5px;
  transform: scale(0.8);
}

.row.clearfix.flex mat-form-field,
.multirow.flex mat-form-field,
.row.clearfix.flex .clearfix.input-cont-height,
.row.clearfix.flex app-phone-input,
.row.clearfix.flex mat-select,
.row.clearfix.flex app-occupation-input,
.row.clearfix.flex app-email-input {
  margin: 0 5px;
}

.row.clearfix.flex .clearfix.input-cont-height.full-width {
  min-width: 0;
  margin-right: 32px;
}

/* .row.clearfix.flex mat-form-field:last-child,
.multirow.flex mat-form-field:last-child,
.row.clearfix.flex app-phone-input:last-child,
.row.clearfix.flex mat-select:last-child,
.row.clearfix.flex app-occupation-input:last-child,
.row.clearfix.flex app-email-input:last-child {
  margin-right: 0;
} */

app-email-input mat-form-field.full-width {
  margin: 0 !important;
}

.searchArea .row.clearfix.flex mat-form-field {
  margin-right: 5px;
}

.searchArea .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgb(255 255 255 / 62%);
}

.searchArea label.mat-slide-toggle-label {
  color: #fff;
}

.confirm-slide .mat-slide-toggle-label-before .mat-slide-toggle-bar,
.confirm-slide .mat-slide-toggle-bar {
  position: absolute;
  top: 3px;
  left: 0;
}

.mat-radio-button {
  margin-right: 10px;
}

/* .row.flex .mat-select-trigger {
  height: 32px;
  padding-bottom: 2px;
} */

.row.flex .full-width:not(last-child) mat-select {
  margin-right: 30px;
}

.filterCardless {
  box-shadow: none !important;
}

.filterCardless .mat-expansion-panel-body {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 0;
  border: 1px solid #f5f5f5;
  border-radius: 0 0 3px 3px;
  margin-bottom: 6px;
  background: #fdfdfd;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterCardless .mat-select-trigger {
  height: 25px;
}

.filterCardless *,
.filterCardless .ngx-datepicker-container .ngx-datepicker-input {
  font-size: 12px !important;
}

.filterCardless mat-expansion-panel-header.mat-expansion-panel-header {
  width: 100px;
}

.filterCardless .datepicker-container {
  min-width: 80px;
  max-width: 150px;
  float: left;
}

.filterCardless .mat-select {
  position: relative;
  top: 1px;
  min-width: 120px;
  padding-top: 0;
  margin-left: 3px !important;
}

.filterCardless .mat-form-field {
  width: 100%;
  max-width: 150px;
  margin-left: 3px !important;
}

.filterCardless .datepicker-container {
  padding-bottom: 12px !important;
  margin-bottom: 0 !important;
}

.filterCardless .clearButtonWidth {
  position: relative;
  top: 20px;
  right: 40px;
  width: 5%;
  float: left;
}

.filterCardless .clearButtonWidth mat-icon {
  font-size: 24px !important;
}

.filterCardless mat-expansion-panel-header.mat-expansion-panel-header {
  border-bottom: 2px solid #f1f1f1;
}

.filterCardless mat-expansion-panel-header.mat-expansion-panel-header mat-icon {
  position: relative;
  top: 7px;
  left: 5px;
  font-size: 20px !important;
}

.filterCardless.mat-expanded .mat-expansion-panel-content {
  overflow: visible;
}

.mat-expansion-panel-content {
  font: 400 1rem Nunito, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.primary-gradient-img mat-datepicker-toggle button {
  color: #fff;
}

.primary-gradient-img hr {
  background: rgb(255 255 255 / 70%);
}

/* Tutorials */
.no-bullets {
  list-style: none;
}

/*  File List */

app-file-list.compact .file-container p.small {
  margin: 2px 0;
}

app-file-list.compact .buttons {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

app-file-list.compact .buttons button {
  width: 100%;
  min-width: 0;
}

app-file-list.compact .file-container .img {
  max-height: 80px !important;
  margin-bottom: 4px !important;
}

app-file-list.compact .file-container .img::before {
  height: 80px !important;
  font-size: 40px !important;
  line-height: 80px !important;
}

app-file-list.compact .file-container {
  max-width: 25% !important;
  flex-basis: 25% !important;
}

app-file-list.compact .flex.uploadedContainer {
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  padding: 6px;
  border-radius: 4px;
  margin: 0;
  background: #f3f3f3;
  box-shadow: inset 0 1px 6px rgb(0 0 0 / 5%);
}

/* Cards */

.mat-card-remove-padding {
  padding: 0 !important;
}

.card {
  margin: 20px auto;
}

app-contracts-view .contractViewMain.mat-card {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none;
}

.result-card {
  max-width: 600px !important;
}

.result-card .button-row {
  position: absolute;
  width: 100%;
  margin: 15px -24px;
  text-align: center;
}

.result-card .icons-col mat-icon.green-text.mat-icon.material-icons {
  top: 14px;
  color: #57af0c;
}

.green-text {
  color: #5aba47;
}

.colour-stripe {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 2px 2px 0 0;
  animation: none !important;
  background-size: 200% 200%;
}

img.feature-img {
  position: relative;
  z-index: 10;
  max-width: 150px;
  margin: -90px auto -50px;
}

mat-list.mat-card-remove-padding .sticky {
  border-radius: 4px 4px 0 0;
}

mat-list.mat-card-remove-padding p {
  padding: 0 10px 0 0;
  font-weight: 400;
}

@media (max-width: 768px) {
  img.feature-img {
    max-width: 210px;
    margin: -180px auto 10px;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.docuSigning .mat-tab-body-content {
  padding: 0 5px 10px !important;
}

.docuSigning .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.docuSigning .mat-tab-label {
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.9;
}

.docuSigning.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: rgb(255 255 255 / 60%);
}

@media (max-width: 768px) {
  .card {
    margin: 10px auto;
  }

  mat-card.call-to-action,
  .half-width.welcome-block {
    width: auto;
  }

  mat-card.call-to-action {
    margin-right: 0 !important;
  }
}

.finance-header {
  flex-direction: column;
  padding: 20px 20px 80px;
  margin: -24px -24px -90px;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.pc-main mat-card-header.main-patient-card {
  flex-direction: column;
  padding: 20px 20px 120px;
  margin: -24px -24px -40px;
  background-size: cover;
  color: #fff;
  text-align: center;
}

@media (min-width: 768px) {
  .pc-main mat-card-header.main-patient-card {
    padding: 40px 40px 60px;
    margin: -24px -24px -40px;
  }

  .finance-header {
    padding: 40px 40px 100px;
    margin: -24px -24px -95px;
  }
}

/* Dashboard modals */

.status-list .mat-list-item-content {
  padding: 0 !important;
}

#blueBg {
  transition: all 1.5s ease-out;
}

mat-card.patientFilter.mat-card {
  display: inline-block;
  width: calc(100% - 150px);
  padding: 5px 0 10px;
  margin-top: -15px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
  float: right;
}

@media (max-width: 768px) {
  mat-card.patientFilter.mat-card {
    margin-top: 10px;
  }
}

/*  patient list  */
app-existing-patient-list-global {
  display: block;
  padding: 5px;
}

.patientFilter .mat-form-field,
.patientFilter .typeSel mat-select {
  margin: 10px 0 5px !important;
}

.patientFilter .head {
  padding: 5px 20px 0;
}

.patientFilter .grey {
  padding: 5px 20px 0;
  margin-top: 5px;
  margin-bottom: -10px !important;
  background: #f7f7f7;
}

.patientFilter .row.head mat-icon {
  margin: 15px 10px 0 -5px;
  color: #656565;
  float: left;
}

.patientFilter .row.head mat-select.full-width {
  width: calc(100% - 30px) !important;
  float: right;
}

.searchArea .flexwrap mat-form-field,
.searchArea .flexwrap app-date-input {
  flex-basis: 14%;
  flex-grow: 1;
}

.mat-cell i.fas.fa-mobile-alt,
.mat-cell i.fas.fa-envelope,
.mat-cell i.fa-globe {
  margin: 0 10px;
  color: #818181;
  font-size: 1.2rem;
}

/* Lists */

mat-card.list-card.mat-card {
  padding-top: 15px;
  margin-top: 20px;
}

mat-card.list-card mat-header-row.mat-header-row {
  padding: 0 4px !important;
}

mat-list.status-list {
  border-top: 1px solid #e9e9e9;
}

mat-list.status-list mat-list-item {
  height: 30px;
}

mat-list.status-list mat-list-item p {
  padding: 0;
  margin: 0;
}

.mat-column-Actions button.mat-icon-button {
  height: 35px;
  font-size: 0 !important;
}

.mat-icon-button .mat-icon-no-color {
  color: #818181;
}

.white mat-icon.mat-icon-no-color {
  color: #fff;
}

.list-card mat-cell.mat-cell {
  min-height: 40px;
  padding: 3px !important;
  cursor: pointer;
  font-size: 0.8rem;
}

mat-row mat-cell.mat-cell,
.pointer {
  cursor: pointer;
}

.no-quick-click mat-row mat-cell.mat-cell {
  cursor: default !important;
}

.list-card mat-row.mat-row {
  min-height: 30px;
  padding: 2px !important;
}

ul.check {
  padding-left: 0;
  list-style-type: none;
}

ul.check li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  line-height: 1.6rem;
}

ul.check li::before {
  position: absolute;
  left: 0;
  display: block;
  color: #059dbf;
  content: '\2713';
  font-weight: bold;
  text-shadow: 0 1px 4px rgb(0 0 0 / 25%);
}

ul.exclaim {
  padding-left: 0;
  list-style-type: none;
}

ul.exclaim li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  line-height: 1.6rem;
}

ul.exclaim li::before {
  position: absolute;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin: 2px 5px 0 0;
  background: #d46051;
  color: #fff;
  content: '\0021';
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 4px rgb(0 0 0 / 25%);
}

span.badge {
  position: relative;
  top: -2px;
  display: inline-block;
  padding: 5px 8px 4px;
  border-radius: 0.25em;
  margin: 0 8px 0 5px;
  background-color: #5bc0de;
  box-shadow: 0 1px 0 rgb(82 174 202), 0 1px 2px rgb(0 0 0 / 20%), 0 2px 5px rgb(0 0 0 / 20%);
  color: #fff;
  font-size: 65%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

span.dollarValue {
  position: relative;
  top: -2px;
  display: inline-block;
  padding: 5px 8px 4px;
  border-radius: 0.25em;
  margin: 0 8px 0 0;
  background-color: #06c18d;
  box-shadow: 0 1px 0 rgb(7 165 121), 0 1px 2px rgb(0 0 0 / 20%), 0 2px 5px rgb(0 0 0 / 20%);
  color: #fff;
  font-size: 65%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

span.dollarValue.warn,
span.badge.warn {
  background-color: #f14d30;
  box-shadow: 0 1px 0 rgb(228 44 44), 0 1px 2px rgb(0 0 0 / 20%), 0 2px 5px rgb(0 0 0 / 20%);
}

span.badge mat-icon,
span.dollarValue mat-icon {
  width: auto;
  height: auto;
  margin-right: 2px;
  color: rgb(255 255 255 / 75%);
  font-size: 0.9rem;
  line-height: 0;
}

.searchable-list {
  margin-top: 1.5rem !important;
  margin-right: 30px;
}

.mat-paginator {
  border-top: 1px solid #e9e9e9;
}

.mat-paginator-page-size-select .mat-form-field-infix {
  min-width: unset !important;
}

.features ul {
  font-size: 0.9rem;
}

.features ul li {
  margin-bottom: 10px;
  opacity: 0.9;
}

.flexList.busHours span.small {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.busHours .mat-list-item-content {
  padding: 0 !important;
  border-top: 1px solid #eee;
}

.busHours .tradingHour {
  font-weight: bold;
}

.busHrsBlock {
  margin: 20px 10px 0;
}

mat-cell app-ep-document-view-image {
  display: flex;
  width: 45px;
  height: 45px;
}

app-ep-document-view-image {
  position: relative;
}

app-ep-document-view {
  max-width: 100vw;
}

mat-dialog-container app-ep-document-view {
  display: block;
  overflow: hidden;
  max-width: 100vw;
  background: #fff;
}

app-ep-document-view .row.clearfix.white-background {
  max-width: 100vw;
}

.uploadBlock app-ep-document-view-image .container {
  padding-bottom: 0 !important;
}

app-ep-document-view-image.notYetUploaded {
  display: flex;
  width: auto;
  min-height: 100px;
  border: 5px dashed #e3e3e3;
  border-radius: 5px;
  background: #f9f9f9;
}

app-ep-document-view-image.notYetUploaded .ep-view-img {
  right: auto;
  left: auto;
  display: block !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 0;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.displayUploadImg {
  display: block;
  text-align: center;
}

.displayUploadImg .display-image-uploaded {
  max-width: 100%;
}

.noLowerPad .container {
  padding-bottom: 0 !important;
}

.searchArea {
  padding: 16px;
  margin: -24px -16px 20px !important;
}

.searchArea-wht {
  padding: 16px;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  margin: -3px -16px 0 !important;
  background: #f6f6f6;
}

.searchArea-wht h3.dataHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchArea .justify {
  justify-content: space-between;
}

.searchArea p,
.searchArea mat-icon,
.searchArea input,
.searchArea mat-form-field,
.searchArea mat-select *,
.searchArea .mat-form-field-label {
  color: #fff;
}

.searchArea mat-select,
.searchArea mat-input {
  margin-right: 20px;
}

mat-form-field.smaller-input.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix {
  padding: 0.4em 0 !important;
}

mat-form-field.smaller-input.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix>span.mat-form-field-label-wrapper {
  top: -1.5em !important;
}

.mat-form-field-appearance-outline.smaller-input.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  width: 133.33333% !important;
  transform: translateY(-1.1em) scale(0.75) !important;
}

.searchArea .mat-select-underline,
.searchArea .mat-form-field-underline {
  background-color: rgb(255 255 255 / 42%);
}

.searchArea .mat-select-placeholder {
  width: auto !important;
}

.searchArea .mat-focused .mat-form-field-label {
  color: #fff;
}

/*
.mat-table.isStretched {
  overflow-y: scroll;
} */

/* Labels */

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  border-radius: 0.25em;
  color: #fff;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.label,
.label.label-default {
  background-color: #9e9e9e;
}

.label.label-primary {
  background-color: #009688;
}

.label.label-success {
  background-color: #4caf50;
}

.label.label-warning {
  background-color: #ff5722;
}

.label.label-danger {
  background-color: #f44336;
}

.label.label-info {
  background-color: #03a9f4;
}

.mat-simple-snackbar {
  justify-content: center !important;
}

@media (max-width: 768px) {

  .mat-list .mat-list-item,
  .mat-nav-list .mat-list-item,
  .mat-selection-list .mat-list-item {
    font-size: 14px !important;
  }

  .searchArea mat-form-field.full-width,
  .searchArea .full-width {
    margin-left: 0 !important;
  }
}

/* Wells */

.well {
  min-height: 20px;
  padding: 19px;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin: 15px 0;
  margin-top: 15px;
  background: #fdfdfd;
  box-shadow: inset 0 1px 6px rgb(0 0 0 / 5%);
}

.well.w-sml {
  padding: 0 15px;
}

.well.summary-well .mat-list .mat-list-item-content {
  height: auto;
  padding-bottom: 8px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 8px;
  font-size: 0.9rem;
  font-weight: 300;
}

@media (min-width: 768px) {
  .well.summary-well .mat-list .mat-list-item-content {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}

/* Top Nav */

app-header#application-header {
  z-index: 10000;
}

.wideMenu.navHeadMenu button,
.wideMenu.dashMenu button {
  padding-right: 50px;
  border-radius: 0 !important;
}

.wideMenu.navHeadMenu mat-icon,
.wideMenu.dashMenu mat-icon {
  position: absolute;
  top: 25%;
  right: 0;
  width: 1.4rem;
  height: auto;
  font-size: 1.4rem;
}

.wideMenu button:hover {
  background: linear-gradient(-45deg, rgb(31 208 129 / 90%) 0%, rgb(7 159 167 / 90%) 100%);
  background: linear-gradient(135deg, rgb(31 208 129 / 90%) 0%, rgb(7 159 167 / 90%) 100%);
  background-size: cover;
  color: #fff;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#1fd081e6', endColorstr='#079fa7e6', GradientType=1);
}

.wideMenu.navHeadMenu button:hover,
.wideMenu.dashMenu button:hover {
  background: linear-gradient(135deg, rgb(28 184 113 / 90%) 0%, rgb(6 129 136 / 90%) 100%);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#1fd081e6', endColorstr='#079fa7e6', GradientType=1);
}

.wideMenu.navHeadMenu button:hover mat-icon,
.wideMenu.dashMenu button:hover mat-icon {
  color: #fff;
}

app-operator-filter.dash {
  display: block;
  margin: -40px -20px 0;
}

/* Side Nav Animation */

#application-side-menu::-webkit-scrollbar,
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;

  /* Safari and Chrome */
  width: 0;
  height: 0;
}

.mat-drawer-side {
  border-right: none !important;
}

mat-sidenav .mat-drawer-inner-container {
  /* box-sizing: content-box;
  padding-right: 16px !important;
  overflow-x: hidden; */

  /* max-width: 230px; */
}

@media (max-width: 768px) {
  #header .mat-h1 {
    width: auto;
    padding-right: 0;
    padding-left: 0;
    margin: 0 !important;
    font-size: 0.9rem !important;
    line-height: 48px;
    text-align: center;
  }

  nav#header {
    padding: 5px 15px 5px 55px !important;
  }

  nav#header.consumer {
    padding: 5px 0 !important;
  }

  .menu-trigger {
    top: 5px !important;
    left: 5px;
    width: 40px !important;
    height: 50px !important;
    margin: 0 !important;
  }

  #header .btn-new-invite {
    display: none;
  }

  nav#header.consumer .site_logo {
    width: 50px;
    height: 40px;
    margin-right: 10px;
  }
}

mat-list.sub-menu.mat-list {
  padding-top: 0;
  margin-bottom: 10px;
}

/* Sidebar Style */

app-main .side-menu-container,
app-consumer-main .side-menu-container {
  position: relative;
  overflow: hidden;
  max-width: 230px;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

.side-menu-container::after {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 150% 150%;
  content: '';
  opacity: 0.8;
}

.sidebar-gradient {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(22.5deg,
      rgb(67 67 67 / 2%) 0%,
      rgb(67 67 67 / 2%) 29%,
      rgb(47 47 47 / 2%) 29%,
      rgb(47 47 47 / 2%) 37%,
      rgb(23 23 23 / 2%) 37%,
      rgb(23 23 23 / 2%) 55%,
      rgb(182 182 182 / 2%) 55%,
      rgb(182 182 182 / 2%) 69%,
      rgb(27 27 27 / 2%) 69%,
      rgb(27 27 27 / 2%) 71%,
      rgb(250 250 250 / 2%) 71%,
      rgb(250 250 250 / 2%) 100%),
    linear-gradient(67.5deg,
      rgb(117 117 117 / 2%) 0%,
      rgb(117 117 117 / 2%) 14%,
      rgb(199 199 199 / 2%) 14%,
      rgb(199 199 199 / 2%) 40%,
      rgb(33 33 33 / 2%) 40%,
      rgb(33 33 33 / 2%) 48%,
      rgb(135 135 135 / 2%) 48%,
      rgb(135 135 135 / 2%) 60%,
      rgb(148 148 148 / 2%) 60%,
      rgb(148 148 148 / 2%) 95%,
      rgb(53 53 53 / 2%) 95%,
      rgb(53 53 53 / 2%) 100%),
    linear-gradient(135deg,
      rgb(190 190 190 / 2%) 0%,
      rgb(190 190 190 / 2%) 6%,
      rgb(251 251 251 / 2%) 6%,
      rgb(251 251 251 / 2%) 18%,
      rgb(2 2 2 / 2%) 18%,
      rgb(2 2 2 / 2%) 27%,
      rgb(253 253 253 / 2%) 27%,
      rgb(253 253 253 / 2%) 49%,
      rgb(128 128 128 / 2%) 49%,
      rgb(128 128 128 / 2%) 76%,
      rgb(150 150 150 / 2%) 76%,
      rgb(150 150 150 / 2%) 100%),
    linear-gradient(90deg, #fff, #fff);

  /* background-size: cover !important;
  background-position: center center;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); */
}

.side-menu-container::after {
  transition: all 0.15s ease-out;
}

.side-menu-container.promoter::after,
.promoter .welcome-block .primary-gradient-img {
  background: #242424;
  background: linear-gradient(-45deg, #242424 0%, #8b8b8b 100%);
  background: linear-gradient(-45deg, #242424 0%, #8b8b8b 100%);
  background: linear-gradient(-45deg, #242424 0%, #8b8b8b 100%);
  background-size: cover;
}

.side-menu-container.promoter .sidebar-background {
  filter: none;
}

.header-background {
  position: relative;
  z-index: 1;
  padding: 40px 25px 10px;
  margin-bottom: 40px;
  background-position: center center;
  background-size: cover;
  filter: blur(5px);
  text-align: center;
}

/* Sidebar Logo */

.logo-container {
  position: relative;

  /* width: 230px; */
  z-index: 4;
  height: 64px;
  box-sizing: border-box;
  padding: 10px 15px;
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.logo-container h1 {
  padding: 0;
  margin: 5px 0 0;
  color: #fff;
  font-weight: 100;
}

.logo-image {
  display: inline-block;
  width: 50px;
  height: 40px;
  margin-right: 10px;
  background-size: contain;
  filter: brightness(0) invert(1);
  float: left;
}

/* Finance calculator */

.mat-slider-thumb-label {
  width: 45px !important;
  height: 45px !important;
}

.mat-slider-horizontal .mat-slider-thumb-label {
  top: -54px !important;
  right: -20px !important;
}

.mat-slider-thumb {
  right: -20px !important;
  bottom: -20px !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 30%);
}

.mat-slider-thumb::after {
  position: absolute;
  top: 5px;
  left: 5px;
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgb(255 255 255 / 20%);
  content: '';
}

.consumer-slider .well.clearfix {
  border: none;
  background: #fff;
}

.mat-slider-thumb-label-text {
  font-size: 1rem !important;
}

/*  consumer slider */

.consumer-slider .mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}

.consumer-slider .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px;
}

.consumer-slider .mat-slider.mat-slider-horizontal .mat-slider-track-background,
.consumer-slider .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.consumer-slider .mat-accent .mat-slider-thumb {
  right: -20px;
  bottom: -25px !important;
  width: 30px;
  height: 30px;
  border: solid 4px #fff;
}

/*  consumer application */

.consumerApplication .mat-select-disabled .mat-select-value,
.consumerApplication .mat-select-arrow,
.consumerApplication .mat-select-trigger {
  color: rgb(255 255 255 / 90%);
}

.consumerApplication .mat-select-underline {
  height: 2px !important;
  background-color: rgb(255 255 255 / 42%);
}

.consumerApplication .mat-form-field-underline .mat-form-field-ripple {
  height: 2px !important;
}

.consumerApplication .mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-trigger,
.consumerApplication .mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-arrow {
  color: #fff;
}

.consumerApplication .container {
  height: 100%;
  padding-bottom: 20px;
}

.consumerApplication aw-wizard,
.consumerApplication aw-wizard-step,
.consumerApplication .wizard-steps.horizontal {
  flex: 1;
}

.consumerApplication .mat-expansion-panel {
  background: transparent;
  color: #fff;
}

.consumerApplication app-product-view-modal mat-expansion-panel .mat-expansion-panel-header {
  padding: 5px 16px 0;
  border-radius: 8px;
  border-top: none;
  margin-top: 5px;
  background: rgb(0 0 0 / 10%) !important;
}

.consumerApplication mat-panel-title.primary-color.mat-expansion-panel-header-title {
  color: #fff !important;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 5px rgb(0 0 0 / 20%);
}

.consumerApplication .mat-expansion-panel-header-description,
mat-sidenav .mat-expansion-indicator::after {
  color: rgb(255 255 255 / 70%) !important;
}

.consumerApplication app-product-view-modal mat-expansion-panel .row.clearfix.padded {
  padding: 15px;
}

.consumerApplication app-product-view-modal .row.full-width.primary-gradient-img.rel.clearfix {
  padding: 8px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 16px rgb(0 0 0 / 20%);
}

.consumerApplication app-product-view-modal {
  margin-top: 30px;
}

.consumerApplication app-product-view-modal h1.sm-h1.sr-title {
  padding: 0;
  margin: 0;
}

.consumerApplication app-product-view-modal label.header-title-product {
  display: none;
}

.consumerApplication app-product-calculator .calculator {
  border-radius: 10px;
  background: rgb(0 0 0 / 22%);
}

.consumerApplication app-product-calculator .mat-dialog-content {
  display: block;
  overflow: visible;
  max-height: 100%;
  padding: 12px;
  margin: 0;
}

.consumerApplication app-product-calculator.noHeading .row.clearfix.mt.mb.heading,
.consumerApplication app-product-calculator.noHeading .calcContainer h3.clearfix.primary-color.text-center {
  display: none;
}

.consumerApplication app-product-calculator.noHeading .selector-row.row.clearfix.mb {
  margin: 0 0 20px !important;
}

.consumerApplication app-product-calculator.noHeading mat-card.card.full-width.results {
  border-radius: 8px;
  margin-bottom: 10px !important;
}

.consumerApplication app-product-calculator.noHeading .btn-group {
  background: rgb(0 0 0 / 10%);
}

.consumerApplication .mat-select-value {
  color: #fff;
}

/* consuemr forms */
.consumerApplication .inlined-label {
  overflow: hidden;
  max-height: 50px;
}

.consumerApplication .inlined-label label {
  padding: 14px 2px 14px 12px;
  font-size: 1rem;
}

.consumerApplication .mat-form-field-underline {
  height: 2px !important;
  background-color: rgb(255 255 255 / 42%);
}

.consumerApplication app-main .mat-form-field-labelwrapper,
.consumerApplication app-consumer-main .mat-form-field-labelwrapper {
  font-size: 1.1rem !important;
}

.consumerApplication .input-container {
  padding-bottom: 1.25em;
}

.consumerApplication .input-container mat-select {
  padding-top: 0;
}

@media screen and (max-width: 768px) {
  .inlined-label label {
    padding: 10px 2px 10px 12px !important;
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 500px) {

  .consumerApplication .mat-select-placeholder,
  .consumerApplication .mat-form-field-labeler {
    font-size: 1.1rem;
  }

  .consumerApplication .mat-select-value {
    color: rgb(255 255 255);
    font-size: 1.1rem;
  }

  .consumerApplication .mat-select-trigger {
    height: 50px;
  }
}

@media screen and (max-width: 360px) {
  .consumerApplication p.lead {
    font-size: 1.05rem;
    line-height: 1.3rem;
  }

  .consumerApplication h1.text-center {
    margin: 5px 0 10px;
  }

  .inlined-label label {
    padding: 8px 2px 8px 12px !important;
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 340px) {
  .inlined-label label {
    padding: 4px 2px 4px 12px !important;
    font-size: 0.9rem !important;
  }
}

/* Sidebar Menu */

app-main .mat-drawer,
app-consumer-main .mat-drawer {
  min-width: 230px !important;
  overflow-x: hidden;
}

app-main .mat-drawer.mat-drawer-opened,
app-consumer-main .mat-drawer.mat-drawer-opened {
  transform: none;
}

#side-menu {
  position: relative;
  z-index: 4;
  overflow: auto;

  /* max-height: calc(100vh - 75px); */
  min-height: 100%;
}

app-main #side-menu mat-list-item,
app-consumer-main #side-menu mat-list-item {
  width: 100%;
  color: #fff !important;
  text-transform: uppercase;
}

#side-menu mat-list-item {
  width: 100%;
  color: #fff !important;
}

#side-menu mat-list-item a {
  width: 100%;
  text-align: left !important;
}

#side-menu mat-list-item a.active {
  background: rgb(255 255 255 / 10%);
}

#side-menu mat-icon {
  margin-right: 10px;
}

#side-menu mat-icon.caret {
  position: absolute;
  top: 20%;
  right: 0;
  margin-right: 0;
  transition: all 0.15s ease-out;
}

#side-menu mat-icon.caret.opened {
  transform: rotate(180deg);
}

#side-menu .mat-button {
  font-weight: 200 !important;
}

/* Mobile First */

@media (min-width: 768px) {
  #header h1.mat-h1.inline {
    margin-left: 10px;
  }

  app-merchant-root .mat-sidenav-content {
    height: 100vh !important;
  }

  .logo-container {
    transition: all 0.15s ease-out;
  }

  .mat-sidenav.mat-sidenav-push {
    min-width: 220px;
    padding: 0 25px;
  }

  .mat-sidenav.mat-sidenav-closed .mat-h1 {
    opacity: 0;
    transition: all 0.2s;
  }

  .mat-sidenav.mat-sidenav-closed,
  .mat-sidenav.mat-sidenav-closing {
    transform: translate3d(-150px, 0, 0) !important;
    visibility: visible !important;
  }

  .mat-sidenav.mat-sidenav-closed .logo-container,
  .mat-sidenav.mat-sidenav-closing .logo-container {
    width: 70px;
    transform: translate3d(145px, 0, 0);
  }

  .mat-sidenav.mat-sidenav-opened,
  .mat-sidenav.mat-sidenav-opening {
    width: 260px;
  }

  .mat-sidenav-closed nav,
  .mat-sidenav-closing nav {
    transform: translate3d(150px, 0, 0) !important;
  }

  .mat-button span.text {
    transition: all 0.2s;
  }

  .mat-sidenav-closed nav .mat-button span.text,
  .mat-sidenav-closing nav .mat-button span.text {
    opacity: 0;
  }

  .mat-sidenav-closed nav .mat-button,
  .mat-sidenav-closing nav .mat-button {
    width: 36px !important;
    min-width: 0;
  }

  .mat-sidenav-closed nav mat-icon.caret,
  .mat-sidenav-closing nav mat-icon.caret {
    display: none;
  }

  .mat-sidenav-closed .sub-menu,
  .mat-sidenav-closing .sub-menu {
    display: none;
  }

  .right-border {
    border-right: 1px solid #e9e9e9;
  }
}

/* aw-Wizard Bar */

aw-wizard-navigation-bar {
  /* padding: 15px 0; */
  border-bottom: 1px solid #e9e9e9;
  margin: 0 -20px;
  margin-bottom: 10px;
  background: #f7f7f7;
}

app-marketing-campaign-create-edit aw-wizard-navigation-bar,
app-dentist-invite-create aw-wizard-navigation-bar {
  margin: -20px -20px 0;
}

ul.steps-indicator {
  max-width: 1440px;
  margin: 0 auto !important;
}

.steps-indicator li a {
  font-size: 12px !important;
  font-weight: 300 !important;
  letter-spacing: 0.5px;
}

.horizontal.large-filled-symbols ul.steps-indicator li::after {
  top: -38px !important;
  left: calc(50% - 12px) !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 0.75rem;
  line-height: 25px !important;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li .step-indicator,
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable a:hover .step-indicator {
  position: absolute;
  top: -40px;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #e6e6e6;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  color: rgb(61 61 61);
  line-height: 30px;
  text-align: center;
  transition: 0.25s;
  vertical-align: middle;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable.optional a:hover .step-indicator {
  background-color: inherit;
}

.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child)::before {
  left: calc(50% + 20px) !important;
  width: calc(100% - 40px) !important;
}

aw-wizard-navigation-bar ul.steps-indicator li a .label {
  padding-top: 0 !important;
  background: transparent;
  font-weight: inherit;
  text-decoration: none;
  text-transform: none;
}

/* .horizontal.large-filled-symbols ul.steps-indicator li.current:after,
.horizontal.large-filled-symbols ul.steps-indicator li.editing:after {
  background-color: #4acad5 !important;
  color: #fff !important;
  -webkit-box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.03), 0px 4px 1px 0 rgba(0, 0, 0, 0.13);
  -moz-box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.03), 0px 4px 1px 0 rgba(0, 0, 0, 0.13);
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.03), 0px 4px 1px 0 rgba(0, 0, 0, 0.13);
  border-bottom: 2px solid #3ba7b1;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.horizontal.large-filled-symbols ul.steps-indicator li.done:after {
  background: #95db7b !important;
  color: #fff !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #92c57f;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
} */

.horizontal.large-filled-symbols ul.steps-indicator {
  padding: 50px 0 10px !important;
}

.horizontal.large-filled-symbols ul.steps-indicator li {
  padding: 2px 0 0 !important;
}

/* Hide non-visible steps in bar */

aw-wizard-navigation-bar li[step-symbol='a'],
aw-wizard-navigation-bar li[step-symbol='b'],
aw-wizard-navigation-bar li[step-symbol='c'] {
  display: none;
}

app-loan-application-finalize aw-wizard-navigation-bar li[step-symbol='8'] {
  display: none;
}

app-loan-application-create aw-wizard-navigation-bar li[step-symbol='6'] {
  display: none;
}

app-payment-plan-start aw-wizard-navigation-bar li[step-symbol='6'] {
  display: none;
}

app-payment-plan-start aw-wizard-navigation-bar li[step-symbol='5']::before {
  display: none;
}

app-membership-application-start aw-wizard-navigation-bar li[step-symbol='7']::before {
  display: none;
}

app-loan-application-start aw-wizard-navigation-bar,
app-payment-plan-start aw-wizard-navigation-bar,
app-membership-application-start aw-wizard-navigation-bar {
  margin-top: -90px;
  transition: all 0.5s ease-in-out;
}

app-loan-application-start aw-wizard-navigation-bar.commenced,
app-payment-plan-start aw-wizard-navigation-bar.commenced,
app-membership-application-start aw-wizard-navigation-bar.commenced {
  margin-top: 0;
}

@media (max-width: 768px) {

  app-loan-application-start aw-wizard-navigation-bar,
  app-payment-plan-start aw-wizard-navigation-bar,
  app-membership-application-start aw-wizard-navigation-bar {
    margin-top: -90px;
    margin-bottom: 40px;
  }

  app-loan-application-start aw-wizard-navigation-bar.commenced,
  app-payment-plan-start aw-wizard-navigation-bar.commenced,
  app-membership-application-start aw-wizard-navigation-bar.commenced {
    margin-top: 0;
    margin-bottom: 0;
  }
}

#loanApplication.horizontal ul.steps-indicator li a {
  margin: 0 15px;
}

#loanApplicationContract aw-wizard-navigation-bar {
  border: none;
  background: none;
}

/* Special styling for sub-wizard */

/* #loanApplicationContract aw-wizard-navigation-bar {
  margin-bottom: -20px;
} */

/* #loanApplicationContract .horizontal.small ul.steps-indicator li.current:after {
  background-color: #4acad5 !important;
  color: #fff !important;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.03), 0px 4px 1px 0 rgba(0, 0, 0, 0.13);
  border-bottom: 2px solid #3ba7b1;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

#loanApplicationContract .horizontal.small ul.steps-indicator li.done:after {
  background: #95db7b !important;
  color: #fff !important;
  box-shadow: none;
  border-bottom: 2px solid #92c57f;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
} */

/*
#loanApplicationContract a {
  color: #fff;
}

#loanApplicationContract .horizontal.small ul.steps-indicator li:not(:last-child):before {
  background-color: #e6e6e654;
} */

@media (max-width: 768px) {
  .steps-indicator li a {
    font-size: 0 !important;
    line-height: 0 !important;
  }

  aw-wizard-navigation-bar {
    padding: 10px 0 0;
    margin-bottom: 5px;
  }

  .mat-tab-header {
    margin-bottom: 0 !important;
  }
}

/* List Status Labels */

.cdk-column-InvitationStatus span.list-label,
.cdk-column-ApplicationStatus span.list-label,
.cdk-column-ContractStatus span.list-label,
span.list-label {
  position: relative;
  display: inline;

  /* display: inline-block; */
  overflow: hidden;
  padding: 0.5em 0.6em;
  border-radius: 5px;
  color: #fff;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  text-shadow: 0 1px 1px rgb(0 0 0 / 15%);
  vertical-align: baseline;
  white-space: nowrap;
}

p span.list-label {
  top: -2px;
  margin-left: 10px;
  background: rgb(90 186 71);
}

p span.list-label::after {
  left: 0;
  height: 100%;
}

.cdk-column-InvitationStatus span.list-label::after,
.cdk-column-ApplicationStatus span.list-label::after,
.cdk-column-ContractStatus span.list-label::after,
span.list-label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 24px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgb(255 255 255 / 25%) 0, rgb(138 138 138 / 0%) 46%, rgb(0 0 0 / 12%) 100%);
  content: '';
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#73ffffff', endColorstr='#33000000', GradientType=0);
}

.mat-badge-medium .mat-badge-content,
.mat-badge-content.mat-badge-active {
  width: auto !important;
  min-width: 22px;
  height: 22px;
  box-sizing: border-box;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 20px !important;
  line-height: 22px;
}

.mat-badge-content {
  width: auto !important;
}

.chip {
  margin-left: 0 !important;
  position: relative;
  overflow: hidden;
  padding: 3px 12px 2px;
  border-radius: 20px;
  margin-left: 3px;
  background: #9c9c9c;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 6%), 0 1px 3px 0 rgb(0 0 0 / 6%);
  color: rgb(255 255 255);
  font-family: Nunito;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-shadow: 0 1px 1px rgb(0 0 0 / 30%);
}

.mat-chip-list-wrapper .mat-chip {
  margin-top: 3px !important;
}

.chipBeta {
  position: relative;
  top: 0;
  margin-left: 4px !important;
  background: #4d86ff;
}

/* List Tables */

.mat-header-row,
.mat-row {
  padding: 6px 24px !important;
}

.search-table-header {
  padding: 8px 0 0 !important;
}

.truncate-ellipsis {
  display: table;
  width: 100%;
  table-layout: fixed;
  white-space: nowrap;
}

.truncate-ellipsis>* {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
}

mat-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-cell {
  padding: 5px;
}

.mat-column-Actions {
  max-width: 45px;
}

.mat-row {
  border-color: rgb(0 0 0 / 5%);
}

mat-row:nth-child(odd) {
  background-color: #f9f9f9;
}

mat-row.listRow.separatedRow {
  position: relative;
  z-index: 1;
  border-radius: 4px;
  border-top: 2px solid #e3e3e3;
  border-right: 1px solid rgb(0 0 0 / 12%);
  border-left: 1px solid rgb(0 0 0 / 12%);
  margin: 0 0 8px;
  background: transparent;
}

mat-row.listRow.separatedRow .mat-cell {
  z-index: 2;
}

.separatedRow .cdk-column-fieldColor {
  position: absolute;
  z-index: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.07;
  pointer-events: none;
}

.mat-cell.cdk-column-Icon,
.mat-header-cell.cdk-column-Icon {
  max-width: 50px;
}

mat-header-cell.mat-header-cell.cdk-column-fieldColor {
  display: none;
}

/* Overview Cards */

.staticsCard.mat-card {
  background: #f2f5f6;
  background: linear-gradient(to bottom,
      rgb(242 245 246 / 100%) 0,
      rgb(227 234 237 / 100%) 37%,
      rgb(200 215 220 / 100%) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f2f5f6', endColorstr='#c8d7dc', GradientType=0);
}

.staticsCard.mat-card.good {
  background: #00b7ea;
  background: linear-gradient(to bottom, rgb(0 183 234 / 100%) 0, rgb(0 158 195 / 100%) 100%);
  color: #fff !important;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00b7ea', endColorstr='#009ec3', GradientType=0);
}

.staticsCard.mat-card.bad {
  background: rgb(230 106 0);
  background: linear-gradient(to bottom, rgb(245 68 55) 0, rgb(230 106 0) 100%);
  color: #fff !important;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00b7ea', endColorstr='#009ec3', GradientType=0);
}

.staticsCard h3 {
  padding-bottom: 4px;
  margin: 0;
  font-size: 0.85rem;
  font-weight: 300 !important;
  text-align: center;
}

.statisticsBlock .mat-list .mat-list-item .mat-list-item-content {
  height: auto !important;
  margin-bottom: 6px;
  font-size: 0.9rem;
}

.chartsCard h1,
.listCard h1,
.graph-card h1 {
  padding-bottom: 4px;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 300 !important;
}

.chartsCard h3,
.listCard h2,
.graph-card h3 {
  padding-bottom: 8px;
  margin: 0;
  font-size: 1rem;
  font-weight: 300 !important;
}

.chartsCard h3.center,
.graph-card h3.center {
  display: block;
  width: 100%;
  margin: 20px 0 0;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
}

.chartsCard h4,
.graph-card h4 {
  padding-bottom: 20px;
  margin: 0;
  color: #9a9a9a;
  font-size: 1rem;
  font-weight: 300 !important;
  text-align: center;
}

.staticsCard span {
  display: block;
  font-size: 2rem;
  font-weight: 300 !important;
  text-align: center;
}

span.history {
  color: #9a9a9a;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1rem;
}

.history mat-icon {
  position: relative;
  top: 3px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  float: left;
  font-size: 18px;
}

.total {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  height: 2.8rem;
  margin: auto;
  color: #5a5a5a !important;
  font-size: 1.8rem;
  font-style: italic;
  font-weight: 700;
  pointer-events: none;
  text-align: center;
}

.total span {
  display: block;
  padding: 0;
  margin: 0;
}

span.totLab {
  margin-top: -0.9rem !important;
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0.7;
}

span.totSub {
  margin-top: 0 !important;
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0.7;
}

@media (min-width: 768px) {
  .overflow-card {
    padding: 24px 35px !important;
    margin: 20px -35px !important;
  }
}

h1.dataHeader strong::after {
  content: ':';
}

@media only screen and (max-width: 767px) {

  /* ,(min-device-width: 767px) and (max-device-width: 1024px) { */
  .dataCard {
    width: auto !important;
    max-width: 100% !important;
  }

  h1.dataHeader {
    margin: 8px 16px;
    font-size: 1.6rem;
  }

  p.dataHeader,
  .dataHeader {
    margin-right: 16px;
    margin-left: 16px;
  }

  /* Force table to not be like tables anymore */

  /* .list-card table,
  .list-card thead,
  .list-card tbody,
  .list-card th,
  .list-card td,
  .list-card tr,
  .list-card mat-table,
  .list-card .mat-table {
    display: block;
  } */

  /* Hide table headers (but not display: none;, for accessibility) */

  /* .list-card thead tr,
  .list-card mat-header-row {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .list-card tr,
  .list-card mat-row {
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    margin-bottom: 3px;
    margin-top: 2px;
    display: block !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, .08), 0 1px 5px 0 rgba(0, 0, 0, .06);
  }

  .list-card td,
  .list-card mat-cell {

    border: none;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    text-align: left !important;
    padding-left: 40% !important;
    display: block !important;
  }

  .list-card td:before,
  .list-card mat-cell:before {

    position: absolute;

    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 11px;
  }

  .list-card .cdk-column-Actions button {
    border: 1px solid #c5c5c5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .list-card .cdk-column-Actions {
    max-width: 46% !important;
    border-bottom: none;
  } */
}

/* Modals */

.cdk-column-Actions {
  max-width: 50px;
  text-align: center;
}

.cdk-column-dateTimeCreated,
.cdk-column-CreatedDate {
  max-width: 150px;
}

.cdk-column-selectCheckBox {
  max-width: 40px;
}

.cdk-column-active,
.cdk-column-IsAdministrator,
.cdk-column-Enabled,
.cdk-column-thumbnail,
.cdk-column-cataloguePic,
.cdk-column-logoPic,
.cdk-column-ProfileImage,
.cdk-column-profilePicture,
.cdk-column-merchantProfilePicture,
.cdk-column-dentistProfilePicture,
.cdk-column-profilePic,
.cdk-column-isMenuDisplayed,
.cdk-column-IsActive,
.cdk-column-isActive {
  max-width: 110px;
}

p.noFurther {
  box-sizing: border-box;
  padding: 6px;
  border: 1px solid #eee;
  border-radius: 6px;
  background: #ff6868;
  background: linear-gradient(to bottom, #fffcdc, #d9a7c7);
  background: linear-gradient(to bottom, #ff6868, #d0222a);
  color: #fff;
  font-size: 0.82rem;
  font-weight: 400;
  text-shadow: 0 1px 0 rgb(0 0 0 / 20%), 0 1px 5px rgb(0 0 0 / 20%);
}

.my-full-screen-dialog .mat-dialog-container {
  width: 100vw;
  max-width: none;
  height: 100vh;
}

.noCard {
  transition: all 0.5s;
}

.noCard mat-dialog-container,
.noCard .mat-dialog-container {
  background: none !important;
  box-shadow: none;
}

.ScrollNoCard mat-dialog-container,
.ScrollNoCard .mat-dialog-container {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none;
}

.ScrollNoCard mat-dialog-container app-blob-view {
  display: block;
}

.ScrollNoCard mat-dialog-container app-blob-view::after {
  display: block;
  clear: both;
  content: '';
}

.ScrollNoCard mat-dialog-container app-blob-view button.mt {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ScrollNoCard mat-dialog-container app-blob-view button.pull-right {
  margin: 10px 10px 10px 0;
}

.ScrollNoCard mat-dialog-container app-blob-view #fileContainer {
  overflow: scroll;
  max-height: 65vh;
  overflow-x: hidden;
}

.cdk-overlay-backdrop {
  position: fixed !important;
}

.top-modal {
  position: absolute !important;
  top: 40px !important;
  max-width: 120vw !important;
}

.modal-map .mapDisplay {
  height: 650px !important;
}

mat-dialog-container app-customer-view-modal {
  display: block;
  padding: 0;
  margin: -45px -25px;
}

mat-dialog-container .float-close {
  position: absolute;
  z-index: 10;
  top: 17px;
  right: 15px;
}

span.list-label.smaller.round {
  display: block;
  width: 16px;
  height: 16px;
  padding: 4px !important;
  border-radius: 20px;
}

.calculator mat-card-header.text-center.mat-card-header {
  flex-direction: column;
  padding: 20px 24px 5px;
  margin: -24px -16px 30px;
  color: #fff;
  text-align: center;
}

.mat-dialog-container {
  overflow: visible !important;
}

app-session-countdown-modal {
  display: block;
  max-width: 400px;
}

h2 .subLabel {
  display: block;
  font-size: 0.9rem;
  opacity: 0.8;
  word-break: break-word;
}

.inModal button.btn-close {
  display: flex;
  align-items: center;
  color: #fff;
  float: right;
}

.card-header.primary-gradient-img {
  position: relative;
  z-index: 1;
}

.card-header.primary-gradient-img.inModal {
  position: relative;
  padding: 24px;
  margin: -24px -16px 20px;
  color: #fff;
}

.card-header.primary-gradient-img::before {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(22.5deg,
      rgb(67 67 67 / 2%) 0%,
      rgb(67 67 67 / 2%) 29%,
      rgb(47 47 47 / 2%) 29%,
      rgb(47 47 47 / 2%) 37%,
      rgb(23 23 23 / 2%) 37%,
      rgb(23 23 23 / 2%) 55%,
      rgb(182 182 182 / 2%) 55%,
      rgb(182 182 182 / 2%) 69%,
      rgb(27 27 27 / 2%) 69%,
      rgb(27 27 27 / 2%) 71%,
      rgb(250 250 250 / 2%) 71%,
      rgb(250 250 250 / 2%) 100%),
    linear-gradient(67.5deg,
      rgb(117 117 117 / 2%) 0%,
      rgb(117 117 117 / 2%) 14%,
      rgb(199 199 199 / 2%) 14%,
      rgb(199 199 199 / 2%) 40%,
      rgb(33 33 33 / 2%) 40%,
      rgb(33 33 33 / 2%) 48%,
      rgb(135 135 135 / 2%) 48%,
      rgb(135 135 135 / 2%) 60%,
      rgb(148 148 148 / 2%) 60%,
      rgb(148 148 148 / 2%) 95%,
      rgb(53 53 53 / 2%) 95%,
      rgb(53 53 53 / 2%) 100%),
    linear-gradient(135deg,
      rgb(190 190 190 / 2%) 0%,
      rgb(190 190 190 / 2%) 6%,
      rgb(251 251 251 / 2%) 6%,
      rgb(251 251 251 / 2%) 18%,
      rgb(2 2 2 / 2%) 18%,
      rgb(2 2 2 / 2%) 27%,
      rgb(253 253 253 / 2%) 27%,
      rgb(253 253 253 / 2%) 49%,
      rgb(128 128 128 / 2%) 49%,
      rgb(128 128 128 / 2%) 76%,
      rgb(150 150 150 / 2%) 76%,
      rgb(150 150 150 / 2%) 100%),
    linear-gradient(90deg, #fff, #fff);
  content: '';
  mix-blend-mode: multiply;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
}




.header-card-customized {
  position: relative;
  padding: 24px;
  border-radius: 4px 4px 0 0;
  margin: -16px -16px 20px;
  color: #fff;
  margin-top: -24px !important;

}

.card-header.header-card-customized,
.header-card-customized.heading.row {
  border-radius: 4px 4px 0 0;
}

.card-header.header-card-customized.inModal p,
.card-header.header-card-customized.inModal h2 {
  font-weight: 500;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 4px rgb(0 0 0 / 20%);
}

.card-header.header-card-customized.inModal h2 {
  width: 100%;
  padding-top: 0;
  padding-left: 10px;
  margin-top: 10px;
}

/* .card-header.header-card-customized.inModal .hasIcon h2 {
  padding-left: 50px;
} */

.card-header.header-card-customized.inModal .hasIcon mat-icon {
  width: auto;
  height: auto;
  font-size: 2rem;
}

.card-header.header-card-customized.inModal label {
  color: rgb(255 255 255 / 80%);
}



.card-header.header-card-customized::before {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(22.5deg,
      rgb(67 67 67 / 2%) 0%,
      rgb(67 67 67 / 2%) 29%,
      rgb(47 47 47 / 2%) 29%,
      rgb(47 47 47 / 2%) 37%,
      rgb(23 23 23 / 2%) 37%,
      rgb(23 23 23 / 2%) 55%,
      rgb(182 182 182 / 2%) 55%,
      rgb(182 182 182 / 2%) 69%,
      rgb(27 27 27 / 2%) 69%,
      rgb(27 27 27 / 2%) 71%,
      rgb(250 250 250 / 2%) 71%,
      rgb(250 250 250 / 2%) 100%),
    linear-gradient(67.5deg,
      rgb(117 117 117 / 2%) 0%,
      rgb(117 117 117 / 2%) 14%,
      rgb(199 199 199 / 2%) 14%,
      rgb(199 199 199 / 2%) 40%,
      rgb(33 33 33 / 2%) 40%,
      rgb(33 33 33 / 2%) 48%,
      rgb(135 135 135 / 2%) 48%,
      rgb(135 135 135 / 2%) 60%,
      rgb(148 148 148 / 2%) 60%,
      rgb(148 148 148 / 2%) 95%,
      rgb(53 53 53 / 2%) 95%,
      rgb(53 53 53 / 2%) 100%),
    linear-gradient(135deg,
      rgb(190 190 190 / 2%) 0%,
      rgb(190 190 190 / 2%) 6%,
      rgb(251 251 251 / 2%) 6%,
      rgb(251 251 251 / 2%) 18%,
      rgb(2 2 2 / 2%) 18%,
      rgb(2 2 2 / 2%) 27%,
      rgb(253 253 253 / 2%) 27%,
      rgb(253 253 253 / 2%) 49%,
      rgb(128 128 128 / 2%) 49%,
      rgb(128 128 128 / 2%) 76%,
      rgb(150 150 150 / 2%) 76%,
      rgb(150 150 150 / 2%) 100%),
    linear-gradient(90deg, #fff, #fff);
  content: '';
  mix-blend-mode: multiply;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
}


.card-header.primary-gradient-img,
.primary-gradient-img.heading.row {
  border-radius: 4px 4px 0 0;
}

.card-header.primary-gradient-img.inModal p,
.card-header.primary-gradient-img.inModal h2 {
  font-weight: 500;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 4px rgb(0 0 0 / 20%);
}

.card-header.primary-gradient-img.inModal h2 {
  width: 100%;
  padding-top: 0;
  padding-left: 10px;
  margin-top: 10px;
}

/* .card-header.primary-gradient-img.inModal .hasIcon h2 {
  padding-left: 50px;
} */

.card-header.primary-gradient-img.inModal .hasIcon mat-icon {
  width: auto;
  height: auto;
  font-size: 2rem;
}

.card-header.primary-gradient-img.inModal label {
  color: rgb(255 255 255 / 80%);
}

.card-header .colourOverlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  mix-blend-mode: color;
}

.card-header .content {
  position: relative;
  z-index: 2;
  padding: 24px;
  margin: -24px;
}

@media only screen and (max-width: 600px) {
  .card-header.primary-gradient-img.inModal {
    padding: 24px 16px;
    margin: -24px -16px 20px;
  }
}

.confirmModal button.btn-close {
  color: #fff;
  float: right;
}

.card-header.primary-gradient-img.confirmModal {
  padding: 24px;
  margin: -24px -16px 20px;
  color: #fff;
}

.card-header.primary-gradient-img.confirmModal p,
.card-header.primary-gradient-img.confirmModal h2 {
  font-weight: 500;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 4px rgb(0 0 0 / 20%);
}

.card-header.primary-gradient-img.confirmModal h2 {
  width: 100%;
  padding-top: 0;
  padding-left: 42px;
  margin-top: 10px;
}

.card-header.confirmModal .titleArea {
  max-width: 80%;
  float: left;
}

.card-header.inModal .titleArea {
  display: flex;
  max-width: 80%;
  flex-direction: row;
  align-items: center;
  float: left;
}

.card-header.primary-gradient-img.confirmModal label {
  color: rgb(255 255 255 / 80%);
}

.animate.contain {
  overflow: hidden;
}

.confirmModal mat-icon,
mat-icon.large-icon {
  width: auto;
  height: auto;
  font-size: 2rem;
}

.primary-gradient-img.warning {
  border: none !important;
  border-radius: 0 !important;
  background: linear-gradient(-45deg, rgb(208 31 118 / 90%) 0%, rgb(210 0 0 / 90%) 100%) !important;
  background: linear-gradient(135deg, rgb(208 31 118 / 90%) 0%, rgb(210 0 0 / 90%) 100%) !important;
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d01f76e6', endColorstr='#d05318e6', GradientType=1);
}

.stacked button.btn-close {
  position: absolute;
  top: 16px;
  right: 15px;
  color: #fff;
  float: right;
}

.card-header.primary-gradient-img.stacked {
  position: relative;
  padding: 24px;
  border-radius: 4px 4px 0 0;
  margin: -16px -16px 20px;
  color: #fff;
}

.card-header.primary-gradient-img.stacked p,
.card-header.primary-gradient-img.stacked h2 {
  font-weight: 500;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 4px rgb(0 0 0 / 20%);
}

.card-header.primary-gradient-img.stacked h2 {
  width: 100%;
  padding-top: 0;
  margin-top: 10px;
  text-transform: capitalize;
}

.card-header.stacked .titleArea {
  display: flex;
  width: 100%;
  flex-direction: column;
  float: left;
  text-align: center;
}

.card-header.primary-gradient-img.stacked label {
  color: rgb(255 255 255 / 80%);
}

.animate.contain {
  overflow: hidden;
}

.card-header.primary-gradient-img.stacked app-ep-document-view-image {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 10px;
  background: #fff;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

mat-dialog-content.stacked-card-view {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  margin-top: -24px !important;
  margin-bottom: 0;
}

/* buttons */
.action-button {
  margin-bottom: -40px;
  text-align: right;
}

button.actionMenuToggle {
  z-index: 100;
  color: #5e5e5e;
}

.merchantView button.btn-close {
  color: #fff;
  float: right;
}

.mat-stroked-mini-fab {
  position: relative;
  display: inline-block;
  overflow: visible;
  width: 40px;
  min-width: 40px !important;
  min-width: 0;
  height: 40px;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 0 !important;
  border-radius: 4px;
  border-radius: 50% !important;
  margin: 0;
  cursor: pointer;
  line-height: 40px !important;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  vertical-align: baseline;
  white-space: nowrap;
}

/*  selection buttons */
.selection-items {
  margin-right: 5px;
  float: right;
}

.selection-items.adjusted {
  margin: 10px 5px 0 0;
  float: right;
}

.selection-items button.row.select-btn {
  display: inline-block;
  margin: 0 5px 0 0;
  color: #616161;
}

.selection-items.adjusted button {
  min-height: 39px;
}

@media (max-width: 1600px) {

  .selection-items button.row.select-btn,
  .heading-controls button,
  .heading-controls .mat-button-toggle {
    min-width: 50px !important;
    padding: 2px 10px 3px !important;
    font-size: 0.75rem !important;
    line-height: 1.1rem !important;
  }

  .selection-items button.row.select-btn mat-icon,
  .heading-controls button mat-icon {
    display: block !important;
    margin: 6px auto -10px !important;
    font-size: 1.1rem !important;
  }

  .heading-controls .mat-button-toggle .mat-button-toggle-label .mat-button-toggle-label-content mat-icon {
    display: block !important;
    margin: 3px auto 0 !important;
    font-size: 0.9rem !important;
  }

  .heading-controls .mat-button-toggle-label-content {
    font-size: 0.75rem !important;
    line-height: 1.1rem !important;
  }
}

@media (max-width: 1300px) {
  h1.dataHeader {
    margin-top: 15px !important;
    font-size: 1.4rem;
  }

  .heading-controls.pull-right {
    margin-bottom: 10px;
  }
}

@media (max-width: 950px) {
  .selection-items button.row.select-btn {
    min-width: 50px !important;
    padding: 2px 10px 3px !important;
    font-size: 0 !important;
    line-height: 0.7rem !important;
  }
}

@media (max-width: 768px) {
  .mat-dialog-container {
    margin-right: auto;
    margin-left: auto;
  }

  .noCard mat-dialog-container {
    width: auto;
    max-width: 100% !important;
    max-height: 95vh !important;
    padding: 20px 10px;
    margin: 0 auto;
  }

  .row.clearfix.flex.mainSearch {
    display: block;
  }

  .row.clearfix.flex.mainSearch mat-select {
    display: inline-block;
    width: 50% !important;
    box-sizing: border-box;
    margin-right: 10px;
    float: none;
  }

  #operatorFilter button.btn-pill.btn-small {
    padding: 2px 8px !important;
  }

  .full-width.flex.searcher {
    display: inline-block;
    width: 45% !important;
    box-sizing: border-box;
    float: none;
  }

  .row.clearfix.flex.mainSearch .full-width.flex.searcher mat-select {
    width: 100% !important;
  }

  .list-table .cdk-column-Customer {
    min-width: 100px;
  }

  .list-table .mat-header-cell {
    overflow: hidden;
  }

  .searchArea .qtr-width.flex {
    width: 50% !important;
    max-width: 350px;
  }

  .list-label.smaller mat-icon {
    width: auto;
    height: auto;
    font-size: 1rem;
    font-weight: 700;
  }

  span.list-label.smaller {
    border-radius: 50%;
  }

  span.list-label.smaller::after {
    height: 100%;
  }
}

@media (max-width: 600px) {

  mat-card-header.mat-card-header,
  .mat-card-header {
    margin: -24px -16px -30px !important;
  }

  .calculator .back mat-card-header.text-center.mat-card-header {
    margin: 0 0 10px !important;
  }
}

.autoHeight mat-dialog-container {
  height: auto !important;
}

/* Quill fonts */

.ql-size-huge {
  font-size: 2.5em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-font-serif {
  font-family: Georgia, 'Times New Roman', serif;
}

.ql-font-monospace {
  font-family: Monaco, 'Courier New', monospace;
}

p.ql-align-center,
.ql-align-center {
  text-align: center;
}

h2.ql-align-center {
  margin: 0;
}

/* Gravatar */

img.grav {
  border-radius: 50%;
}

h2 app-gravatar {
  top: 15px !important;
}

app-ep-document-view-image.display-image img.mat-card-image.ep-view-img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: auto;
  box-shadow: none;
}

/* Invitation */

.treatment-card .mat-tab-label,
.treatment-card .mat-tab-link {
  font-size: 0.95rem !important;
}

.treatment-card .row.clearfix.tab-header {
  position: relative;
  padding: 40px 25px 10px;
  margin-bottom: 40px;
  background: linear-gradient(-45deg, rgb(43 192 228 / 90%) 0%, rgb(234 236 198 / 90%) 100%),
    url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png);
  background: linear-gradient(135deg, rgb(43 192 228 / 90%) 0%, rgb(234 236 198 / 90%) 100%),
    url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#e62bc0e4', endColorstr='#e6eaecc6', GradientType=1);
  text-align: center;
}

.treatment-card .row.clearfix.tab-header-advanced {
  position: relative;
  padding: 40px 20px 10px;
  margin-bottom: 0;
  background: linear-gradient(-45deg, rgb(229 93 135 / 90%) 0%, rgb(228 94 136 / 90%) 1%, #5fc3e4e6 100%),
    url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png);
  background: linear-gradient(-45deg, rgb(229 93 135 / 90%) 0%, rgb(228 94 136 / 90%) 1%, rgb(95 195 228 / 90%) 100%),
    url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png);
  background: linear-gradient(135deg, rgb(229 93 135 / 90%) 0%, rgb(228 94 136 / 90%) 1%, rgb(95 195 228 / 90%) 100%),
    url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/background.png);
  background-size: cover;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#e6e55d87', endColorstr='#e65fc3e4', GradientType=1);
  text-align: center;
}

.treatment-card .tab-header h3.sm-h3.rm-mt,
.treatment-card .tab-header-advanced h3.sm-h3 {
  margin-bottom: 15px;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 300;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
}

.treatment-card .tab-header-advanced hr {
  margin: 20px 0;
  background: rgb(255 210 248 / 33%);
}

.tab-header-advanced p {
  margin: 20px auto;
  color: #fff;
  text-align: center;
}

@media (min-width: 768px) {
  .tab-header-advanced p {
    max-width: 70%;
  }
}

/* App Container Backgrounds */

app-invitation-template-create-edit,
app-existing-patient-create {
  margin-top: -20px;
}

.body-container-content app-invitation-create::after,
app-settlement-create::after,
app-dentist-invite-create::after,
app-membership-create::after {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fbfbfb;
  content: '';
}

app-invitation-create aw-wizard-navigation-bar,
app-settlement-create aw-wizard-navigation-bar,
app-appointment-create aw-wizard-navigation-bar,
app-membership-create aw-wizard-navigation-bar,
app-dentist-invite-create aw-wizard-navigation-bar,
app-lending-invitation-create aw-wizard-navigation-bar,
app-medical-history-invitation-create aw-wizard-navigation-bar {
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

@media (min-width: 768px) {

  app-invitation-create aw-wizard-navigation-bar,
  app-settlement-create aw-wizard-navigation-bar,
  app-appointment-create aw-wizard-navigation-bar,
  app-membership-create aw-wizard-navigation-bar,
  app-dentist-invite-create aw-wizard-navigation-bar,
  app-lending-invitation-create aw-wizard-navigation-bar,
  app-medical-history-invitation-create aw-wizard-navigation-bar {
    padding-left: 40px;
    margin-right: -20px;
    margin-left: -20px;
  }
}

/* app-main-card:after, */
app-patient-card-superadvanced::after,
app-patient-card-emergency::after,
app-facility-card::after,
app-lending-customer-card::after,
app-cold-application::after,
app-page404::after,
app-unsubscribe::after,
app-simple-time-out::after,
app-maintenance-page::after,
app-page403::after,
app-page500::after,
app-something-wrong::after,
app-merchant-create::after,
app-payment-plan-start::after,
app-payment-plan-finalize::after,
app-loan-application-start::after,
app-loan-application-finalize::after,
app-membership-application-start::after,
app-member-card::after {
  position: fixed;
  z-index: -2;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: url('https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pattern.png') repeat !important;
  content: '';
}

app-invitation-settings app-invitation-template-list-overview #operatorFilter {
  padding: 0 !important;
  margin: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}

app-invitation-settings app-invitation-template-list-overview app-operator-filter.dash {
  display: block;
  margin: 0;
}

app-invitation-settings app-note-type-list-overview #operatorFilter {
  padding: 0 !important;
  margin: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}

app-invitation-settings app-note-type-list-overview app-operator-filter.dash {
  display: block;
  margin: 0;
}

app-patient-card::before,
app-cold-application::before,
app-member-card::before {
  display: none !important;
}

.deep.primary-gradient,
.deep.primary-gradient-img {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.deep.site_background {
  position: absolute;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover !important;
}

/* error colour */

app-simple-time-out::before,
app-page404::before,
app-page500::before,
app-maintenance-page::before,
app-something-wrong::before {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: rgb(229 140 30 / 7%);
  background: linear-gradient(to bottom, rgb(229 140 30 / 7%) 0%, rgb(229 0 0 / 20%) 100%);
  content: '';
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#121e88e5', endColorstr='#0a0098e5', GradientType=0);
}

.error-input-manual {
  color: rgb(233 30 90) !important;
}

.mat-error {
  margin-top: 3px;
  color: rgb(255 17 0) !important;
  font-weight: bold;
}

/* merchant-specific bg colour */

app-merchant-create::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: rgb(0 179 255 / 3%);
  background: linear-gradient(to bottom, rgb(44 163 162 / 7%) 0%, rgb(0 152 229 / 4%) 100%);
  content: '';
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#121e88e5', endColorstr='#0a0098e5', GradientType=0);
}

aw-wizard-navigation-bar {
  z-index: 20;
}

app-invitation-communication-view {
  display: block;
  margin-top: -24px;
}

app-invitation-view-modal {
  display: block;
  margin-top: -24px;
}

/*  wizard navigation colour */
aw-wizard-navigation-bar ul.steps-indicator li .step-indicator {
  color: #5b5b5b;
  font-weight: bold;
  text-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 1px 5px rgb(0 0 0 / 10%);
}

aw-wizard-navigation-bar ul.steps-indicator li.current .step-indicator {
  background-color: #1b8bdd !important;
  color: white !important;
}

aw-wizard-navigation-bar ul.steps-indicator li.editing .step-indicator {
  color: white;
}

aw-wizard-navigation-bar ul.steps-indicator li.done .step-indicator,
aw-wizard-navigation-bar ul.steps-indicator li.navigable.done a:hover .step-indicator {
  background-color: #02c5d3 !important;
  color: white !important;
  font-size: 0;
}

aw-wizard-navigation-bar ul.steps-indicator li.done .step-indicator::after {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 25%;
  display: block;
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
}

aw-wizard-navigation-bar ul.steps-indicator li.done.editing .step-indicator {
  background-color: #f00 !important;
  color: white !important;
  font-size: 0;
}

aw-wizard-navigation-bar ul.steps-indicator li.done.editing .step-indicator::after {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 25%;
  display: block;
  content: '\f303';
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
}

@media (min-width: 768px) {

  app-patient-card .container,
  app-facility-card .container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0;
  }

  app-patient-card,
  app-facility-card {
    display: flex;
    flex-direction: column;

    /* min-height: 100vh; */
  }
}

app-membership-overview app-membership-list .mat-table {
  overflow: auto;
  max-height: 25vh !important;
}

app-membership-package-inclusion-list .mat-table,
app-membership-package-list .mat-table {
  overflow: auto;
  max-height: calc(100vh - 380px) !important;
}

/* hide step bar in membership application */

/*  dp popup time picker */

.dp-popup button {
  border: none;
  background: none;
}

.dp-popup {
  z-index: 99999999999999 !important;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%) !important;
}

.dp-calendar-nav-container {
  border: none !important;
}

.dp-calendar-wrapper {
  height: 35px;
  border: none !important;
  border-top: 1px solid #c7c7c7 !important;
}

/* Horizontal Scrolling */

.inline {
  display: inline-block;
}

.card.inline.mat-card.product-card {
  display: inline-block;
  min-width: 320px;
  margin: 20px 5px;
}

.mat-expansion-panel {
  background: #fdfdfd;
}

.mat-expansion-panel .mat-expansion-panel-header.main .mat-content {
  display: block !important;
  overflow: visible;
}

.scroller-chamber {
  min-height: 280px;
  padding: 0 16px;
  margin: 0 -16px;
  text-align: center;
}

.panel-content p {
  white-space: normal !important;
  word-wrap: normal !important;
}

@media (min-width: 768px) {
  .scroller-chamber {
    padding: 0 16px;
    margin: 0 -16px;
  }

  app-patient-card .container,
  app-facility-card .container {
    width: 100%;
    max-width: 1000px;
  }

  #payment-panel .mat-expansion-panel-content.mat-expanded {
    overflow: visible !important;
  }
}

@media (max-width: 768px) {
  .mat-dialog-container mat-card.calculator .mat-dialog-content .scroller-chamber {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-dialog-container mat-card.calculator .back {
    padding: 0;
  }

  .mat-dialog-container mat-card.calculator .button-row.clearfix.text-center.mt {
    padding: 5px;
  }

  .mat-dialog-container mat-card.calculator .mat-dialog-content h3.sm-h3.sr-title.rm-mt {
    margin: 10px !important;
  }
}

app-product-view-compact-merch .mat-expansion-panel-body {
  padding: 0;
}

.response-grey-row {
  padding: 1px 20px 30px;
  margin: 0 -24px -16px;
  background: #f7f7f7;
}

.nav-btn-left,
.nav-btn-right {
  position: absolute !important;
  top: 50%;
}

.nav-btn-left {
  left: -25px;
}

.nav-btn-right {
  right: -25px;
}

@media (min-width: 768px) {
  .nav-btn-left {
    left: -15px;
  }

  .nav-btn-right {
    right: -15px;
  }
}

mat-accordion.card {
  width: auto;
  min-width: 80vw;
}

@media (min-width: 768px) {
  mat-accordion.card {
    min-width: 305px;
  }

  mat-accordion.card.compact {
    width: 305px;
    min-width: 305px;
  }
}

/* Patient Card */

app-patient-card .container,
app-facility-card .container {
  padding-bottom: 50px;
}

app-header-consumer-light {
  z-index: 10;
}

.mat-expansion-panel .mat-expansion-panel-header.main-patient-card .mat-content {
  display: block !important;
  overflow: visible;
}

mat-expansion-panel-header.main-patient-card::after {
  position: absolute;
  z-index: 0;
  top: -100px;
  left: 0;
  display: block;
  width: 100%;

  /* IE6-9 */
  height: 100px;
  background: #1e88e5;
  background: linear-gradient(to bottom, #1e88e5 0%, #0098e5 100%);
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  content: '';
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#1e88e5', endColorstr='#0098e5', GradientType=0);
}

mat-expansion-panel-header.main-patient-card {
  min-height: 270px;
}

@media (min-width: 768px) {
  mat-expansion-panel-header.main-patient-card {
    min-height: 210px;
  }
}

h3.wide-header {
  padding: 10px !important;
  border-radius: 3px;
  color: #fff;
  text-shadow: 0 1px 6px rgb(0 39 64 / 30%);
}

.main-patient-card .mat-expansion-indicator.ng-trigger {
  position: absolute;
  z-index: 10;
  top: -60px;
  right: 25px;
}

.documentCard mat-dialog-container.mat-dialog-container {
  padding: 0;
}

.documentCard mat-dialog-container.mat-dialog-container .padd {
  padding: 10px;
}

.freqSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.freqSelect .mat-radio-label {
  display: block !important;
  text-align: center !important;
}

.freqSelect .mat-radio-container {
  display: block !important;
  margin: 0 auto !important;
}

.freqSelect .mat-radio-label-content {
  display: block !important;
  padding-right: 0;
  padding-left: 0 !important;
  margin: 0 5px !important;
}

.freqSelect .mat-radio-label p {
  margin-bottom: 0;
}

.mobshow {
  display: block;
}

.mobhide {
  display: none !important;
}

.mat-button-wrapper span.mobHide {
  display: none !important;
}

.merchBtn {
  min-width: 0 !important;
}

@media (min-width: 768px) {
  .mobshow {
    display: none !important;
  }

  .mobhide {
    display: block !important;
  }

  .mat-button-wrapper span.mobHide {
    display: inline-block !important;
  }

  button span.mobhide {
    display: inline-block !important;
  }

  .main-patient-card .mat-expansion-indicator.ng-trigger {
    top: -33px;
  }

  .main-patient-card .mat-expansion-indicator.ng-trigger {
    position: absolute;
    z-index: 10;
    top: -60px;
    right: 25px;
  }

  .treatment-row .description {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
  }

  .treatment-row .description .qtr-width {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .treatment-row .description .qtr-width .column {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: left;
  }
}

.documentCard .mat-dialog-content {
  display: block;
  overflow: auto;
  width: 100%;
  max-height: 65vh;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.main-patient-card .mat-expansion-indicator.ng-trigger::after {
  color: #fff !important;
}

.mat-expansion-panel-header.bordered.mat-expanded {
  border-bottom: 1px solid #e9e9e9;
}

.borderTop {
  border-top: 1px solid #e3e3e3;
}

app-treatment-list-consumer .toothView {
  display: inline-block;
  width: auto !important;
  margin: 0 -40px !important;
  text-align: center;
}

app-treatment-list-consumer .toothView p {
  color: #9a9a9a !important;
  font-size: 11px !important;
}

app-treatment-list-consumer .dviewCont {
  max-width: 450px;
  border-radius: 10px;
  margin-left: 20px !important;
  background: rgb(0 0 0 / 3%);
}

app-treatment-list-consumer .dview {
  padding: 5px;
  margin: 0 10px 10px 0;
}

app-treatment-list-consumer div.teethShap {
  position: relative;
  z-index: 10;
  display: inline-block;
  margin: 0 auto;
}

app-treatment-list-consumer app-dental-arch-view {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: center;
}

mat-dialog-container app-treatment-informed-consent-create {
  display: flex;
  flex-direction: column;
  margin-top: -24px !important;
}

mat-dialog-container .card-header.primary-gradient-img {
  border-radius: 4px 4px 0 0;
}

@media (max-width: 768px) {
  app-treatment-list-consumer .dview {
    display: flex !important;
  }

  .pat-lookup-btn {
    position: relative !important;
    margin: 0 auto 10px;
  }

  app-treatment-list-consumer .dviewCont {
    max-width: 100%;
    border-radius: 5px;
    margin-left: 0 !important;
    background: rgb(0 0 0 / 2%);
  }

  .selectMerchant mat-select.margin-btm.half-width {
    width: calc(100% - 70px);
  }
}

/* Memberships */

app-membership-overview .pat-sum-header {
  display: none;
}

/* CSS Ribbon - red */

.ribbon {
  position: absolute;
  z-index: 1;
  top: -5px;
  right: -5px;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  position: absolute;
  top: 19px;
  right: -21px;
  display: block;
  width: 100px;
  background: #79a70a;
  background: linear-gradient(#f70505 0%, #8f0808 100%);
  box-shadow: 0 3px 10px -5px rgb(0 0 0 / 100%);
  color: #fff;
  font-size: 9px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(45deg);
}

.ribbon span::before {
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
  border-top: 3px solid #8f0808;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #8f0808;
  content: '';
}

.ribbon span::after {
  position: absolute;
  z-index: -1;
  top: 100%;
  right: 0;
  border-top: 3px solid #8f0808;
  border-right: 3px solid #8f0808;
  border-bottom: 3px solid transparent;
  border-left: 3px solid transparent;
  content: '';
}

/* Path styling */

#adult-dental-arches path {
  stroke: #7d7d7d !important;
}

#adult-dental-arches path.empty {
  fill: none !important;
  transition: all 0.2s ease-in;
}

#adult-dental-arches path.blue {
  fill: #1e88e5 !important;
  stroke: #e9e9e9 !important;
  transition: all 0.2s ease-in;
}

/* Example tooth highlighting */

#adult-dental-arches .tooth-highlighted,
#adult-dental-arches .tooth-18.tooth-18-parent,
#adult-dental-arches .tooth-16.tooth-16-parent {
  fill: #bbdefb !important;
}

/* mat panels hack in tabs */

.mat-tab-label mat-icon {
  width: auto;
  height: auto;
  margin-right: 8px;
  font-size: 1rem;
}

mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-content {
  height: 0 !important;
  visibility: hidden !important;
}

.mat-expansion-panel-header {
  height: 48px;
}

/*  consent */

.consent span {
  margin-left: 10px;
  font-size: 0.9rem;
  font-weight: 500;
}

.consent span mat-icon {
  position: relative;
  top: 2px;
  width: auto;
  height: auto;
  font-size: 1rem;
}

.consent span.green {
  background: linear-gradient(-45deg, rgb(31 208 129 / 90%) 0%, rgb(7 159 167 / 90%) 100%);
  background: linear-gradient(135deg, rgb(31 208 129 / 90%) 0%, rgb(7 159 167 / 90%) 100%);
  background-size: cover;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 4%), 0 1px 5px 0 rgb(0 0 0 / 2%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#1fd081e6', endColorstr='#079fa7e6', GradientType=1);
  text-shadow: 0 1px 1px rgb(0 0 0 / 12%);
}

.consent span.red {
  background: linear-gradient(-45deg, rgb(208 31 118 / 90%) 0%, rgb(210 0 0 / 90%) 100%);
  background: linear-gradient(135deg, rgb(208 31 118 / 90%) 0%, rgb(210 0 0 / 90%) 100%);
  background-size: cover;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 4%), 0 1px 5px 0 rgb(0 0 0 / 2%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d01f76e6', endColorstr='#d05318e6', GradientType=1);
  text-shadow: 0 1px 1px rgb(0 0 0 / 12%);
}

/* example images */

.example-header-image {
  background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
  background-size: cover;
}

/* Notifications */

.notification .mat-list-item-content {
  height: auto !important;
  padding: 0 !important;
}

/*  icons and images  */
.display-image-inner .ep-view-img {
  border-radius: 0 !important;
}

.squareImg {
  display: flex !important;
  min-height: 200px !important;
  margin: 0 -16px 20px !important;
}

.squareImg div {
  border-radius: 0 !important;
}

.template-preview .squareImg {
  border-bottom: 1px solid #e7e7e7 !important;
  margin: 0 -24px 5px !important;
}

app-template-review iframe {
  /* pointer-events: none; */
}

@media (max-width: 768px) {
  .squareImg {
    margin: 0 -16px 20px !important;
  }
}

/* Animations */

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -60%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes bounceHoriz {

  0%,
  20%,
  53%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-30px, 0, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-15px, 0, 0);
  }

  90% {
    transform: translate3d(-4px, 0, 0);
  }
}

@keyframes bounceHoriz {

  0%,
  20%,
  53%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-20px, 0, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(-4px, 0, 0);
  }
}

.bounceHoriz {
  animation: bounceHoriz 2s 10s infinite;
  animation-name: bounceHoriz;
  transform-origin: center bottom;
}

.bounceHoriz.stop {
  animation: none;
}

/* TEETH */

.teeth-button-array .mat-button-toggle .mat-button-toggle-label .mat-button-toggle-label-content {
  padding: 0 !important;
}

.teeth-button-array .mat-button-toggle-checked {
  background-color: #fff;
  color: rgb(0 0 0 / 54%);
}

.teeth-button-array .mat-button-toggle-checked svg {
  fill: #f44336;
}

.teeth-button-array .mat-button-toggle-checked svg .cls-1 {
  fill: #ffebeb;
}

.teeth-button-array .mat-button-toggle svg {
  transition: all 0.1s ease-out;
}

.teeth-button-array .mat-button-toggle:hover svg {
  transform: scale(1.2);
}

.teeth-button-array {
  margin: 20px auto 10px;
}

.teeth-button-array mat-button-toggle {
  padding: 0 2px;
  box-shadow: none;
}

/* .mat-button-toggle .mat-button-toggle-label .mat-button-toggle-label-content {
  padding: 0 !important;
} */

.teeth-button-array mat-button-toggle p {
  margin: 0;
}

.teeth-button-array svg {
  width: 100%;
}

div.teethShap.selected .cls-1 {
  border: 1px solid #1f88e5;
  fill: #1f88e5;
}

@media (max-width: 1905px) {
  .toothView {
    flex: 1 0 15%;
  }
}

@media (max-width: 2024px) {
  .toothView {
    flex: 1 0 13%;
  }
}

/*  messages */

.messages .speech-bubble {
  position: relative;
  left: 20px;
  max-width: 70%;
  padding: 15px 25px;
  border-radius: 0.4em;
  background: #0ab;
  color: #fff;
}

.messages .speech-bubble::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  border: 0.625em solid transparent;
  border-right-color: #0ab;
  border-bottom: 0;
  border-left: 0;
  margin-top: -0.312em;
  margin-left: -0.625em;
  content: '';
}

.messages .speech-bubble-right {
  position: relative;
  max-width: 50%;
  padding: 15px 25px;
  border-radius: 0.4em;
  margin-right: 30px;
  margin-left: auto;
  background: #bb6463;
  color: #fff;
}

.messages .speech-bubble-right::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right: 0;
  border-bottom: 0;
  border-left-color: #bb6463;
  margin-top: -10px;
  margin-right: -20px;
  content: '';
}

.messages .speech-bubble-im {
  position: relative;
  padding: 15px;
  border-radius: 20px;
  margin: 16px auto;
  background: #1b8bdd;
  color: #fff;
}

.messages .speech-bubble-im-reply {
  position: relative;
  padding: 15px;
  border-radius: 20px;
  margin: 16px auto 16px 5px;
  background: #ddd;
  color: black;
}

/* i18n */

.lang.clearfix {
  width: auto;
  margin-top: 5px;
  margin-right: 15px;
  float: left;
}

.lang label .flag-icon {
  width: 2em;
  margin: 5px 0;
  font-size: 1.4rem;
}

.lang.clearfix .i18n {
  width: auto;
  padding: 0;
  margin: 0;
}

.lang.clearfix .i18n .mat-select-value {
  color: #fff !important;
  font-weight: 300;
  letter-spacing: 1px;
}

.lang.clearfix .i18n .mat-select-arrow {
  color: #fff !important;
}

.lang.clearfix .i18n .mat-select-underline {
  background-color: #fff !important;
}

.lang.clearfix .i18n .mat-select-trigger {
  min-width: 90px;
}

/* ADMIN PANEL */

.table-striped.table-bordered thead tr th {
  color: rgb(0 0 0 / 54%);
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.table-striped.table-bordered tbody tr td {
  color: rgb(0 0 0 / 87%);
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.table-striped.table-bordered thead tr th,
.table-striped.table-bordered tbody tr td {
  display: table-cell;
  min-height: 48px;
  align-items: center;
  padding: 10px 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(0 0 0 / 12%);
}

tbody input.form-control {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 6px 0 8px;
  border: none;
  border-bottom: 1px solid #949494;
  margin: 0;
  color: #818181;
  font-size: 0.9rem;
  font-weight: 300;
}

.pagination {
  display: flexbox;
  display: flex;
  padding-left: 0;
  border-radius: 0.25rem;
  list-style: none;
}

.ngx-pagination {
  box-sizing: border-box;
  padding: 0;
  font-size: 0.9rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ddd;
  margin-left: -1px;
  background-color: #fff;
  color: #0275d8;
  line-height: 1.25;
  text-decoration: none;
  transition: all 0.1s ease-out;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #777;
  opacity: 0.4;
}

/* Wave spinner */

.wave-spinner {
  background-image: url('https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/savingData.gif') !important;
  background-position: right !important;
  background-repeat: no-repeat !important;
}

#userDataLoader {
  position: fixed;
  z-index: 100;
  bottom: 10px;
  left: calc(50% - 150px);
  display: none;
  width: 300px !important;
  padding: 10px;
  border-radius: 7px;
  background: #fff;
  opacity: 0.85;
  text-align: center;
}

#userDataLoader img {
  width: 35px;
  height: 35px;
  margin-top: -5px;
  margin-bottom: -5px;
  float: left;
}

#userDataLoader p {
  font-weight: 500;
}

#dataLoadingSection {
  position: fixed;
  z-index: 100;
  bottom: 10px;
  left: calc(50% - 150px);
  display: none;
  width: 300px !important;
  padding: 10px;
  border-radius: 7px;
  background: #fff;
  opacity: 0.85;
  text-align: center;
}

#dataLoadingSection img {
  width: 35px;
  height: 35px;
  margin-top: -5px;
  margin-bottom: -5px;
  float: left;
}

#dataLoadingSection p {
  font-weight: 500;
}

.lds-ellipsis {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: rgb(114 114 114);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

/* Settlements */

.settlement-chart h1 {
  margin: 0;
  font-weight: 300;
}

.settlement-chart h3 {
  margin: 5px auto 15px;
  color: #777;
  font-weight: 300;
}

.settlement-chart h3.center {
  margin: 20px auto 10px;
  font-size: 1.85rem;
}

.settlement-chart h4 {
  margin: 0 auto 20px;
  color: #a9a9a9;
  font-size: 1rem;
  font-weight: 300;
}

input#settlement-input {
  font-size: 2.5rem;
  font-weight: 200;
  letter-spacing: 3px;
  text-align: center;
}

.clearfix.settleWell {
  position: relative;
  padding: 0;
  border-bottom: 1px solid #e3e3e3;
  margin: 1px 1px 8px 0;
  transition: all ease-out 0.1s;
}

.clearfix.settleWell .row.clearfix.grey {
  padding: 8px 16px 0;
  border-top: 1px solid #e2e2e2;
  margin: 0 -16px;
  background: #f8f8f8;
  transition: all ease-out 0.1s;
}

.clearfix.settleWell .row.clearfix.grey:hover {
  background: #e2e2e2;
}

.settleWell .status {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
}

.settleWell .status mat-icon {
  margin: 0;
  font-size: 1rem;
}

.settleWell.approved .status {
  background: #00d07a;
  color: #fff;
}

.settleWell.declined .status {
  background: #d00e00;
  color: #fff;
}

.settleWell.pending .status {
  background: #ffc011;
  color: #fff;
}

.settleWell.requested .status {
  background: #5f92ff;
  color: #fff;
}

.settleWell.cancelled .status {
  background: #7e7d7d;
  color: #fff;
}

.settleWell.awaiting .status {
  background: #825fff;
  color: #fff;
}

app-customer-prospect-profile-tabs .container.contract {
  max-width: 100% !important;
}

/* tab headers */

.auditInfo .mat-tab-header {
  border-bottom: none;
  margin-bottom: 0 !important;
}

.auditInfo .mat-tab-label {
  border-right: 1px solid #e3e3e3;
  background: #fff;
  background: linear-gradient(to bottom, #fff 0%, #f3f3f3 50%, #ededed 51%, #fff 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

.mat-tab-group-dynamic-height .mat-tab-body-content {
  padding-left: 1px;
}

/* Contact Us */

app-contact-us {
  display: block;
}

#contact-tabs .mat-tab-header {
  margin-bottom: -1px !important;
}

/* Footer mobile styles */
app-merchant-footer#application-footer {
  z-index: 9;
}

footer button {
  font-size: 0.7rem !important;
  line-height: 1.6rem !important;
}

footer mat-icon {
  width: auto !important;
  height: auto !important;
  font-size: 1.1rem !important;
  line-height: 0 !important;
}

@media (max-width: 768px) {

  footer .pull-right,
  footer .pull-left {
    float: none;
  }

  footer button {
    margin-bottom: 5px;
    float: none;
  }

  .lang.clearfix {
    width: auto;
    height: 50px;
    margin-top: 5px;
    margin-right: 15px;
    float: none;
  }

  .lang.clearfix .i18n {
    width: 100%;
    padding: 0;
    margin: 0 5px;
  }

  .btn-help {
    padding-left: 0 !important;
  }
}

@media (max-width: 360px) {

  footer .mat-button,
  footer .mat-raised-button {
    padding: 0 !important;
  }

  footer .mat-button,
  footer .mat-raised-button {
    font-size: 12px !important;
  }

  .btn-help {
    font-size: 75% !important;
  }

  footer .mat-button strong {
    display: none;
  }

  p.lead {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

/* Animated Icons */

.svg-box * {
  padding: 0;
  margin: 0;
}

.svg-box {
  position: relative;
  display: inline-block;
  width: 150px;
  margin: 10px 0;
}

.green-stroke {
  stroke: #7cb342;
}

.red-stroke {
  stroke: #ff6245;
}

.yellow-stroke {
  stroke: #ffc107;
}

.circular circle.path {
  animation: 0.7s draw-circle ease-out;
  opacity: 0.4;
  stroke-dasharray: 330;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

/* Birthday icon */

.birthday mat-icon,
mat-icon.star {
  position: relative;
  top: 1px !important;
  width: 15px;
  color: #ffd800;
  font-size: 0.95rem;
  text-shadow: 0 0 1px rgb(130 56 0 / 70%), 0 2px 4px rgb(0 0 0 / 20%);
}

p.small.birthday {
  display: inline-block;
  padding: 6px 6px 0;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  background: #f9f9f9;
  color: #929292 !important;
  line-height: 30px;
}

/* ------- Checkmark --------- */

.checkmark.green-stroke {
  position: absolute;
  top: 56px;
  left: 49px;
  width: 52px;
  height: 40px;
  background: none !important;
  stroke-linecap: round;
  stroke-width: 6.25;
}

.checkmark.green-stroke path {
  animation: 1s draw-check ease-out;
}

@keyframes draw-circle {
  0% {
    opacity: 1;
    stroke-dasharray: 0, 330;
    stroke-dashoffset: 0;
  }

  80% {
    opacity: 1;
    stroke-dasharray: 330, 330;
    stroke-dashoffset: 0;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes draw-check {
  0% {
    opacity: 0;
    stroke-dasharray: 49, 80;
    stroke-dashoffset: 48;
  }

  50% {
    opacity: 1;
    stroke-dasharray: 49, 80;
    stroke-dashoffset: 48;
  }

  100% {
    stroke-dasharray: 130, 80;
    stroke-dashoffset: 48;
  }
}

/* ---------- Cross ---------- */

.cross {
  position: absolute;
  top: 54px;
  left: 54px;
  width: 40px;
  height: 40px;
  stroke-linecap: round;
  stroke-width: 6.25;
}

.cross .first-line {
  animation: 0.7s draw-first-line ease-out;
}

.cross .second-line {
  animation: 0.7s draw-second-line ease-out;
}

@keyframes draw-first-line {
  0% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 56, 330;
    stroke-dashoffset: 0;
  }
}

@keyframes draw-second-line {
  0% {
    stroke-dasharray: 0, 55;
    stroke-dashoffset: 1;
  }

  50% {
    stroke-dasharray: 0, 55;
    stroke-dashoffset: 1;
  }

  100% {
    stroke-dasharray: 55, 0;
    stroke-dashoffset: 70;
  }
}

.alert-sign {
  position: absolute;
  top: 40px;
  left: 68px;
  width: 15px;
  height: 70px;
  animation: 0.5s alert-sign-bounce cubic-bezier(0.175, 0.885, 0.32, 1.275);
  stroke-linecap: round;
  stroke-width: 6.25;
}

.alert-sign .dot {
  fill: #ffc107;
  stroke: none;
}

@keyframes alert-sign-bounce {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

/* over-height modal hack */

.mat-dialog-container {
  max-height: 97vh !important;
}

.flex .mat-dialog-container {
  display: flex !important;
  max-height: 85vh !important;
  flex-direction: column;
}

.flex .mat-dialog-container app-patient-card-stop-modal,
.flex .mat-dialog-container app-product-calculator {
  display: flex;
  flex-direction: column;
}

.flex .mat-dialog-container app-patient-card-stop-modal,
.flex .mat-dialog-container app-product-estimation {
  display: flex;
  flex-direction: column;
}

.flex .mat-dialog-container .rel,
.flex .mat-dialog-container .container,
.flex .mat-dialog-container .container .mat-card {
  display: flex;
  flex-direction: column;
}

.flex .mat-dialog-container .rel .modal-content,
.flex .mat-dialog-container .container .modal-content {
  display: flex;
  flex-direction: column;
  padding: 5px 16px;
  margin: 0 -16px;
  overflow-y: auto;
}

.mainSearch .mat-form-field-wrapper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {

  .mat-list .mat-list-item,
  .mat-nav-list .mat-list-item,
  .mat-selection-list .mat-list-item {
    font-size: 14px !important;
  }

  .mainSearch {
    text-align: center;
  }

  .mainSearch .full-width.searcher {
    max-width: 100% !important;
    padding-right: 0 !important;
    text-align: left;
  }

  app-operator-filter #operatorFilter mat-select {
    max-width: 100% !important;
    text-align: left;
  }

  .mat-dialog-container {
    max-width: 92vw !important;
  }

  .flex .mat-dialog-container .rel .modal-content,
  .flex .mat-dialog-container .container .modal-content {
    display: flex;
    flex-direction: column;
    padding: 5px 16px;
    border-top: 1px solid #ebebeb;
    margin: 0 -16px;
    overflow-y: auto;
  }
}

/* contact modal  */

app-contact-us mat-dialog-content.contact {
  margin: 0;
}

/* character images */

.pleaseChoose {
  display: block;
  min-height: 20px;
  padding: 19px;
  border: 1px solid #d5d5d7;
  border-radius: 4px;
  margin: 15px 0;
  margin-top: 15px;
  background: #fdfdfd78;
  box-shadow: inset 0 1px 6px rgb(0 0 0 / 2%);
  text-align: center;
}

.pleaseChoose img {
  max-width: 250px;
  margin: 30px auto;
}

.pleaseChoose img.character_warning {
  max-width: 150px;
  margin: 5px auto;
}

.pleaseChoose p {
  color: #a0a0a0;
  font-size: 1.2rem;
  line-height: 1.95rem;
}

/* Time output filter */

.card.selectMerchant.inner {
  margin: 10px 0 !important;
}

.timeOutput p.small {
  color: #757575;
  line-height: 1rem;
  vertical-align: baseline;
}

.timeOutput p.small mat-icon {
  position: relative;
  top: 5px;
}

app-time-input .mat-form-field-infix {
  min-width: unset !important;
}

/* Print */

.print-only {
  display: none;
}

/* Devices */

.marvel-device {
  position: relative;
  display: inline-block;
  box-sizing: content-box !important;
}

.marvel-device .screen {
  position: relative;
  z-index: 3;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  background: white;
  box-shadow: 0 0 0 3px #111;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.marvel-device .top-bar,
.marvel-device .bottom-bar {
  display: block;
  width: 100%;
  height: 3px;
  background: black;
}

.marvel-device .middle-bar {
  position: absolute;
  top: 0;
  left: 90px;
  width: 3px;
  height: 4px;
  background: black;
}

.marvel-device.iphone8 {
  display: block;
  width: 345px;
  height: 555px;
  padding: 105px 24px;
  border-radius: 56px;
  margin: 20px auto;
  background: #d9dbdc;
  box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 20%), 0 4px 5px -3px rgb(0 0 0 / 20%), 0 9px 9px -6px rgb(0 0 0 / 14%),
    0 7px 23px 2px rgb(0 0 0 / 12%);
}

.marvel-device.iphone8::before {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  border-radius: 50px;
  background: #f8f8f8;
  content: '';
}

.marvel-device.iphone8::after {
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  border-radius: 48px;
  box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 10%), inset 0 0 6px 3px #fff;
  content: '';
}

.marvel-device.iphone8 .home {
  position: absolute;
  z-index: 3;
  bottom: 22px;
  left: 50%;
  width: 58px;
  height: 58px;
  border-radius: 100%;
  margin-left: -24px;
  background: #303233;
  background: linear-gradient(135deg, #303233 0%, #b5b7b9 50%, #f0f2f2 69%, #303233 100%);
}

.marvel-device.iphone8 .home::before {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 100%;
  background: #f8f8f8;
  content: '';
}

.marvel-device.iphone8 .top-bar {
  position: absolute;
  top: 68px;
  left: 0;
  height: 14px;
  background: #bfbfc0;
}

.marvel-device.iphone8 .bottom-bar {
  position: absolute;
  bottom: 68px;
  left: 0;
  height: 14px;
  background: #bfbfc0;
}

.marvel-device.iphone8 .sleep {
  position: absolute;
  top: 190px;
  right: -4px;
  width: 4px;
  height: 66px;
  border-radius: 0 2px 2px 0;
  background: #d9dbdc;
}

.marvel-device.iphone8 .volume {
  position: absolute;
  z-index: 0;
  top: 188px;
  left: -4px;
  width: 4px;
  height: 66px;
  border-radius: 2px 0 0 2px;
  background: #d9dbdc;
}

.marvel-device.iphone8 .volume::before {
  position: absolute;
  top: -78px;
  left: 2px;
  display: block;
  width: 2px;
  height: 40px;
  border-radius: 2px 0 0 2px;
  background: inherit;
  content: '';
}

.marvel-device.iphone8 .volume::after {
  position: absolute;
  top: 82px;
  left: 0;
  display: block;
  width: 4px;
  height: 66px;
  border-radius: 2px 0 0 2px;
  background: inherit;
  content: '';
}

.marvel-device.iphone8 .camera {
  position: absolute;
  z-index: 3;
  top: 24px;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-left: -6px;
  background: #3c3d3d;
}

.marvel-device.iphone8 .sensor {
  position: absolute;
  z-index: 3;
  top: 49px;
  left: 134px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #3c3d3d;
}

.marvel-device.iphone8 .speaker {
  position: absolute;
  z-index: 3;
  top: 54px;
  left: 50%;
  width: 60px;
  height: 6px;
  border-radius: 6px;
  margin-left: -30px;
  background: #292728;
}

.marvel-device.iphone8.gold {
  background: #f9e7d3;
}

.marvel-device.iphone8.gold .top-bar,
.marvel-device.iphone8.gold .bottom-bar {
  background: white;
}

.marvel-device.iphone8.gold .sleep,
.marvel-device.iphone8.gold .volume {
  background: #f9e7d3;
}

.marvel-device.iphone8.gold .home {
  background: #cebba9;
  background: linear-gradient(135deg, #cebba9 0%, #f9e7d3 50%, #cebba9 100%);
}

.dark .marvel-device.iphone8 {
  background: #464646;
  box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 70%);
}

.dark .marvel-device.iphone8::before {
  background: #080808;
}

.dark .marvel-device.iphone8::after {
  box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 10%), inset 0 0 6px 3px #212121;
  box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 10%), inset 0 0 6px 3px #212121;
}

.dark .marvel-device.iphone8 .top-bar,
.dark .marvel-device.iphone8 .bottom-bar {
  background: #212121;
}

.dark .marvel-device.iphone8 .volume,
.dark .marvel-device.iphone8 .sleep {
  background: #464646;
}

.dark .marvel-device.iphone8 .camera {
  background: #080808;
}

.dark .marvel-device.iphone8 .home {
  background: #080808;
  background: linear-gradient(135deg, #080808 0%, #464646 50%, #080808 100%);
}

.dark .marvel-device.iphone8 .home::before {
  background: #080808;
}

.dark .marvel-device .screen {
  background: #464646;
}

/*  loading  */

mat-card.list-card.skeltal {
  min-height: calc(100vh - 600px);
}

.loading_gif.positioned {
  position: absolute;
  top: 10%;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 80px;
  height: 80px;
  border: none !important;
  margin: auto;
}

/*  Success animations */

/* colors */
@keyframes yellow-circle {
  0% {
    border-width: 0;
    opacity: 0.8;
  }

  80% {
    border-width: 25px;
    opacity: 0.8;
  }

  100% {
    border-width: 25px;
    opacity: 0.5;
  }
}

@keyframes yellow-circle {
  0% {
    border-width: 0;
    opacity: 0.8;
  }

  80% {
    border-width: 25px;
    opacity: 0.8;
  }

  100% {
    border-width: 25px;
    opacity: 0.5;
  }
}

@keyframes yellow-circle {
  0% {
    border-width: 0;
    opacity: 0.8;
  }

  80% {
    border-width: 25px;
    opacity: 0.8;
  }

  100% {
    border-width: 25px;
    opacity: 0.5;
  }
}

@keyframes yellow-circle {
  0% {
    border-width: 0;
    opacity: 0.8;
  }

  80% {
    border-width: 25px;
    opacity: 0.8;
  }

  100% {
    border-width: 25px;
    opacity: 0.5;
  }
}

@keyframes white-circle {
  from {
    border-width: 0;
  }

  to {
    border-width: 25px;
  }
}

@keyframes white-circle {
  from {
    border-width: 0;
  }

  to {
    border-width: 25px;
  }
}

@keyframes white-circle {
  from {
    border-width: 0;
  }

  to {
    border-width: 25px;
  }
}

@keyframes white-circle {
  from {
    border-width: 0;
  }

  to {
    border-width: 25px;
  }
}

@keyframes shiner {
  0% {
    top: 5px;
    height: 0;
    transform-origin: 1px -5px;
  }

  60% {
    top: 5px;
    height: 15px;
    transform-origin: 1px -5px;
  }

  100% {
    top: 15px;
    height: 0;
    transform-origin: 1px -15px;
  }
}

@keyframes shiner {
  0% {
    top: 5px;
    height: 0;
    transform-origin: 1px -5px;
  }

  60% {
    top: 5px;
    height: 15px;
    transform-origin: 1px -5px;
  }

  100% {
    top: 15px;
    height: 0;
    transform-origin: 1px -15px;
  }
}

@keyframes shiner {
  0% {
    top: 5px;
    height: 0;
    transform-origin: 1px -5px;
  }

  60% {
    top: 5px;
    height: 15px;
    transform-origin: 1px -5px;
  }

  100% {
    top: 15px;
    height: 0;
    transform-origin: 1px -15px;
  }
}

@keyframes shiner {
  0% {
    top: 5px;
    height: 0;
    transform-origin: 1px -5px;
  }

  60% {
    top: 5px;
    height: 15px;
    transform-origin: 1px -5px;
  }

  100% {
    top: 15px;
    height: 0;
    transform-origin: 1px -15px;
  }
}

@keyframes star {
  0% {
    color: #ffac33;
    font-size: 0;
  }

  30% {
    font-size: 0;
  }

  50% {
    font-size: 3.8em;
  }

  66% {
    font-size: 3em;
  }

  82% {
    font-size: 3.6em;
  }

  92% {
    font-size: 3.1em;
  }

  100% {
    font-size: 3.2em;
  }
}

@keyframes star {
  0% {
    color: #ffac33;
    font-size: 0;
  }

  30% {
    font-size: 0;
  }

  50% {
    font-size: 3.8em;
  }

  66% {
    font-size: 3em;
  }

  82% {
    font-size: 3.6em;
  }

  92% {
    font-size: 3.1em;
  }

  100% {
    font-size: 3.2em;
  }
}

@keyframes star {
  0% {
    color: #ffac33;
    font-size: 0;
  }

  30% {
    font-size: 0;
  }

  50% {
    font-size: 3.8em;
  }

  66% {
    font-size: 3em;
  }

  82% {
    font-size: 3.6em;
  }

  92% {
    font-size: 3.1em;
  }

  100% {
    font-size: 3.2em;
  }
}

@keyframes star {
  0% {
    color: #ffac33;
    font-size: 0;
  }

  30% {
    font-size: 0;
  }

  50% {
    font-size: 3.8em;
  }

  66% {
    font-size: 3em;
  }

  82% {
    font-size: 3.6em;
  }

  92% {
    font-size: 3.1em;
  }

  100% {
    font-size: 3.2em;
  }
}

section.fave {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

section.fave .circle {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
}

section.fave .circle::before,
section.fave .circle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-radius: 50%;
  content: '';
  transform: translate(-50%, -50%);
}

section.fave .circle::before {
  border: 0 solid #ffac33;
}

section.fave .circle::after {
  border: 0 solid #fff;
}

section.fave .shine {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
}

section.fave .shine .shiner {
  position: absolute;
  top: 5px;
  left: 0;
  width: 2px;
  height: 0;
  background: #ffac33;
}

section.fave .shine .shiner:nth-child(1) {
  transform: rotate(0deg);
}

section.fave .shine .shiner:nth-child(2) {
  transform: rotate(72deg);
}

section.fave .shine .shiner:nth-child(3) {
  transform: rotate(144deg);
}

section.fave .shine .shiner:nth-child(4) {
  transform: rotate(216deg);
}

section.fave .shine .shiner:nth-child(5) {
  transform: rotate(288deg);
}

section.fave i.fa-star {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-top: 1px;
  margin-left: 1px;
  color: lightgrey;
  font-size: 3.2em;
  transform: translate(-50%, -50%);
  transition: font-size 0.2s ease-in-out;
}

section.fave:hover {
  cursor: pointer;
}

section.fave:hover .fa-star {
  color: #ffac33;
}

section.fave.selected .circle::before {
  animation: yellow-circle 0.6s ease-in-out 0 1;
}

section.fave.selected .circle::after {
  animation: white-circle 0.5s ease-in-out 0.2s 1;
}

section.fave.selected .shine .shiner {
  animation: shiner 0.6s ease-out 1 0s;
}

section.fave.selected i.fa-star {
  animation: star 1.1s ease-in-out 1 0s;
  color: #ffac33;
  font-size: 3.2em;
}

/*  vertical timeline  */

.timeline {
  position: relative;
  padding: 5%;
}

.timeline::before {
  position: absolute;
  top: -5%;
  left: 50%;
  width: 5px;
  height: 100%;
  background: #c5cae9;
  content: '';
  transform: translateX(-50%);
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;
}

.timeline-item:nth-child(odd) .timeline-content .inner-content {
  margin-right: 0px;
}

.timeline-item:nth-child(even) .timeline-content {
  padding: 16px;
  float: right;
}

.timeline-item:nth-child(even) .timeline-content .date {
  right: auto;
  left: 0;
  display: none !important;
}

.timeline-item:nth-child(even) .timeline-content::after {
  position: absolute;
  top: 65px;
  left: -15px;
  width: 0;
  height: 0;
  border-width: 10px 15px 10px 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  content: '';
}

.timeline-item::after {
  display: block;
  clear: both;
  content: '';
}

.timeline-content {
  position: relative;
  width: 40%;
  padding: 16px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 20px 25px -15px rgb(0 0 0 / 30%);

  /* min-height: 10em; */
}

.timeline-content::after {
  position: absolute;
  top: 65px;
  right: -15px;
  width: 0;
  height: 0;
  border-width: 10px 0 10px 15px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  content: '';
}

vertical-timeline .timeline {
  overflow: hidden;
  background: #f2f2f2;
  box-shadow: inset 0 2px 20px rgb(0 0 0 / 10%);
}

app-event-log-list vertical-timeline-card mat-expansion-panel {
  margin: -10px -16px 10px !important;
  box-shadow: none !important;
}

app-event-log-list vertical-timeline-card mat-expansion-panel .mat-expansion-indicator::after {
  color: rgb(0 0 0 / 54%) !important;
}

.timeline-img {
  position: absolute;
  left: 50%;
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 45px;
  margin-left: -30px;
  background: #3f51b5;
}

.timeline-img p {
  color: #fff;
  font-size: small;
  line-height: 1;
}

.timeline-card {
  padding: 0 !important;
}

.timeline-card p {
  padding: 0 20px;
}

.timeline-card a {
  margin-left: 20px;
}

.timeline-item:nth-child(2) .timeline-img-header {
  background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%)),
    url('https://hd.unsplash.com/photo-1458530970867-aaa3700e966d') center center no-repeat;
  background-size: cover;
}

.timeline-item:nth-child(5) .timeline-img-header {
  background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%)),
    url('https://hd.unsplash.com/photo-1444093826349-9ce8c622f4f3') center center no-repeat;
  background-size: cover;
}

.timeline-item:nth-child(6) .timeline-img-header {
  background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%)),
    url('https://hd.unsplash.com/photo-1471479917193-f00955256257') center center no-repeat;
  background-size: cover;
}

.timeline-item:nth-child(8) .timeline-img-header {
  background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%)),
    url('https://hd.unsplash.com/photo-1466840787022-48e0ec048c8a') center center no-repeat;
  background-size: cover;
}

.timeline-item:nth-child(10) .timeline-img-header {
  background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%)),
    url('https://hd.unsplash.com/photo-1447639703758-f525f36456bf') center center no-repeat;
  background-size: cover;
}

.timeline-item:nth-child(11) .timeline-img-header {
  background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%)),
    url('https://hd.unsplash.com/photo-1469429978400-082eec725ad5') center center no-repeat;
  background-size: cover;
}

.timeline-img-header {
  position: relative;
  height: 200px;
  margin-bottom: 20px;
}

.timeline-content .date p {
  margin: 0;
  font-size: 0.85rem;
  font-weight: 600;

  /* text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.2); */
}

.timeline-img-header h2 {
  position: absolute;
  bottom: 5px;
  left: 20px;
  color: #fff;
}

.timeline-item .actionAction {
  position: absolute;
  top: 0;
  right: 5px;
}

blockquote {
  padding: 0 20px;
  border-left-color: #3f51b5;
  margin-top: 30px;
  color: #757575;
}

.timeline-content .date {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 10px;
  background: #3f51b5;
  color: #fff;
  display: none !important;
}

.darkCard mat-dialog-container {
  background: #efefef;
}

.timeline-content::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 38px;
  border-bottom: 1px solid #efefef;
  background: #fff;
  content: '';
  display: none !important;
}

/*  perfect scrollbar */

.ps__rail-y,
.ps__rail-x {
  z-index: 999999;
}

/* special colours and style */

/* vertical-timeline-card.invitation-event .timeline-content .date {
  background: #4b6cb7;
  background: -webkit-linear-gradient(to bottom, #0083B0, #4b6cb7) !important;
  background: linear-gradient(to bottom, #0083B0, #4b6cb7) !important;
}

vertical-timeline-card.Contract .timeline-content .date,
vertical-timeline-card.contract .timeline-content .date,
vertical-timeline-card.contract-event .timeline-content .date,
vertical-timeline-card.responded .timeline-content .date,
vertical-timeline-card.Responded .timeline-content .date {
  background: #00B4DB;
  background: -webkit-linear-gradient(to bottom, #0083B0, #00B4DB) !important;
  background: linear-gradient(to bottom, #0083B0, #00B4DB) !important;
}

vertical-timeline-card.Compliance .timeline-content .date,
vertical-timeline-card.compliance .timeline-content .date {
  background: #ad5389;
  background: -webkit-linear-gradient(to bottom, #3c1053, #ad5389) !important;
  background: linear-gradient(to bottom, #3c1053, #ad5389) !important;
}

vertical-timeline-card.Created .timeline-content .date,
vertical-timeline-card.created .timeline-content .date,
vertical-timeline-card.commenced .timeline-content .date,
vertical-timeline-card.Commenced .timeline-content .date {
  background: #9D50BB;
  background: -webkit-linear-gradient(to bottom, #6E48AA, #9D50BB) !important;
  background: linear-gradient(to bottom, #6E48AA, #9D50BB) !important;
}

vertical-timeline-card.complete .timeline-content .date,
vertical-timeline-card.Complete .timeline-content .date {
  background: #007991;
  background: -webkit-linear-gradient(to bottom, #78ffd6, #007991) !important;
  background: linear-gradient(to bottom, #78ffd6, #007991) !important;
}

vertical-timeline-card.Contract.Status .timeline-content .date,
vertical-timeline-card.Declined .timeline-content .date,
vertical-timeline-card.declined .timeline-content .date {
  background: #e4005f !important;
  background: -webkit-linear-gradient(to top, #e44800, #ad0d0d) !important;
  background: linear-gradient(to top, #e44800, #ad0d0d) !important;
}

vertical-timeline-card.submitted .timeline-content .date,
vertical-timeline-card.Submitted .timeline-content .date,
vertical-timeline-card.performed .timeline-content .date,
vertical-timeline-card.sent .timeline-content .date {
  background: #536976;
  background: -webkit-linear-gradient(to bottom, #292E49, #536976) !important;
  background: linear-gradient(to bottom, #292E49, #536976) !important;
}

vertical-timeline-card.approved .timeline-content .date,
vertical-timeline-card.approval .timeline-content .date,
vertical-timeline-card.Approved .timeline-content .date {
  background: #56ab2f;
  background: -webkit-linear-gradient(to top, #a8e063, #56ab2f) !important;
  background: linear-gradient(to top, #a8e063, #56ab2f) !important;
}

vertical-timeline-card.referred .timeline-content .date,
vertical-timeline-card.Referred .timeline-content .date,
vertical-timeline-card.refered .timeline-content .date,
vertical-timeline-card.Refered .timeline-content .date {
  background: #fc4a1a;
  background: -webkit-linear-gradient(to top, #f7b733, #fc4a1a) !important;
  background: linear-gradient(to top, #f7b733, #fc4a1a) !important;
}

vertical-timeline-card.Activated .timeline-content .date,
vertical-timeline-card.activated .timeline-content .date {
  background: #11998e;
  background: -webkit-linear-gradient(to bottom, #38ef7d, #11998e) !important;
  background: linear-gradient(to bottom, #38ef7d, #11998e) !important;

} */

@media screen and (max-width: 1024px) {
  .timeline::before {
    left: 50px;
  }

  .timeline .timeline-img {
    left: 50px;
  }

  .timeline .timeline-content {
    width: auto;
    max-width: 100%;
    margin-left: 70px;
  }

  .timeline .timeline-item:nth-child(even) .timeline-content {
    margin-top: 35px;
    float: none;
  }

  .timeline .timeline-item:nth-child(even) .timeline-content .inner-content {
    margin-top: 0px !important;
  }

  .timeline .timeline-item:nth-child(odd) .timeline-content {
    margin-top: 35px;
  }

  .timeline .timeline-item:nth-child(odd) .timeline-content .inner-content {
    margin-right: 0;
  }

  .timeline .timeline-item:nth-child(odd) .timeline-content::after {
    position: absolute;
    top: 30px;
    left: -15px;
    width: 0;
    height: 0;
    border-width: 10px 15px 10px 0;
    border-style: solid;
    border-color: transparent #f5f5f5 transparent transparent;
    content: '';
  }
}

.timeline-content .inner-content {
  margin-top: 0px;
}

.disabled-element {
  filter: opacity(35%);
  pointer-events: none;
}

img.selectAvatar {
  position: relative;
  width: 55px;
}

img.viewSelectAvatar {
  position: relative;
  width: 150px;
}

.starRating {
  padding-top: 10px;
  color: gold;
}

/*  number inputs  */
input[type='number'] {
  appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  appearance: number-input;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/*  bizzare hack for MS edge */

[hidden] {
  display: none !important;
}

.colour-stripe.static {
  animation: none !important;
}

.mat-tab-body-content.ng-animating {
  overflow: hidden !important;
}

/* wiki */

app-wiki-view img {
  max-width: 100%;
}

app-wiki-view .chip {
  background: #919191;
}

/*  global table realign */

.mat-cell,
.mat-header-cell {
  box-sizing: border-box;
}

/*
mat-table.isStretched {
  overflow-x: scroll;
} */

.cdk-column-Type .mat-sort-header-container,
.cdk-column-mediaMedium .mat-sort-header-container,
.cdk-column-documentType .mat-sort-header-container {
  justify-content: flex-start !important;
}

.btn-group .mat-button-toggle-checked {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.btn-group .mat-button-toggle-checked .mat-button-toggle-label-content {
  font-weight: 500 !important;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 4px rgb(0 0 0 / 20%) !important;
}

.btn-group .mat-button-toggle-checked .badge.master {
  text-shadow: none !important;
}

.inputPrefix {
  position: relative;
  top: -10px;
}

mat-dialog-content.no-modal-height {
  overflow: visible;
  height: auto !important;
  max-height: max-content !important;
}

mat-dialog-content.no-modal-height {
  padding-top: 0 !important;
}

/*  sr colour hack temporarily */

.half-width.gradient-area.primary-gradient-img::before {
  z-index: 10;
}

/* .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-focused .mat-form-field-label, .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1b8bdd;
}  */

.ngx-text-highlight {
  background: orange;
  color: #fff;
}

.extra-header-icon-hard {
  width: 30px !important;
  height: 30px !important;
  color: #fff;
  font-size: 30px !important;
}

.extra-header-icon {
  width: 15px !important;
  margin-right: 5px !important;
  font-size: 15px !important;
}

.slim-header {
  padding: 10px !important;
}

.chip-only {
  padding: 2px 8px;
  border-radius: 20px;
  margin-left: 4px;
  background: #a8a8a8;
  color: rgb(255 255 255);
  font-weight: 600;
  text-shadow: 0 1px 1px rgb(78 78 78 / 40%);
}

.chip-warning {
  padding: 2px 8px;
  border-radius: 20px;
  margin-left: 4px;
  background: rgb(218 97 41);
  color: rgb(255 255 255);
  font-weight: 600;
  text-shadow: 0 1px 1px rgb(78 78 78 / 40%);
}

.chip-disable {
  padding: 2px 8px;
  border-radius: 20px;
  margin-left: 3px;
  background: #d00e00;
  color: rgb(255 255 255);
  font-weight: 600;
  text-shadow: 0 1px 1px rgb(78 78 78 / 40%);
}

.chip-enable {
  padding: 2px 8px;
  border-radius: 20px;
  margin-left: 3px;
  background: rgb(90 186 71);
  color: rgb(255 255 255);
  font-weight: 600;
  text-shadow: 0 1px 1px rgb(78 78 78 / 40%);
}

.date-chip-area {
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
}

.date-chip-area p {
  display: flex;
  align-items: center;
}

.date-chip-area p mat-icon {
  width: auto;
  height: auto;
  margin-right: 5px;
  font-size: 1rem;
}

.noCap {
  text-transform: none !important;
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded, [aria-disabled='true']) .mat-expansion-panel-header:hover {
    background: transparent;
  }
}

.hidden-important {
  display: none !important;
  width: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.cdk-overlay-container {
  z-index: 1000000 !important;
}

.toast-container {
  z-index: 10000000 !important;
}

mat-button-toggle-group.btn-group.selectType {
  margin: 0 15px 0 0;
  background: #fff;
}

mat-button-toggle-group.btn-group.selectType .mat-button-toggle label {
  padding: 0 8px;
}

nav.breadcrumb {
  margin: 0 0 30px;
  color: #fff;
  font-size: 0.75rem;
}

.breadcrumbs {
  margin-top: -35px;
}

.breadcrumbs ul {
  padding: 0;
  margin: 0;
  font-size: 0.85rem;
  list-style: none;
}

.breadcrumbs ul li mat-icon {
  margin-right: -20px !important;
  margin-left: 5px !important;
  font-size: 0.9rem !important;
}

.breadcrumbs ul li {
  display: inline-block;
  padding: 8px 0;
  margin: 0 15px 0 5px;
  color: #fff;
  float: left;
  opacity: 0.8;
}

.breadcrumbs ul li.link {
  cursor: pointer;
  opacity: 1;
}

.group-container {
  background-color: #fff !important;
}

.white-background {
  background: #fff !important;
}

.group-toggle-separator {
  margin: 0 !important;
}

app-merchant-main-card {
  margin-left: -20px !important;
}

.line-cross {
  position: relative;
  z-index: 1;
}

.line-cross span.title-cross {
  padding-right: 30px;
  padding-left: 30px;
  background: #fff;
}

.line-cross::before {
  position: absolute;
  z-index: -1;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 95%;
  border-top: 2px solid #dedede;
  margin: 0 auto;
  content: '';
}

.no-box-card {
  box-shadow: none !important;
}

/* whats next */
mat-card.secondary-gradient {
  background: #bc4e9c;

  /* fallback for old browsers */
  background: linear-gradient(to bottom, #f80759, #bc4e9c);

  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #f80759, #bc4e9c);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

mat-card.treatment.secondary-gradient .col-left h2.summary-header {
  font-size: 1.65rem;
}

mat-card.treatment.secondary-gradient .col-left {
  display: flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

mat-card.treatment.secondary-gradient {
  margin: 0;
}

mat-card.treatment.secondary-gradient .col-left {
  display: flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

mat-card.treatment.secondary-gradient .col-left h2.summary-header {
  font-size: 1.25rem;
}

mat-card.treatment.secondary-gradient.first button.full-width.full-height.btn-outline.pop {
  font-size: 1.5rem;
}

mat-card.treatment.secondary-gradient h2.summary-header {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 2rem;
  line-height: 1.6rem;
}

mat-card.treatment.secondary-gradient h2.summary-header span.practiceName {
  display: block;
  margin-left: 40px;
  font-size: 1rem;
  opacity: 0.78;
}

mat-card.treatment.secondary-gradient button.btn-outline {
  border: 1px solid rgb(255 255 255 / 80%);
  background: transparent;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  color: #fff;
  font-size: 1rem;
  text-transform: none;
}

mat-card.treatment.secondary-gradient .btn-outline.pop span.mobShow {
  font-family: Nunito, 'Helvetica Neue', sans-serif !important;
  font-size: 2.8rem;
  font-weight: 600;
  text-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 1px 5px rgb(0 0 0 / 20%);
}

mat-card.treatment.secondary-gradient .btn-outline.pop span.mobShow mat-icon {
  font-size: 2.2rem !important;
}

mat-card.treatment.secondary-gradient .btn-outline.pop .mobHide mat-icon {
  top: 2px;
}

mat-card.treatment.secondary-gradient .row.clearfix.flex.valueLabels {
  display: flexbox;
  display: flex;
}

mat-card.treatment {
  overflow: hidden;
  padding: 10px 16px;
  margin: 10px 0;
}

.valueLabels h1,
.valueLabels h2,
.valueLabels p,
.treatment button,
.treatment button mat-icon {
  color: #fff !important;
}

mat-card.treatment.secondary-gradient mat-icon.background {
  top: 10px;
  right: -5%;
  color: rgb(255 255 255 / 15%) !important;
  font-size: 6rem;
  transform: rotate(10deg);
}

mat-card.treatment .row.clearfix,
mat-card.treatment.appointment .row.clearfix,
mat-card.payment-cta .row.clearfix,
mat-card.changes-card .row.clearfix {
  position: relative;
  z-index: 2;
}

mat-card.payment-cta hr,
mat-card.treatment hr {
  opacity: 0.2;
}

.treatment.secondary-gradient.first button.full-width.full-height.btn-outline.pop {
  font-size: 1.5rem;
}

mat-card.treatment mat-icon.background {
  position: absolute;
  z-index: 0;
  width: auto;
  height: auto;
  color: rgb(255 255 255 / 8%) !important;
  font-size: 7rem;
  pointer-events: none;
}

mat-card.treatment mat-icon.background {
  top: 10px;
  right: -5%;
  color: rgb(255 255 255 / 15%) !important;
  font-size: 6rem;
  transform: rotate(10deg);
}

mat-card.treatment.secondary-gradient .botton {
  cursor: pointer;
}

mat-card.treatment.secondary-gradient .botton::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 7%);
  content: '';
  opacity: 0;
  transition: all 0.15s ease-out;
}

mat-card.payment-cta hr,
mat-card.treatment hr {
  opacity: 0.2;
}

mat-button-toggle-group.divided-toggle .yes {
  background: #31aa35 !important;
  color: #fff !important;
  opacity: 0.8 !important;
  text-align: center !important;
}

mat-button-toggle-group.divided-toggle .no {
  background: #be2525 !important;
  color: #fff !important;
  opacity: 0.8 !important;
  text-align: center !important;
}

mat-button-toggle-group.divided-toggle .yes:hover {
  background: #31cf37 !important;
  color: #fff !important;
  opacity: 1 !important;
}

mat-button-toggle-group.divided-toggle .yes.mat-button-toggle-checked {
  background: #31cf37 !important;
  color: #fff !important;
  opacity: 1 !important;
}

mat-button-toggle-group.divided-toggle .no:hover {
  background: #eb2222 !important;
  color: #fff !important;
  opacity: 1 !important;
}

mat-button-toggle-group.divided-toggle .no.mat-button-toggle-checked {
  background: #eb2222 !important;
  color: #fff !important;
  opacity: 1 !important;
}

mat-button-toggle-group.divided-toggle .yes mat-icon,
mat-button-toggle-group.divided-toggle .no mat-icon {
  width: auto !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  color: #fff !important;
  text-shadow: 0 1px 4px rgb(0 0 0 / 50%) !important;
}

.btn-show-all {
  color: #1b8bdd;
  cursor: pointer;
}

.btn-show-all:hover {
  color: rgb(221 82 27);
}

/* width */
::-webkit-scrollbar {
  width: 14px !important;
  height: 14px !important;
  border-radius: 14px !important;
  background: rgb(104 100 100 / 19.8%) !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 14px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 14px !important;
  background: #5d5d5d7a !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #24242463;
}

.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
  background-color: rgb(0 0 0 / 12%) !important;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%) !important;
  color: rgb(0 0 0 / 26%) !important;
}

mat-card.treatment {
  box-shadow: -3px -4px 10px #1b8bdd, 1px 3px 10px #24242463 !important;
}

app-consumer-settings .mat-tab-label.mat-tab-label-active {
  background-color: rgb(36 88 125);
  color: #fff;
  opacity: 1;
}

.main-banner.blue-banner {
  position: absolute;
  z-index: -1;
  top: -180px !important;
  display: block !important;
  overflow: hidden;
  width: 100%;
  height: 1000px;
  margin-left: -20px;
  background: #0092ea;
  background-image: linear-gradient(to right, #0092ea 0%, #1b8bdd 100%);
  transform: skewY(-6deg);
  transform-origin: 0;
}

@media (max-width: 1024px) {
  .main-banner.blue-banner {
    height: 1200px;
  }
}

@media (max-width: 770px) {
  .main-banner.blue-banner {
    width: calc(100% + 20px) !important;
  }
}

.main-banner.blue-banner :nth-child(2) {
  right: 0;
  bottom: 40px;
  width: 14%;
  background: #3094e2;
}

.main-banner.blue-banner :nth-child(3) {
  right: 0;
  bottom: 0;
  width: 25%;
  background: #a1ffc8;
}

/*

h1.getting-started-page {
  font-size: 3.2rem !important;
  line-height: 3.3rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-shadow: 0 3px 10px rgb(0 0 0 / 25%) !important;
}

h2.getting-started-page {
  font-size: 2.25rem !important;
}

h3.getting-started-page {
  font-size: 2rem !important;
}

h4.getting-started-page {
  font-size: 24px !important;
}

p.getting-started-page {
  font-size: 1.3rem !important;
  line-height: 1.95rem !important;
  font-weight: 300 !important;
  font-family: "Open Sans" !important;
  color: #525252 !important;
}


.top-txt>h1.getting-started-page {
  color: #fff !important;
}

.top-txt>p.getting-started-page {
  color: #fff !important;
  font-size: 26px !important;
}


@media(max-width: 568px) {
  h1.getting-started-page {
    font-size: 2.5rem !important;
  }

  h2.getting-started-page {
    font-size: 2rem !important;
  }

  h3.getting-started-page {
    font-size: 1.8rem !important;
  }

  .title>p.getting-started-page {
    font-size: 1.3rem !important;
  }

  p.getting-started-page,
  .top-txt>p.getting-started-page {
    font-size: 18px !important;
  }
}

.top-txt>h1.getting-started-page {
  color: #fff !important;
}

.top-txt>p.getting-started-page {
  color: #fff !important;
  font-size: 26px !important;
}

.visit-text>p.getting-started-page {
  font-size: 33px !important;
  color: #2AA2A3 !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
}

.title>p.getting-started-page {
  font-size: 2.1rem !important;
  line-height: 1.5 !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 600 !important;
  color: #249DCE !important;
} */

.white-bg {
  background-color: #fff;
}

mat-dialog-content {
  width: 100%;
}

span.list-label.smaller {
  border-radius: 50%;
}

span.list-label.smaller mat-icon {
  width: auto;
  height: auto;
  font-size: 1rem;
  font-weight: 700;
}

table span.list-label::after {
  display: block;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 80%) !important;
}

.mat-autocomplete-trigger.mat-chip-input {
  width: 100%;
}

.no-scroll {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.no-scroll-y {
  overflow-x: hidden !important;
}

.no-scroll-x {
  overflow-x: hidden !important;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.optional .step-indicator {
  background-color: #e6e6e6;
  color: black;
}

@media (max-width: 700px) {
  .container {
    padding: 0 !important;
  }
}

.white-background {
  background: #fff;
}

.card.successful {
  max-width: 700px;
  background: #f7f7f7;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: #57c242 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: rgb(91 197 70) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(91 197 70 / 50%) !important;
}

mat-checkbox .mat-checkbox-inner-container {
  width: 18px;
  height: 18px;
  margin-top: 0;
}

app-inner-html-isolated {
  position: relative !important;
}

.vert-move {
  animation: moverVert 1s infinite alternate;
}

.vert-move {
  animation: moverVert 1s infinite alternate;
}

@keyframes moverVert {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes moverVert {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.mat-select-panel {
  max-width: 1000px !important;
}

.initial-loader {
  width: 100%;
  height: 100vh !important;
  background: rgb(0 0 0 / 40%) !important;
}

.initial-loader p.inner-percent {
  position: absolute;
  top: calc(50% - 26px);
  left: calc(50% - 102px);
  width: 180px;
  height: 180px;
  margin: 0;

  /* vertical-align: middle; */
  color: #fff;
  font-family: Helvetica, sans-serif !important;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: 0 1px 3px rgb(0 0 0 / 40%);

  /* word-spacing: 74px; */
  white-space: 20px;
}

.initial-loader2 {
  width: 100%;
  height: 100vh !important;
  background: none !important;
}

.initial-loader2 p.inner-percent {
  position: absolute;
  top: calc(50% - 26px);
  left: calc(50% - 102px);
  width: 180px;
  height: 180px;
  margin: 0;

  /* vertical-align: middle; */
  color: #fff;
  font-family: Helvetica, sans-serif !important;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: 0 1px 3px rgb(0 0 0 / 40%);

  /* word-spacing: 74px; */
  white-space: 20px;
}

.lds-ring-main {
  position: relative;
  position: absolute;
  top: calc(50% - 94px);
  right: calc(50% - 105px);
  display: inline-block;
  width: 210px;
  height: 210px;
}

.lds-ring-main div {
  position: absolute;
  display: block;
  width: 180px;
  height: 180px;
  box-sizing: border-box;
  border: 12px solid #fff;
  border-color: #fff transparent transparent;
  border-radius: 50%;
  margin: 3px;
  animation: lds-ring-main 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring-main div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-main div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-main div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-main {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#initial-loader-percent-custom {
  z-index: 10000000000;
}

.mat-dialog-container.full-screen {
  position: fixed;
  top: 1vh !important;
  left: 3% !important;
  width: 94% !important;
  max-width: 96% !important;
  height: 98% !important;
  max-height: 99% !important;
  padding: 0 16px !important;
  margin: 0 !important;
}

.full-big-screen .mat-dialog-container {
  max-height: 100vh !important;
}

.bigger-screen .mat-dialog-container {
  max-height: 95vh !important;
}

.mat-dialog-container.full-screen .mat-dialog-content video {
  height: calc(92vh - 100px) !important;
  max-height: calc(92vh - 100px) !important;
}

.mat-dialog-container.full-screen .mat-dialog-content {
  height: calc(92vh - 100px) !important;
  max-height: calc(92vh - 100px) !important;
}

.mat-dialog-container .mat-dialog-content iframe {
  border: none !important;
}

.mat-dialog-container.full-screen .mat-dialog-content iframe {
  height: calc(92vh - 110px) !important;
  border: none !important;
}

.mat-dialog-container.full-screen .mat-dialog-content embed {
  height: calc(92vh - 110px) !important;
}

.mat-dialog-container.full-screen .ep-document-view-modal pinch-zoom.pinchZoom-blob .pinch-zoom-content {
  min-height: calc(92vh - 100px) !important;
}

.mat-dialog-container.full-screen pinch-zoom.pinchZoom-img-simple .pinch-zoom-content {
  min-height: calc(92vh - 100px) !important;
}

.mat-dialog-container.full-screen .mat-dialog-content .image-loading {
  min-height: calc(92vh - 100px) !important;
}

.mat-dialog-container.full-screen .mat-dialog-content .min-height {
  min-height: calc(92vh - 100px) !important;
  max-height: none !important;
}

.mat-dialog-container.full-screen .mat-dialog-content pinch-zoom.pinchZoom-file .pinch-zoom-content {
  min-height: calc(92vh - 100px) !important;
}

.mat-dialog-container.full-screen .ep-view-img-simple-default {
  height: calc(92vh - 100px) !important;
}

.mat-dialog-container.full-screen app-view-image-simple mat-card:first-child {
  padding: 0 !important;
}

.mat-dialog-container.full-screen .card-header {
  margin-top: 0 !important;
}

.mat-dialog-container.full-screen .mat-dialog-actions {
  min-height: auto !important;
  padding: 7px 0 !important;
}

.spinner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  margin: auto;
  margin: -125px 0 0 -125px;
}

.spinner-inner .double-bounce1,
.spinner-inner .double-bounce2,
.spinner-inner .double-bounce3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100% !important;
  animation: sk-bounce-inner 3s infinite ease-in-out !important;
  background-color: #cfcfcfa3;
  opacity: 0.6;
}

.spinner-inner .double-bounce2 {
  animation-delay: -1s !important;
}

.spinner-inner .double-bounce3 {
  animation-delay: -2s !important;
}

@keyframes sk-bounce-inner {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes sk-bounce-inner {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.image-big-holder {
  min-height: 60vh !important;
  background-color: rgb(0 0 0 / 80%);
}

@media (max-width: 768px) {
  h2.summary-header {
    font-size: 1.1rem !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content {
    height: calc(92vh - 170px) !important;
    max-height: calc(92vh - 170px) !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content video {
    height: calc(92vh - 170px) !important;
    max-height: calc(92vh - 170px) !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content {
    height: calc(92vh - 170px) !important;
    max-height: calc(92vh - 170px) !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content iframe {
    height: calc(92vh - 180px) !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content embed {
    height: calc(92vh - 180px) !important;
  }

  .mat-dialog-container.full-screen pinch-zoom.pinchZoom-img-simple .pinch-zoom-content {
    min-height: calc(92vh - 170px) !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content .image-loading {
    min-height: calc(92vh - 170px) !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content .min-height {
    min-height: calc(92vh - 170px) !important;
    max-height: none !important;
  }

  .mat-dialog-container.full-screen .mat-dialog-content pinch-zoom.pinchZoom-file .pinch-zoom-content {
    min-height: calc(92vh - 170px) !important;
  }

  .mat-dialog-container.full-screen .ep-view-img-simple-default {
    height: calc(92vh - 170px) !important;
  }

  .mat-dialog-container {
    width: 100% !important;
  }

  .mat-dialog-container.full-screen {
    position: fixed;
    top: 1vh !important;
    left: 0% !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 98% !important;
    max-height: 99% !important;
    padding: 0 5px !important;
    margin: 0 !important;
  }
}

.emojiContainer {
  max-width: 600px !important;
}

.emojiContainer .mat-menu-content {
  padding: 0 !important;
}

.customizationPanelContain {
  max-width: 500px !important;
  max-height: 500px !important;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 10px;
  background: rgb(200 200 200 / 70%) !important;
  overflow-y: auto !important;
}

.no-line-input .mat-form-field-underline {
  display: none !important;
}

.iconproductgroup {
  color: #818181;
  font-size: 1.3rem;
}

.product-group-ico {
  color: #818181;
  font-size: 1.3rem;
}

.product-group-ico-small {
  color: #818181;
  font-size: 1.1rem;
}

.tox-tinymce-aux {
  z-index: 100000000 !important;
}

emoji-preview .emoji-mart-preview {
  display: none !important;
}

.tox-statusbar__branding {
  display: none !important;
}

.tox .tox-tbtn svg {
  fill: #818181 !important;
}

.tox .tox-statusbar__resize-handle {
  display: none !important;
}

.cdk-overlay-pane.card-relative {
  position: relative !important;

}

.cdk-overlay-pane.max-width-panel-1200 {
  max-width: 1200px !important;

}


.cdk-overlay-pane.max-width-panel-90vw {
  max-width: 90vw !important;
}



.cdk-overlay-pane.max-width-panel-1100 {
  max-width: 1100px !important;
}

.cdk-overlay-pane.max-width-panel-1000 {
  max-width: 1000px !important;
}

.cdk-overlay-pane.max-width-panel-1400 {
  max-width: 1400px !important;
}


.cdk-overlay-pane.max-width-panel-1600 {
  max-width: 1400px !important;
}

.cdk-overlay-pane.max-width-panel-1800 {
  max-width: 1400px !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.header-button {
  border: 1px solid white !important;
  background: none !important;
  color: #fff !important;
}


.fa-vacuum::before {

  content: url('./svg/vacuum-svgrepo-com.svg');
}



.cdk-global-overlay-wrapper:not(.cdk-overlay-backdrop ~ .cdk-global-overlay-wrapper) {
  background: rgb(0, 0, 0, 0.9);
}

.status-container {
  padding: 3px 10px;
  display: inline-block;
  border-radius: 5px;
  background: #2ecc71;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 1px 4px rgb(0 0 0 / 20%);
}


.tooltip.tooltip-shadow {
  max-width: 500px !important;
  z-index: 2000000;
  background: rgb(0, 0, 0, 0.9) !important;

}




.header-tooltip-new.tooltip.tooltip-shadow {
  max-width: 500px !important;
  width: 500px !important;
  z-index: 2000000;
  color: #000;
  background: #e1e1e1 !important;
  opacity: 0.9 !important;
  left: 750px !important;
  margin-top: -16px !important;
}

.header-tooltip-new.tooltip.tooltip-shadow h3,
.header-tooltip-new.tooltip.tooltip-shadow p,
.header-tooltip-new.tooltip.tooltip-shadow ul,
.header-tooltip-new.tooltip.tooltip-shadow li {
  text-align: left;
}

.header-tooltip-new.tooltip.tooltip-shadow p,
.header-tooltip-new.tooltip.tooltip-shadow ul,
.header-tooltip-new.tooltip.tooltip-shadow li {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.header-tooltip-new.tooltip.tooltip-shadow li {
  margin-bottom: 10px;
}


.header-tooltip-new.tooltip.tooltip-shadow.tooltip-bottom::after {
  left: 30px !important;
}


.customized-tooltip-class {
  position: relative;
  /* Add any additional styles for the parent class */
}

.custom-tooltip {
  position: absolute;
  display: none;
  opacity: 0;
  /* Add styling for the tooltip */
}

.custom-tooltip {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.indicator-top::before {
  top: 100%;
  border-bottom-color: #3498db;
}


.customized-tooltip-class {
  position: absolute !important;
  display: block;
  font-size: 0.9rem;
  line-height: 1.4rem;
  min-width: 100px !important;
  max-width: 500px !important;
  min-height: 40px !important;
  background: red;
  z-index: 2000000000000000;
  padding: 10px;
  background: rgb(0, 0, 0, 0.8) !important;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 7px 15px -5px rgba(0, 0, 0, .4);
  border: 1px solid rgb(202, 202, 202);
}


.additional-tooltip-class.customized-tooltip-class {
  position: absolute !important;
  display: block;
  font-size: 0.9rem;
  line-height: 1.4rem;
  min-width: 100px !important;
  max-width: 600px !important;
  min-height: 40px !important;
  background: red;
  z-index: 2000000000000000;
  padding: 10px;
  color: #000;
  background: #ededed !important;
  border-radius: 5px;
  opacity: 0.9;
  margin-left: 128px;
  margin-top: -26px;
  border: 1px solid rgb(202, 202, 202);

}


.additional-tooltip-class.customized-tooltip-class h3,
.additional-tooltip-class.customized-tooltip-class p,
.additional-tooltip-class.customized-tooltip-class ul,
.additional-tooltip-class.customized-tooltip-class li {
  text-align: left;
}

.additional-tooltip-class.customized-tooltip-class li {

  margin-bottom: 10px;
}

.additional-tooltip-class.customized-tooltip-class p,
.additional-tooltip-class.customized-tooltip-class ul,
.additional-tooltip-class.customized-tooltip-class li {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.4rem;
}


.additional-tooltip-class-header.customized-tooltip-class {
  position: absolute !important;
  display: block;
  font-size: 0.9rem;
  line-height: 1.3rem;
  min-width: 100px !important;
  max-width: 500px !important;
  width: 500px !important;
  min-height: 40px !important;
  z-index: 2000000000000000;
  padding: 10px;
  color: #000;
  background: #ededed !important;
  border-radius: 5px;
  opacity: 0.9;
  border: 1px solid grey;
  margin-left: -250px;
  margin-top: -2px;
  border: 1px solid rgb(202, 202, 202);

}



.additional-tooltip-class-header.customized-tooltip-class h3,
.additional-tooltip-class-header.customized-tooltip-class p,
.additional-tooltip-class-header.customized-tooltip-class ul,
.additional-tooltip-class-header.customized-tooltip-class li {
  text-align: left;
}

.additional-tooltip-class-header.customized-tooltip-class li {

  margin-bottom: 10px;
}

.additional-tooltip-class-header.customized-tooltip-class p,
.additional-tooltip-class-header.customized-tooltip-class ul,
.additional-tooltip-class-header.customized-tooltip-class li {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.4rem;
}









.additional-tooltip-class-header1540.customized-tooltip-class {
  position: absolute !important;
  display: block;
  font-size: 0.9rem;
  line-height: 1.3rem;
  min-width: 100px !important;
  max-width: 500px !important;
  width: 500px !important;
  min-height: 40px !important;
  z-index: 2000000000000000;
  padding: 10px;
  color: #000;
  background: #ededed !important;
  border-radius: 5px;
  opacity: 0.9;
  border: 1px solid grey;
  margin-left: -515px;
  margin-top: -4px;
  border: 1px solid rgb(202, 202, 202);

}



.additional-tooltip-class-header1540.customized-tooltip-class h3,
.additional-tooltip-class-header1540.customized-tooltip-class p,
.additional-tooltip-class-header1540.customized-tooltip-class ul,
.additional-tooltip-class-header1540.customized-tooltip-class li {
  text-align: left;
}

.additional-tooltip-class-header1540.customized-tooltip-class li {

  margin-bottom: 10px;
}

.additional-tooltip-class-header1540.customized-tooltip-class p,
.additional-tooltip-class-header1540.customized-tooltip-class ul,
.additional-tooltip-class-header1540.customized-tooltip-class li {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.4rem;
}




.additional-tooltip-class-application.customized-tooltip-class {
  position: absolute !important;
  display: block;
  font-size: 0.9rem;
  line-height: 1.4rem;
  min-width: 100px !important;
  max-width: 600px !important;
  width: 600px !important;
  min-height: 40px !important;
  z-index: 2000000000000000;
  padding: 10px;
  color: #000;
  background: #e1e1e1 !important;
  border-radius: 5px;
  opacity: 0.9;

  margin-left: 80px;
  margin-top: -14px;
  border: 1px solid rgb(202, 202, 202);

}


.additional-tooltip-class-application.customized-tooltip-class h3,
.additional-tooltip-class-application.customized-tooltip-class p,
.additional-tooltip-class-application.customized-tooltip-class ul,
.additional-tooltip-class-application.customized-tooltip-class li {
  text-align: left;
}

.additional-tooltip-class-application.customized-tooltip-class li {

  margin-bottom: 10px;
}

.additional-tooltip-class-application.customized-tooltip-class p,
.additional-tooltip-class-application.customized-tooltip-class ul,
.additional-tooltip-class-application.customized-tooltip-class li {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.4rem;
}


.or-devider {
  height: 22px;
  padding-top: 6px;
  width: 100%;
  float: left;
  margin-bottom: 20px;


}

.or-devider p {
  background: #fff;
  width: 80px !important;
  margin: auto;
  float: none;
  margin-top: -26px;
  font-weight: 600;
  font-size: 1.4rem;
}







.additional-tooltip-class-header-right.customized-tooltip-class {
  position: absolute !important;
  display: block;
  font-size: 0.9rem;
  line-height: 1.3rem;
  min-width: 100px !important;
  max-width: 500px !important;
  width: 500px !important;
  min-height: 40px !important;
  z-index: 2000000000000000;
  padding: 10px;
  color: #000;
  background: #ededed !important;
  border-radius: 5px;
  opacity: 0.9;

  margin-left: -498px !important;
  margin-top: -2px;
  border: 1px solid rgb(202, 202, 202);

}


.additional-tooltip-class-header-right.customized-tooltip-class h3,
.additional-tooltip-class-header-right.customized-tooltip-class p,
.additional-tooltip-class-header-right.customized-tooltip-class ul,
.additional-tooltip-class-header-right.customized-tooltip-class li {
  text-align: left;
}

.additional-tooltip-class-header-right.customized-tooltip-class li {

  margin-bottom: 10px;
}

.additional-tooltip-class-header-right.customized-tooltip-class p,
.additional-tooltip-class-header-right.customized-tooltip-class ul,
.additional-tooltip-class-header-right.customized-tooltip-class li {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.4rem;
}


.mat-header-cell {
  color: rgba(0, 0, 0, 0.54) !important;
  background: #f7f7f7 !important;
  font-weight: 800 !important;
}


.additional-tooltip-SMS.customized-tooltip-class {
  position: absolute !important;
  min-width: 240px !important;
  max-width: 240px !important;
  width: 240px !important;
  margin-left: -230px;
  margin-top: -10px;

}

.display-750px {
  display: none;
}

@media(max-height: 760px) {
  .hide-750px {
    display: none;
  }

  .display-750px {
    display: block;
  }
}


button.mat-mini-fab {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.selected-number-item {

  padding: 1px 4px 2px 4px;
  border-radius: 5px;
  margin-top: -3px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  /* position: relative; */
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  line-height: 20px;
  margin-left: 10px;
  /* float: inline-end; */
  margin-right: -10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.bubble {
  background: #dd1b1b;
  padding: 1px 4px 2px 4px;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  /* position: relative; */
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  /* float: inline-end; */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  white-space: nowrap;
}


.filter-select-component-search {
  border-bottom: 1px solid rgba(210, 210, 210);

}

.filter-select-component-search .mat-option-pseudo-checkbox {
  display: none !important;
}

.filter-select-component-not-found .mat-option-pseudo-checkbox {
  display: none !important;
}

.filter-select-component-not-found p {
  background: rgb(248, 248, 255) !important;
  border: 1px solid #50B1FB;
  padding: 7px;
  margin: 0;
  border-radius: 5px;

}

.filter-select-component-not-found span {
  color: #50B1FB !important;
}

.filter-select-component-not-found mat-icon {
  color: #50B1FB !important;
}


.beta-new-button {
  height: 20px !important;
  line-height: 20px !important;
  padding: 0px 6px !important;
  margin: 0 2px 2px 0 !important;
  left: auto !important;
  right: 5px !important;
  top: 8px !important;
}

.full-screen .min-height-json {
  height: calc(92vh - 120px) !important;
  width: 100% !important;

}
