.pac-container {
  z-index: 100000000000000 !important;
}

#loader {
  position: fixed;
  z-index: 100000000000000;
  top: 0;
  right: 0;
  display: none;
  width: 100%;
  height: 100vh;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  background: rgb(0 0 0 / 40%);
}

#loaderForce {
  position: fixed;
  z-index: 100000000000000;
  top: 0;
  right: 0;
  display: none;
  width: 100%;
  height: 100vh;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  background: rgb(0 0 0 / 40%);
}

#loaderForce img,
#loader img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
}

#globalUserDataLoader {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  display: none;
  width: 100%;
  height: 100vh;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  animation: fadein 1s;
  background: #fff;
  opacity: 0.9;
}

#globalUserDataLoader h2 {
  position: fixed;
  bottom: 100px;
  left: 10px;
  opacity: 0.6;
}

#globalUserDataLoader h2 {
  animation: blink-animation 1s steps(5, start) infinite;
  animation: blink-animation 1s steps(5, start) infinite;
}

.borderless-modal .mat-dialog-container {
  padding: 0 !important;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

#globalUserDataLoader img {
  display: block;
  margin: 0 auto;
  margin-top: 30%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
